import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from "@angular/forms";
import { Router } from '@angular/router';

import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

import {WindowService} from "../../services/window.service";

//import { tokenNotExpired, JwtHelper } from 'angular2-jwt';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
    selector: 'login',
    templateUrl: 'login.component.html'
})
 
export class LoginComponent implements OnInit {
    model: any = {};
    loading = false;
    error = '';
    errorMessage = '';
    private windowHandle: any = null;
    private intervalId: any = null;
    private loopCount = 600;
    private intervalLength = 100;
    
    jwtHelper = new JwtHelperService();

    loginForm = new FormGroup({
        naam: new FormControl(),
        wachtwoord: new FormControl()
      });

    constructor(
        private windows: WindowService,
        private router: Router,
        private http: HttpClient) { }
    
        onSubmit() {
            let obj = this.loginForm.value;

            let naam = obj.naam;
            let wachtwoord = obj.wachtwoord;

            let fd = new FormData();
            fd.append("naam", naam);
            fd.append("wachtwoord", wachtwoord);
            let token;

            this.http.post("https://www.spermalie.info/login/", fd).subscribe(
                data => {token = data},
                error => {console.log(error)},
                ()=> {
                    localStorage.setItem('id_token', JSON.stringify({token: token}));
                    this.router.navigate(['/login']);
                });
        }
    
    popup(){
        var loopCount = this.loopCount;
        //aanpassen domein voor page origin check, zodat we onze token kunnen ophalen
        document.domain = 'spermalie.info';
        //nieuw venster openen om in te loggen via smartschool
        //this.windowHandle = this.windows.createWindow("https://www.spermalie.info/login/", 'OAuth2 Login');
        //interval loopt tot de token verkregen is
        this.intervalId = setInterval(() => {
                if (loopCount-- < 0) {
                    clearInterval(this.intervalId);
                    this.windowHandle.close();
                } else {
                    var token: string;
                    try {
                        token = this.windowHandle.document.getElementsByName('token')[0].value;
                    } catch (e) {
                        //console.log('Error:', e);
                    }
                    if (token != null) {
                        clearInterval(this.intervalId);
                        localStorage.setItem('id_token', JSON.stringify({token: token}));
                        this.router.navigate(['/login']);
                        this.windowHandle.close();        
                    }
                }
            }, this.intervalLength);

        const formData = new FormData();
        //formData.append('key', form.value);

        //this.http.post("https://www.spermalie.info/login/", data)
    }

    ngOnInit() {
        // reset login status
    }
    logout(): void {
        // clear token remove user from session storage to log user out
        localStorage.removeItem('id_token');
    }
    loggedIn() {
        let jwt = localStorage.getItem('id_token');
        if (jwt) {
            return !this.jwtHelper.isTokenExpired(jwt);
        } else {
            return false;
        }
        //this.jwtHelper.decodeToken(jwt)
        //this.jwtHelper.getTokenExpirationDate(jwt)
        
    }
}