import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Evenement } from '../entities/evenement.model';
//import { BoundElementPropertyAst } from '@angular/compiler';

const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'my-auth-token'
    })
  };


@Injectable()
export class EvenementCopyService{
    apiName = "evenement";
    apiUrl = 'https://api.spermalie.info/'+this.apiName+'/read.php';

    constructor(private http: HttpClient){ }

    public kopieer (evenement: Evenement) {
      var kopie = Object.assign({}, evenement);
      return kopie;
    }
}



