import { Pipe, PipeTransform } from '@angular/core';
import { GeneralService } from '../services/general.service';

@Pipe({name: 'nl2br'})
export class Nl2BrPipe implements PipeTransform {

constructor( private generalService: GeneralService){

    }   

  transform(inputText, type: string) : string {
    var output = "";
    //console.log("type = "+type);
    if(type=="1")
    {
    output = inputText.replace("\\n", "<br/>");
    var plaats = inputText.indexOf("\\n");
      while (plaats >= 0) {
          output = output.replace("\\n", "<br/>");
          plaats = output.indexOf("\\n");
          //console.log('plaats:' + plaats);
      } 
    }     
    else 
    {
      output = inputText.replace("\\n", "\n");
      var plaats = inputText.indexOf("\\n");
      while (plaats >= 0) {
        output = output.replace("\\n", "\n");
        plaats = output.indexOf("\\n");
        //console.log('plaats:' + plaats);
      }
    }
    //console.log('output:' + output);
    return output;
  }
}