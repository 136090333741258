import { Component, OnInit } from '@angular/core';
import { EvenementCopyService } from "../../services/event-copy.service";
import { EvenementService } from "../../services/evenement.service";
import { Evenement } from "../../entities/evenement.model";
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IAlert } from '../../entities/alert.model';
import { finalize, debounceTime } from 'rxjs/operators';
import { AlertService} from '../../services/alerts.service';
import { GeneralService} from '../../services/general.service';

@Component({
  selector: 'app-event-create',
  templateUrl: './event-create.component.html',
  providers: [ EvenementCopyService, AlertService, EvenementService, GeneralService ],
  styleUrls: ['./event-create.component.css']
})
export class EventCreateComponent implements OnInit {

  constructor(private evenementCopyService : EvenementCopyService, 
    private evenementService: EvenementService,
    private modalService: NgbModal, 
    private alertService: AlertService,
    private generalService: GeneralService) { }

    mainData: Evenement[];
    newItem: Evenement;
    magOpslaan: boolean;
    errorMessage: string;
    addNewItem: boolean = false;
    title:string = "Nieuw Evenement";
    alerts: Array<IAlert> = [];

  ngOnInit() {
    this.dataOphalen();
    this.newItem = new Evenement(0,"","","","","",0,0,"","","","","",0,0,0,"","","","","","","","",0,"","","","","","",0,"",false, 0,"","",false,false,"","",false,"","",false);
  }

  dataOphalen(): void {
    this.evenementService.getAPI_Values()
      .subscribe(
        array => this.mainData = array,
        err=> this.errorMessage = "Namen van lijsten niet opgehaald",
        () => 
        {
          this.errorMessage = "";
          //this.voegLegeInstanceToe();
          //this.sorteer();
        }
    );
    //let this.evenementService.evenement_List = this.mainData;
    this.alertService.newAlert('info','Data opgehaald',3000,this.alerts);
    console.log("Error message = " + this.errorMessage);
}

  checkValidity(evNaam:string, evWachtwoord: string)
  {
    this.generalService.giveLog('Input: ' + evNaam + ' ' + evWachtwoord);
    var naamOK = this.evenementService.checkUniekeNaam(evNaam, this.mainData);
    var wachtwoordOK = this.evenementService.checkUniekWachtwoord(evWachtwoord, this.mainData);
    this.generalService.giveLog('naamOK:' + naamOK + '\nwachtwoordOK: ' + wachtwoordOK);


    if (naamOK) {
      document.getElementById("evNaam").className = "form-control ng-touched ng-dirty ng-valid";
    }
    else {
      document.getElementById("evNaam").className = "form-control ng-touched ng-dirty ng-invalid";
    }

    if (wachtwoordOK) {
      document.getElementById("evWachtwoord").className = "form-control ng-touched ng-dirty ng-valid";
    } else {
      document.getElementById("evWachtwoord").className = "form-control ng-touched ng-dirty ng-invalid";
    }

    if (wachtwoordOK && naamOK) {
      this.magOpslaan = true;
    } else {
      this.magOpslaan = false;
    }
  }

  saveItem(evNaam:string, evWachtwoord: string, evOmschrijving: string):void{
    this.newItem.evNaam = evNaam;
    this.newItem.evWachtwoord = evWachtwoord.toUpperCase();
    this.newItem.evAfkortingNaam = this.newItem.evWachtwoord;
    this.newItem.evActief = 'J';
    if (evOmschrijving == "") {
      this.newItem.evOmschrijving = this.newItem.evNaam;
    } else {
      this.newItem.evOmschrijving = evOmschrijving;
    }
    var naamNieuwItem = this.newItem.evNaam;
    this.generalService.giveLog(naamNieuwItem);
    this.alertService.newAlert('info','wordt opgeslaan ...',3000,this.alerts, naamNieuwItem);

    if (!naamNieuwItem) { return; }
    console.log(this.newItem);

    this.evenementService.addAPI_values(this.newItem)
      .pipe( finalize( () => this.alertService.newAlert('success','is opgeslagen',3000,this.alerts, naamNieuwItem) ) )
      .subscribe(
        gelukt => this.newItem,
        () => 
        {
          this.mainData.push(this.newItem);
        }
      );
    
    this.addNewItem = false;
    this.magOpslaan = false;
  }  
}


