import { Component, OnInit } from '@angular/core';

import { EvenementCopyService } from "../../services/event-copy.service";
import { EvenementService } from "../../services/evenement.service";
import { Evenement_VragenService } from "../../services/evenement-vragen.service";
import { PrijsService } from "../../services/prijs.service";
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Evenement } from "../../entities/evenement.model";
import { Form } from "../../entities/form.model";
import { Prijs } from "../../entities/prijs.model";

import { IAlert } from '../../entities/alert.model';
import { finalize, debounceTime } from 'rxjs/operators';
import { AlertService} from '../../services/alerts.service';
import { GeneralService} from '../../services/general.service';

@Component({
  selector: 'app-event-copy',
  templateUrl: './event-copy.component.html',
  providers: [ EvenementCopyService, AlertService, EvenementService, GeneralService, Evenement_VragenService, PrijsService ],
  styleUrls: ['./event-copy.component.css']
})
export class EventCopyComponent implements OnInit {

  constructor(private evenementCopyService : EvenementCopyService, 
              private evenementService: EvenementService,
              private modalService: NgbModal, 
              private alertService: AlertService,
              private generalService: GeneralService,
              private evenement_VragenService: Evenement_VragenService, 
              private prijsService: PrijsService
            ) { }

  mainData: Evenement[];
  selectedItem: Evenement;
  newItem: Evenement;
  selectedSomething:boolean;
  magOpslaan: boolean;
  errorMessage: string;
  addNewItem: boolean = false;
  title:string = "Kopieer Evenement";
  alerts: Array<IAlert> = [];
  kopieOK: boolean;
  copyFromID: number;

  ngOnInit() {
    this.dataTonen();
    this.kopieOK = false;
  }

  onSelect(index:number): void {
    if(index > 0){
      this.addNewItem = false;
      this.selectedSomething = true;
      this.newItem = this.evenementCopyService.kopieer(this.mainData[index]);    
    }
    else{
      this.addNewItem = true;
      this.selectedSomething = false;
      this.newItem = null;         
    }
    console.log(index + ' - selectedSomething: ' + this.selectedSomething);
    this.magOpslaan = false;
    this.kopieOK = false;
  }

  dataTonen(): void {
      this.evenementService.getAPI_Values()
        .subscribe(
          array => this.mainData = array,
          err=> this.errorMessage = "Namen van lijsten niet opgehaald",
          () => 
          {
            this.errorMessage = "";
            this.voegLegeInstanceToe();
            this.sorteer();
            this.alertService.newAlert('info','Data opgehaald',3000,this.alerts);            
          }
      );
  }

  voegLegeInstanceToe(): void
  {
    var legeInstance = new Evenement(0,"*** Kies een evenement ***","","","","",0,0,"","","","","",0,0,0,"","","","","","","","",0,"","","","","","",0,"",false, 0,"","",false,false,"","",false,"","",false);
    this.mainData.push(legeInstance);
    
  }

  sorteer():void{
  this.mainData = this.mainData.sort((f1, f2) => {
  return f1.evNaam < f2.evNaam ? -1 :
        (f1.evNaam > f2.evNaam ? 1 : 0);
    });
  }

  checkValidity(evNaam:string, evWachtwoord: string)
  {
    var naamOK = this.evenementService.checkUniekeNaam(evNaam, this.mainData);
    var wachtwoordOK = this.evenementService.checkUniekWachtwoord(evWachtwoord, this.mainData);
    this.generalService.giveLog('naamOK:' + naamOK + '\nwachtwoordOK: ' + wachtwoordOK);


    if (naamOK) {
      document.getElementById("evNaam").className = "form-control ng-touched ng-dirty ng-valid";
    }
    else {
      document.getElementById("evNaam").className = "form-control ng-touched ng-dirty ng-invalid";
    }

    if (wachtwoordOK) {
      document.getElementById("evWachtwoord").className = "form-control ng-touched ng-dirty ng-valid";
    } else {
      document.getElementById("evWachtwoord").className = "form-control ng-touched ng-dirty ng-invalid";
    }

    if (wachtwoordOK && naamOK) {
      this.magOpslaan = true;
      this.newItem.evWachtwoord = this.newItem.evWachtwoord.toUpperCase();
      this.newItem.evAfkortingNaam = this.newItem.evWachtwoord;
    } else {
      this.magOpslaan = false;
    }
  }

  saveItem():void{
    var naamNieuwItem = this.newItem.evNaam;
    this.copyFromID = this.newItem.evEventID;
    var newEvent: Evenement;
    this.newItem.evEindDatumInschrijving = undefined;
    this.newItem.evActief = 'J';

    this.alertService.newAlert('info','wordt opgeslaan ...',3000,this.alerts, naamNieuwItem);

    if (!naamNieuwItem) { return; }
    //console.log(this.newItem);

    this.evenementService.addAPI_values(this.newItem)
      .pipe( finalize( () => 
      {
          this.alertService.newAlert('success','is opgeslagen',3000,this.alerts, naamNieuwItem);
      }
      ) )
      .subscribe(
        gelukt => newEvent,
        error =>  
        {
          this.generalService.giveLog('error bij wegschrijven');
          this.alertService.newAlert('Fout','niet opgeslagen',3000,this.alerts, naamNieuwItem);
        },
        () => 
        {
          this.generalService.giveLog("wegschrijven OK");
          this.dataTonen();
          this.kopieOK = true;
        }
      );
    this.addNewItem = false;
    this.selectedSomething = false;
  }  

  kopieerVragenEnPrijzen() {
    this.generalService.giveLog("Nieuw event: " + this.newItem.evNaam);
    var newEventId = this.zoekNieuwID(this.newItem.evNaam);
    this.generalService.giveLog("Kopie van: " + this.copyFromID + " naar " + newEventId);
    this.kopieerVragen(this.copyFromID, newEventId);
    this.kopieerPrijzen(this.copyFromID, newEventId);
    this.kopieOK = false;
  }

  zoekNieuwID(eventNaam: string): number {
    var eventID = 0;    
    for (let index = 0; index < this.mainData.length; index++) {
        const element = this.mainData[index];

        this.generalService.giveLog("Eventnaam: " + element.evNaam);
        if (eventNaam == element.evNaam) {
          eventID = element.evEventID;
          this.generalService.giveLog("Eventnaam: " + element.evNaam + ": "+ eventID);
          break;
        }
    }
    return eventID;
  }

  kopieerVragen(eventIdFrom: number, eventIdTo: number) {
    var vragen: Form[];
    let observable = this.evenement_VragenService.getFilteredData(eventIdFrom);
    observable
    .subscribe(
      array => vragen = array,
      err=> this.generalService.giveLog(err),
      () => 
      {
        vragen.forEach(element => {
          element.eventID = eventIdTo;          
          this.generalService.giveLog(element.omschrijving + ' : ' + eventIdFrom + ' ==> ' + element.eventID);

          this.evenement_VragenService.addAPI_values(element)
          .pipe( finalize( () => this.alertService.newAlert('success','is opgeslagen',3000,this.alerts, element.omschrijving) ) )
          .subscribe(
            gelukt => this.newItem,
            error =>  
            {
              this.generalService.giveLog('error bij wegschrijven');
              this.generalService.giveLog(this.newItem);
            },
            () => 
            {
              this.generalService.giveLog(this.newItem);
            }
          );
        });
      }      
    )
  }

  kopieerPrijzen(eventIdFrom: number, eventIdTo: number) {
    var prijzen: Prijs[];
    let observable = this.prijsService.getFilteredData(eventIdFrom);
    observable
    .subscribe(
      array => prijzen = array,
      err=> this.generalService.giveLog(err),
      () => 
      {
        prijzen.forEach(element => {
          element.prEventID = eventIdTo;          
          this.generalService.giveLog(element.prOmschrijving + ' : ' + eventIdFrom + ' ==> ' + element.prEventID);

          this.prijsService.addRecord(element)
          .pipe( finalize( () => this.alertService.newAlert('success','is opgeslagen',3000,this.alerts, element.prOmschrijving) ) )
          .subscribe(
            gelukt => this.newItem,
            error =>  
            {
              this.generalService.giveLog('error bij wegschrijven');
              this.generalService.giveLog(this.newItem);
            },
            () => 
            {
              this.generalService.giveLog(this.newItem);
            }
          );
        });
      }      
    )
  }
}