export class Evenement {
    constructor(
        public evEventID:number,
        public evNaam: string,
        public evDatum: string,
        public evAanvangsTijd: string,
        public evEindDatumInschrijving: string,
        public evOmschrijving: string,
        public evMaxDeelnemers: number,
        public evMaxInschrijvingen: number,
        public evAfkortingNaam: string,
        public evWachtwoord: string,
        public evSlotformule: string,
        public evActief: string,
        public evTitelPrijzen: string,
        public evHerinneringstermijn: number,
        public evBetalingstermijn: number,
        public evAnnulatieTermijn: number,
        public evMailBody: string,
        public evMailFrom: string,
        public evMailSubject: string,
        public evMailAttachment: string,
        public evHerinneringBody1: string,
        public evHerinneringSubject: string,
        public evAnnuleringBody1: string,
        public evAnnuleringSubject: string,
        public evRptBevestigingID: number,
        public evWachtlijst: string,
        public evAanhefRapport: string,
        public evTarieven: string,
        public evActiefVanaf: string,
        public evRekeningNummer: string,
        public evMailBcc: string,
        public evLogoID: number,
        public evMailVerantwoordelijke: string,
        public evCheckMaximum: boolean,
        public evCheckNoMaxima: number,
        public evSuccesBoodschap: string,
        public evLogin: string,
        public evBetalend: boolean,
        public evBevestiging: boolean,
        public evTabs: string,
		public evCreated: string,
		public evPrijsVerplicht: boolean,
		public evAangemaakt: string,
		public evAangepast: string,
		public evReadonlyDN: boolean
    ){}

}