<!--The content below is only a placeholder and can be replaced.-->

<!-- <h1>{{title}}</h1> -->

<div class="container">
  <div class="row">
    <div class="col-sm-3 col-md-2 col-lg-2">
      <div class="list-group" id="sidebar">
        <a class="list-group-item list-group-item-action" id="0" (click)="ChangeSelected(0);" routerLink="/betaling">Betaling</a>
        <a class="list-group-item list-group-item-action" id="1" (click)="ChangeSelected(1);"  routerLink="/evenement">Evenementen</a>   
        <a class="list-group-item list-group-item-action"  id="2" (click)="ChangeSelected(2);" routerLink="/formvalues">Keuzelijsten</a>     
        <!-- <a class="list-group-item list-group-item-action" id="3" (click)="ChangeSelected(3);"  routerLink="/logo">Logo</a> -->
        <a class="list-group-item list-group-item-action" (click)="ChangeSelected(3);"  id="3" routerLink="/eventcopy">Kopieer event</a>
        <a class="list-group-item list-group-item-action" (click)="ChangeSelected(4);"  id="4" routerLink="/eventcreate">Nieuw event</a>
        <a *ngIf="loggedIn()" class="list-group-item" routerLink="/login">Afmelden</a>

      </div>
    </div>
    <div class="col-sm-9 col-md-10 col-lg-10">
        <router-outlet></router-outlet>
        <!-- <app-form-values></app-form-values> -->
    </div>
  </div>
</div>