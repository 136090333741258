//Standaard
  import { BrowserModule } from '@angular/platform-browser';
  import { NgModule } from '@angular/core';
  import { AppComponent } from './app.component';

//Bijkomende modules
  import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
  import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
  import { HttpClientModule } from '@angular/common/http';
  import { RouterModule, Routes } from '@angular/router';
  // import { HttpErrorHandler }     from './services/http-error-handler.service';
  // import { MessageService }       from './services/message.service';
  
  import { AlertModule } from 'ngx-bootstrap/alert';
  import { ButtonsModule} from 'ngx-bootstrap/buttons';
  import { DatepickerModule } from 'ngx-bootstrap/datepicker';
  import { TabsModule } from 'ngx-bootstrap/tabs';
  import { ModalModule } from 'ngx-bootstrap/modal';
  import { SortableModule } from 'ngx-bootstrap/sortable';
  import { OrderModule } from 'ngx-order-pipe';
  import { FilterPipeModule } from 'ngx-filter-pipe';

//Components
  import { EvenementComponent } from './gui/evenement/evenement.component';
  import { FormValuesComponent} from './gui/form-values/form-values.component';
  import { EventCopyComponent } from './gui/event-copy/event-copy.component';
  import { EventCreateComponent } from './gui/event-create/event-create.component';  
  import { EvenementVragen } from './gui/vragen/vragen.component';  
  import { CommunicatieComponent } from './gui/communicatie/communicatie.component';
  import { BetaalInfoComponent } from './gui/betaal-info/betaal-info.component';
  import { PrijsComponent } from './gui/prijs/prijs.component'; 
  import { AfhVragenComponent } from './gui/afh-vragen/afh-vragen.component';
  import { BetalingComponent } from './gui/betaling/betaling.component';

  import { FilterPipe } from './_pipes/filter.pipe';
  import { Nl2BrPipe } from './_pipes/nl2br.pipe';
import { AuthGuard } from './_guards/auth.guard';
import { WindowService } from './services/window.service';
import { LoginComponent } from './gui/login/login.component';



  const appRoutes: Routes = [
    { path: '',   redirectTo: 'gui/evenement', pathMatch: 'full' },
    { path: 'login', component: LoginComponent },
  
    
    //Uncomment voor debugging
    //Comment bij build
/*   { path: 'evenement', component: EvenementComponent },
    { path: 'formvalues', component: FormValuesComponent }, 
    { path: 'eventcopy', component: EventCopyComponent }, 
    { path: 'eventcreate', component: EventCreateComponent }, 
    { path: 'betaling', component: BetalingComponent},
    // { path: 'logo', component: LogoComponent },
    { path: '**', component: EvenementComponent }  */

    //Comment voor debugging
    //Uncomment bij build
  
     { path: 'evenementen', component: EvenementComponent, canActivate: [AuthGuard] },
     { path: 'formvalues', component: FormValuesComponent, canActivate: [AuthGuard] }, 
     { path: 'eventcopy', component: EventCopyComponent, canActivate: [AuthGuard] }, 
     { path: 'eventcreate', component: EventCreateComponent, canActivate: [AuthGuard] }, 
     { path: 'betaling', component: BetalingComponent, canActivate: [AuthGuard] },
     //{ path: 'logo', component: LogoComponent, canActivate: [AuthGuard] },
     { path: '**', component: EvenementComponent, canActivate: [AuthGuard] }  
  ];

@NgModule({
  declarations: [
    AppComponent,
    EvenementComponent,
    FormValuesComponent,
    EventCopyComponent,
    FilterPipe,
    Nl2BrPipe,
    EventCreateComponent,
    EvenementVragen,
    CommunicatieComponent,
    BetaalInfoComponent,
    PrijsComponent,
    AfhVragenComponent,
    BetalingComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule,    
    FormsModule,  
    ReactiveFormsModule,  
    FilterPipeModule,
    OrderModule,
    NgbModule,
    HttpClientModule,
	  AlertModule.forRoot(),
    ButtonsModule.forRoot(),
    DatepickerModule.forRoot(),
    TabsModule.forRoot(),
    ModalModule.forRoot(),
    SortableModule.forRoot(),
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: true } // <-- debugging purposes only
    )],
  providers: [AuthGuard, WindowService],
  bootstrap: [AppComponent]
})
export class AppModule { }
