import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Afh_Vraag } from './../entities/afh_vraag.model';
import { Afh_Vragen_View } from './../entities/afh_vraag.model';
import { Afh_Keuzes_View } from './../entities/afh_vraag.model';
//import { Form } from '../entities/form.model';
import { GeneralService } from './general.service';

const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'my-auth-token'
    })
  };


@Injectable()
//##### AANPASSEN #############
export class AfhVragenService {
  apiName = "afh_vragen";
  apiFilteredName = "afh_vragen_view";
  apiAfhKeuzes = "afh_keuzes_view";
   
  apiUrl = 'https://api.spermalie.info/'+this.apiName+'/read.php';
  filteredUrl = 'https://api.spermalie.info/'+this.apiFilteredName+'/read_one_event.php?eventID=';
  afhKeuzesUrl = 'https://api.spermalie.info/'+this.apiAfhKeuzes+'/read_one_question.php?formID=';

  constructor(private http: HttpClient){}

  generalService = new GeneralService(this.http);

  getAPI_Values (): Observable<Afh_Vraag[]> {
      return this.http.get<Afh_Vraag[]>(this.apiUrl);
  }

  getFilteredData (eventID:number): Observable<Afh_Vragen_View[]> {
      var url = this.filteredUrl + eventID;
      console.log(url);
      return this.http.get<Afh_Vragen_View[]>(url);
  }

  getAfhKeuzes (formID:number): Observable<Afh_Keuzes_View[]> {
    var url = this.afhKeuzesUrl + formID;
    console.log(url);
    return this.http.get<Afh_Keuzes_View[]>(url);
  }

    //////// CRUD //////////

  /** POST: add a new lijstje to the database */
  addRecord (instance: Afh_Vraag): Observable<Afh_Vraag> {
    return this.http.post<Afh_Vraag>('https://api.spermalie.info/'+this.apiName+'/create.php', JSON.stringify(instance))
  }

  deleteRecord (id: number): Observable<{}> {
    let body = JSON.stringify({ID: id});
    this.generalService.giveLog(body);
    return this.http.post<Afh_Vraag>('https://api.spermalie.info/'+this.apiName+'/delete.php', body)
  }

  updateRecord (instance: Afh_Vraag): Observable<Afh_Vraag> {
    return this.http.post<Afh_Vraag>('https://api.spermalie.info/'+this.apiName+'/update.php', JSON.stringify(instance))
  }

}