import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IAlert } from '../../entities/alert.model';
import { finalize, debounceTime } from 'rxjs/operators';
import { AlertService} from '../../services/alerts.service';
import { AppComponent } from '../../app.component';

import { Evenement } from '../../entities/evenement.model';
import { Form } from '../../entities/form.model';
import { VeldType } from '../../entities/veldtype.model';
import { Keuzes } from '../../entities/keuzes.model';
import { Afh_Vraag } from '../../entities/afh_vraag.model';
import { Form_Bestemming } from '../../entities/form_bestemming.model';
import { Form_values } from '../../entities/form_values.model';

import { GeneralService } from '../../services/general.service';
import { Evenement_VragenService } from '../../services/evenement-vragen.service';
import { VeldTypeService } from '../../services/veldType.service';
import { Form_BestemmingService } from '../../services/form_bestemming.service';
import { Form_ValuesService } from '../../services/form_values.service';

import { ModalDirective } from 'ngx-bootstrap/modal';
import { delay } from 'q';

@Component({
  selector: 'app-vragen',
  templateUrl: './vragen.component.html',
  styleUrls: ['./vragen.component.css'],
  providers: [Evenement_VragenService, 
              GeneralService, 
              FormBuilder, 
              VeldTypeService, 
              Form_BestemmingService,
              Form_ValuesService,
              AlertService]
})
export class EvenementVragen {
  @Input() item: Evenement;
  @Input() scroll: boolean;
  itemForm = new FormGroup ({
    cOmschrijving: new FormControl()
  });
  //### specifieke variabelen: aan te passen
  tableName = "tbl_form";
  primKeyField = 'ID';
  primTableArray: Form[];
  initialPrimTableArray: Form[];
  selRecord: Form;
  searchField: string = "omschrijving";
  order: string = 'volgorde';
  editItem: boolean = false;
  createItem: boolean = false;
  newItem: Form;
  tempArray: Form;
  alerts: Array<IAlert> = [];
  //tempVeldTypeArr: VeldType;

  jaNee = ['J', 'N'];
  controlTypes = [];
  veldTypeArray: VeldType[];
  bestemmingArray: Form_Bestemming[];
  formValuesArray: Form_values[];

//### algemene variabelen: niet aan te passen
  reverse: boolean = false;
  showGeneral: boolean = false;
  updateKeyID: number;
  removeKeyID: number;
  itemAdded: boolean;
  errorMessage: string = "";
  newKey: number;
  textAreaClass: string [];  
  initialFieldValues;
  private tick: number;
  counter:number; 
  sortAction:boolean;
  radioDetailFix: boolean = false;

  constructor(private generalService: GeneralService, 
              private evenement_VragenService:Evenement_VragenService, 
              private veldTypeService: VeldTypeService,
              private alertService: AlertService,
              private form_BestemmingService: Form_BestemmingService,
              private form_ValuesService: Form_ValuesService,
              private fb: FormBuilder
            ) { 
    this.errorMessage = generalService.errorMessage;
    this.createForm();
  }

//### ngActions
ngOnInit() {
  console.log('init vragen');
  this.dataOphalen();
  this.comboLijstenOphalen()
  this.initialFieldValues = new Array(); 
  this.selRecord = new Form(0,0,0,"","",0,"",0,0,"","","");
}

//### Functies die aangepast moeten worden aan de specifieke pagina
radioDetailFixToggle() {
 
  this.radioDetailFix = this.radioDetailFix ? false : true;
  //console.log('selectedTab: ' + this.selectedTab);
  //this.tabSelected(this.selectedTab);          
  this.generalService.giveLog('radioDetailFix: ' + this.radioDetailFix);
  //console.log('radioDetailFix: ' + this.radioDetailFix);
}

comboLijstenOphalen() {

    let observable = this.veldTypeService.getmainData();
    observable
    .subscribe(
      array => this.veldTypeArray = array,
      err=> this.generalService.giveLog(err),
      () => 
      {
      this.generalService.giveLog('VeldTypes opgehaald uit database; ' + this.veldTypeArray.length + ' records');
       this.errorMessage = "";
      //  this.generalService.giveLog("---veldTypes:");
      //  this.veldTypeArray.forEach(element => {
      //   this.generalService.giveLog(element.veldType);
      //  });
       
      }      
    );

    let obs_form_bestemming = this.form_BestemmingService.getmainData();
    obs_form_bestemming
    .subscribe(
      array => this.bestemmingArray = array,
      err=> this.generalService.giveLog(err),
      () => 
      {
      this.generalService.giveLog('form_bestemmingen opgehaald uit database; ' + this.bestemmingArray.length + ' records');
       this.errorMessage = "";
      //  this.generalService.giveLog("---form_bestemmingen:");
      //  this.bestemmingArray.forEach(element => {
      //   this.generalService.giveLog(element.omschrijving);
      //  });
       
      }      
    );

    //formValuesArray
    this.form_ValuesService.getmainData()
    .subscribe(
      array => this.formValuesArray = array,
      err=> this.errorMessage = "Namen van lijsten niet opgehaald",
      () => 
      {
        this.generalService.giveLog('formValues opgehaald uit database; ' + this.formValuesArray.length + ' records');
        // this.generalService.giveLog("---formValues:");
        // this.formValuesArray.forEach(element => {
        //  this.generalService.giveLog(element.waardeName);
        // });

      }
   );

}

dataOphalen() {

    var primTableArrayUnOrdered: Form[];
    let observable = this.evenement_VragenService.getFilteredData(this.item.evEventID);
    observable
    .subscribe(
      array => primTableArrayUnOrdered = array,
      err=> this.generalService.giveLog(err),
      () => 
      {
      //this.generalService.giveLog('Tabel opgehaald uit database; ' + primTableArrayUnOrdered.length + ' records');
       this.errorMessage = "";
       //this.generalService.giveLog(primTableArrayUnOrdered[0].omschrijving);
       this.primTableArray = this.sortArray(primTableArrayUnOrdered);
       this.initialPrimTableArray = this.primTableArray;
      //  this.initialPrimTableArray.forEach(element => {
       this.generalService.giveLog(this.primTableArray[0].omschrijving);
      //  });
       
      }      
    )
    
    this.newKey = 0;
}

//#### Functies voor reactive forms
  createForm() {
    this.editItem = false;
    this.itemForm = this.fb.group({
      cOmschrijving: ['', Validators.required ],
      cSoort: ['', Validators.required ],
      cVerplicht: ['', Validators.required ],      
      cWaarde: null,
      cLijst: null,
      cBestemming: null,
      cMaxDeelnemers: null,
      cTitel: '', 
      cVergelijkMet:''
    });
  } 

  populateForm(omschrijving, soort, waarde, verplicht, bestemming, maxDeelnemers, titel, vergelijkMet, itemSelected) {
    this.generalService.giveLog('populateForm');
    this.editItem = true;
    this.selRecord = itemSelected;
    //this.generalService.giveLog("selRecord.ID" + this.selRecord.ID)
    this.generalService.giveLog('omschrijving = ' + omschrijving);
    this.itemForm.setValue ({
      cOmschrijving: omschrijving,
      cSoort: soort,
      cWaarde: waarde,
      cVerplicht: verplicht,
      cLijst: waarde,
      cBestemming: bestemming,
      cMaxDeelnemers: maxDeelnemers,
      cTitel: titel, 
      cVergelijkMet: vergelijkMet
    });
  } 

  saveRecord(omschrijving, soort, waarde, verplicht, bestemming, maxDeelnemers,titel, vergelijkMet) {
    var volgorde;
    
    if (this.selRecord != null) {
      this.generalService.giveLog("selRecord.ID" + this.selRecord.ID)
      volgorde = this.selRecord.volgorde;
      //vergelijkMet = this.selRecord.vergelijkMet;
    }
    else {
      volgorde = this.bepaalVolgorde();
      //vergelijkMet = "";
    }
    var omschrijving_underscore = omschrijving.replace(" ", "_");
    this.generalService.giveLog(omschrijving_underscore)
    this.newItem = new Form (null, this.item.evEventID ,volgorde,omschrijving,soort,waarde, verplicht, bestemming,maxDeelnemers, titel, omschrijving_underscore, vergelijkMet);
    if(this.createItem) {
      var response;
      this.createItem = false;
      this.generalService.giveLog('Create Item:' + JSON.stringify(this.newItem));

      this.evenement_VragenService.addAPI_values(this.newItem)
      .pipe( finalize( () => this.alertService.newAlert('success','is opgeslagen',3000,this.alerts, this.newItem.omschrijving) ) )
      .subscribe(
        gelukt => this.newItem,
        error =>  
        {
          this.generalService.giveLog('error bij wegschrijven');
          this.generalService.giveLog(this.newItem);
        },
        () => 
        {
          this.generalService.giveLog(this.newItem);
          this.primTableArray.push(this.newItem);
          this.dataOphalen();
        }
      );
    }
    else {
      this.newItem.ID = this.selRecord.ID;
      this.updateRecord(this.newItem);
      this.generalService.giveLog('Create Item / update');
    }
      this.editItem = false;    
  }

  createNew() {
    this.generalService.giveLog("#### Create"); 
    this.createItem = true;
    this.editItem = true;
    this.populateForm('','',null,'',null,null,'','', undefined);
  }

  bepaalVolgorde() {
    var volgorde:number = 1;
    this.primTableArray.forEach(element => {
      if (element.volgorde > volgorde) {
        volgorde = element.volgorde;
      }
    });
    return volgorde + 1;
  }

//### Functies voor sortable
  updateSortOrder() {
    this.generalService.giveLog("drag ended");
    this.counter = 0;
    this.primTableArray.forEach(element => {
        /*this.generalService.giveLog(element.ID + ' = ' + this.counter + ' - ' + element.omschrijving + " (" + element.volgorde + ") *** " +  
        this.initialPrimTableArray[this.counter].omschrijving + " (" + this.initialPrimTableArray[this.counter].volgorde + ")");  */
        
        if(element.volgorde != this.counter) {
          element.volgorde = this.counter;
          this.updateRecord(element);
        }            
        this.counter++;  
    })
  }

  sortArray(tableArray:Form[]) {
    this.sortAction = true;
    while(this.sortAction){
      this.sortAction = false;
      this.counter = 0;
      tableArray.forEach(element => {
        if(this.counter > 0) {
          if(Number(tableArray[this.counter].volgorde) < Number(tableArray[this.counter -1].volgorde)) {
            this.tempArray = tableArray[this.counter];
            tableArray[this.counter] = tableArray[this.counter -1];
            tableArray[this.counter -1] = this.tempArray;
            this.sortAction = true;
          }
        }
        this.counter++;
      })            
    }
    return tableArray;
  }

  sortControlType(tableArray:VeldType[]) {
    this.sortAction = true;
    var tempVeldTypeArr: VeldType;
    while(this.sortAction){
      this.sortAction = false;
      this.counter = 0;
      tableArray.forEach(element => {
        if(this.counter > 0) {
          //this.generalService.giveLog(this.counter + ' - ' + element.veldType);
          if(tableArray[this.counter].veldType < tableArray[this.counter -1].veldType) {
            tempVeldTypeArr = tableArray[this.counter];
            tableArray[this.counter] = tableArray[this.counter -1];
            tableArray[this.counter -1] = tempVeldTypeArr;
            this.sortAction = true;
          }
        }
        this.counter++;
      })            
    }
    return tableArray;
  }

  sortBestemming(tableArray:Form_Bestemming[]) {
  this.sortAction = true;
  var tempBestemmingArr: Form_Bestemming;
  while(this.sortAction){
    this.sortAction = false;
    this.counter = 0;
    tableArray.forEach(element => {
      if(this.counter > 0) {
        //this.generalService.giveLog(this.counter + ' - ' + element.omschrijving);
        if(tableArray[this.counter].omschrijving < tableArray[this.counter -1].omschrijving) {
          tempBestemmingArr = tableArray[this.counter];
          tableArray[this.counter] = tableArray[this.counter -1];
          tableArray[this.counter -1] = tempBestemmingArr;
          this.sortAction = true;
        }
      }
      this.counter++;
    })            
  }
  return tableArray;
  }

  sortLijsten(tableArray:Form_values[]) {
  this.sortAction = true;
  var tempLijstenArr: Form_values;
  while(this.sortAction){
    this.sortAction = false;
    this.counter = 0;
    tableArray.forEach(element => {
      if(this.counter > 0) {
        if(tableArray[this.counter].waardeName < tableArray[this.counter -1].waardeName) {
          tempLijstenArr = tableArray[this.counter];
          tableArray[this.counter] = tableArray[this.counter -1];
          tableArray[this.counter -1] = tempLijstenArr;
          this.sortAction = true;
        }
      }
      this.counter++;
    })            
  }
  return tableArray;
  }    

//CRUD
updateRecord(form: Form) {
  this.generalService.giveLog("update: " + form.omschrijving + " --> " + form.volgorde);
  var observable = this.evenement_VragenService.updateItem(form);
  observable.subscribe((response) => {
    this.generalService.giveLog(JSON.stringify(response));
    var callResponse = JSON.stringify(response);
    if (callResponse == '{"update":"OK"}') {
      this.generalService.giveLog("OK");
      this.selRecord = form;
      //this.updateArray(form);
      this.dataOphalen();
    } else {
      this.generalService.giveLog("not OK");
    }
  });
}

// updateArray(form:Form) {
//   for (let index = 0; index < this.primTableArray.length; index++) {
//     var element = this.primTableArray[index];
//     if (element.ID == form.ID) {
//       this.primTableArray[index] = form;
//       this.generalService.giveLog("Omschrijving: " + this.primTableArray[index].omschrijving);
//       break;
//     }
//   }
// }

deleteRecord(ID: number) {
  this.generalService.giveLog("delete: " + ID);
  var observable = this.evenement_VragenService.deleteAPI_values(ID);
  observable.subscribe(
    (response) => {
    this.generalService.giveLog(JSON.stringify(response));
    // var callResponse = JSON.stringify(response);
    // if (callResponse == '{"update":"OK"}') {
    //   this.generalService.giveLog("OK");
    // } else {
    //   this.generalService.giveLog("not OK");
    // }
    },
    error =>
    {
      this.generalService.giveLog('Fout');
    },
    () => this.dataOphalen()
  );
}

}
