import { Component, OnInit, Input } from '@angular/core';
import { Form_ValuesService } from '../../services/form_values.service';
import { Form_values } from '../../entities/form_values.model';
import { Form_value_item} from '../../entities/form_value_item.model';
import { Form_Value_ItemsService } from '../../services/form_value_items.service';
import { IAlert } from '../../entities/alert.model';
import { GeneralService} from '../../services/general.service';
import { finalize, debounceTime } from 'rxjs/operators';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-form-values',
  templateUrl: './form-values.component.html',
  providers: [ Form_ValuesService, GeneralService, Form_Value_ItemsService ],
  styleUrls: ['./form-values.component.css']
})

export class FormValuesComponent implements OnInit {
  title = "Lijsten";

  mainData: Form_values[];
  filteredMainData: Form_values[];
  subData : Form_value_item[];
  errorMessage: string="";
  searchFilter: string="";
  public alerts: Array<IAlert> = [];

  selectedItem: Form_values;
  selectedSubItem: Form_value_item;
  selectedSomething: boolean = false;
  selectedFormValueItem: boolean = false;
  showItemDetails: boolean = true;
  magOpslaan: boolean;
  magOpslaanSub: boolean;
  addNewItem: boolean = false;
  addNewSubItem: boolean = false;
 
  constructor(private form_ValuesService : Form_ValuesService, 
              private modalService: NgbModal,
              private generalService: GeneralService,
              private form_Value_ItemsService: Form_Value_ItemsService
              ) { }

  checkNaam(naam:string):void{
    this.generalService.giveLog('1: ' + naam);
    if (this.form_ValuesService.checkUniekeNaam(naam, this.mainData)) {
      document.getElementById("fvNewNaam").className = "form-control ng-touched ng-dirty ng-valid";
      this.magOpslaan = true;
    } else {  
      document.getElementById("fvNewNaam").className = "form-control ng-touched ng-dirty ng-invalid";
      this.magOpslaan = false;
      this.newAlert('Fout','Naam niet uniek',3000,naam);
    }
  }

  checkNaamSubItem(naam:string):void{
    this.generalService.giveLog('1: ' + naam);
    if (this.form_Value_ItemsService.checkUniekeNaam(naam, this.subData)) {
      document.getElementById("newItemNaam").className = "form-control ng-touched ng-dirty ng-valid";
      this.magOpslaanSub = true;
    } else {  
      document.getElementById("newItemNaam").className = "form-control ng-touched ng-dirty ng-invalid";
      this.magOpslaanSub = false;
      this.newAlert('Fout','Naam niet uniek',3000,naam);
    }
  }

  ngOnInit() {
    this.dataTonen();
    //console.log('Eerste: ' + this.mainData[0].waardeName);
   }
  
  private newAlert(type: string, message: string, timeout:number, subject?: string) {
    var newAlert: IAlert;
    newAlert = {type:type,message:message,timeout:timeout,subject:subject};

    this.alerts.push(newAlert)
    setTimeout(() => this.alerts.splice(this.alerts.indexOf(newAlert),1), timeout);
  }

  public closeAlert(alert: IAlert) {
    const index: number = this.alerts.indexOf(alert);
    this.alerts.splice(index, 1);
  }

  selectFirst(): void {
    this.selectedItem = this.mainData[0];
    console.log(this.mainData[0]);
  }

  dataTonen(): void {
    
     this.form_ValuesService.getmainData()
       .subscribe(
         array => this.mainData = array,
         err=> this.errorMessage = "Namen van lijsten niet opgehaald",
         () => 
         {
          this.generalService.giveLog(this.mainData); 
          this.errorMessage = "";
          this.sorteer();
          this.filteredMainData = this.mainData.filter(s => s.waardeName.includes(this.searchFilter))

         }
      );
      this.generalService.giveLog("Error message = " + this.errorMessage);
      this.magOpslaan = false;
  }

  subDataTonen(id:number): void {
    this.form_Value_ItemsService.getFilteredData(id)
      .subscribe(
        array => this.subData = array,
        err=> this.errorMessage = "Namen van lijstitems niet opgehaald",
        () => 
        {
         //this.generalService.giveLog(this.subData); 
         this.generalService.giveLog(this.subData[0].itemNaam);
         this.errorMessage = "";
         this.sorteerSubData();
        }
     );
     this.generalService.giveLog(this.errorMessage); 
     this.magOpslaanSub = false;
      
 }

  sorteer():void{
      this.mainData = this.mainData.sort((f1, f2) => {
      return f1.waardeName < f2.waardeName ? -1 :
            (f1.waardeName > f2.waardeName ? 1 : 0);
    });
  }

  sorteerSubData():void{
    this.subData = this.subData.sort((f1, f2) => {
    return f1.itemSorteer < f2.itemSorteer ? -1 :
          (f1.itemSorteer > f2.itemSorteer ? 1 : 0);
  });
}

  sorteerAny(teSorteren):any[]{
    teSorteren = teSorteren.sort((f1, f2) => {
    return f1[1] < f2[1] ? -1 :
          (f1[1] > f2[1] ? 1 : 0);
    });
    return teSorteren;
  }

  onSelect(form_Value: Form_values): void {
    this.addNewItem = false;
    this.selectedSomething = true;
    this.selectedFormValueItem = false;
    this.showItemDetails = true;
    this.selectedItem = form_Value;
    this.generalService.giveLog(form_Value.waardeName);

    this.magOpslaan = false;
    this.generalService.giveLog(this.selectedItem.ID); 
    this.subDataTonen(this.selectedItem.ID);
    console.log(JSON.stringify(this.selectedItem));
  }

  onSelectFormValueItem(form_value_item: Form_value_item)
  {
    this.addNewItem = false;
    this.selectedSomething = false;
    this.selectedFormValueItem = true;
    this.selectedSubItem = form_value_item;
    this.generalService.giveLog(form_value_item.itemNaam);
  }

  addItem(): void {
    this.selectedSomething = false;
    this.showItemDetails = false;
    this.addNewItem = true;
  }

  addSubItem(): void {
    this.addNewItem = false;
    this.selectedSomething = false;
    this.selectedFormValueItem = false;
    this.addNewSubItem = true;    
  }
  
  addItemSave(waardeName: string): void {
    if (this.magOpslaan) {
      waardeName = waardeName.trim();
      this.newAlert('info','wordt opgeslaan ...',3000,waardeName);
      if (!waardeName) { return; }

      const newItem: Form_values = { waardeName } as Form_values;
      this.form_ValuesService.addForm_values(newItem)
        .pipe( finalize( () => 
        {
          this.newAlert('success','is opgeslagen',3000,newItem.waardeName);
          this.dataTonen();
        }
        ) )
        .subscribe(formvalue => this.mainData.push(formvalue) 
        );
      this.addNewItem = false;      
    } else {
      this.newAlert('info','Naam niet uniek',3000,waardeName);
    }
  }

  addSubItemSave(itemNaam: string, itemSorteer: string, itemNum: string, itemEenheid: string, itemMax: string):void {
    var sortNr;

    this.generalService.giveLog(itemNaam);
    this.generalService.giveLog(itemSorteer);
    if (itemSorteer == null || itemSorteer == "") {
      sortNr = this.form_Value_ItemsService.nextSortID(this.subData);
      this.generalService.giveLog('sorteernummer: ' + sortNr);
    } 
    else  {
      sortNr = Number(itemSorteer);
    }    
    if (itemMax == "0") {
      itemMax= undefined;
    }
    var newSubItem: Form_value_item = {
      itemID:0,
      valuesID: this.selectedItem.ID,
      itemNaam: itemNaam,
      itemNum: Number(itemNum),
      itemEenheid: itemEenheid,
      itemSorteer:sortNr,
      itemMax:Number(itemMax),
      itemNow:0
    };
    if (newSubItem.itemMax == 0) {
      newSubItem.itemMax = undefined;
    }
    this.form_Value_ItemsService.addItem(newSubItem)
    .pipe( finalize( () => 
        {
          this.newAlert('success','is opgeslagen',3000,newSubItem.itemNaam);
          this.subDataTonen(this.selectedItem.ID);
        }
        ) )
        .subscribe(response => this.subData.push(response) 
        );
    this.generalService.giveLog(newSubItem);
  }

  giveDefaultSortNr() {
    this.generalService.giveLog("Default sort = 000");  
  }

  modalDelete(content,index:number) {
    this.selectedItem = this.mainData[index];
    this.modalService.open(content).result.then((result) => {
      if (result == "Verwijder") {
        this.newAlert('info','wordt verwijderd...',3000,this.selectedItem.waardeName);
        this.selectedSomething = false;

        this.form_ValuesService.deleteForm_values(this.selectedItem.ID)
          .pipe( finalize( () => this.newAlert('success','is verwijderd',3000,this.selectedItem.waardeName) ) )
          .subscribe();

        this.mainData.splice(index,1);

      } else if (result == "Annuleer") {
        this.newAlert('info','wordt niet verwijderd',3000,this.selectedItem.waardeName);
      }
      if (result == "VerwijderDetail") {
        this.newAlert('info','wordt verwijderd...',3000,this.selectedSubItem.itemNaam);
        this.selectedFormValueItem = false;

        this.form_Value_ItemsService.deleteItem(this.selectedSubItem.itemID)
          .pipe( finalize( () => 
          {
            this.newAlert('success','is verwijderd',3000,this.selectedSubItem.itemNaam);
            this.subDataTonen(this.selectedItem.ID);
          }
        ) )
          .subscribe();
      } else if (result == "AnnuleerDetail") {
        this.newAlert('info','wordt niet verwijderd',3000,this.selectedSubItem.itemNaam);
      }
    });
  }
  
  cancelItem(): void {
    this.selectFirst();
  }

  saveItem(): void {
    this.selectedSomething = false; 
    this.showItemDetails = false;
    // code to save changes to selectedItem
    this.generalService.giveLog('Update: ' + this.selectedItem.waardeName);
    this.form_ValuesService.updateForm_values(this.selectedItem)
    .pipe( finalize( () => this.newAlert('success','is opgeslagen',3000,this.selectedItem.waardeName) ) )
    .subscribe( 
    //.subscribe(formvalue => this.generalService.giveLog(formvalue) 
    );
  }  

  saveSubItem(): void {
    this.selectedFormValueItem = false;
    this.generalService.giveLog('Update: ' + this.selectedSubItem.itemNaam);
    if (this.selectedSubItem.itemMax == 0) {
      this.selectedSubItem.itemMax = undefined;
    }
    this.form_Value_ItemsService.updateItem(this.selectedSubItem)
    .pipe( finalize( () => this.newAlert('success','is opgeslagen',3000,this.selectedSubItem.itemNaam) ) )
    .subscribe();
  }
}