import { Component } from '@angular/core';
//import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Spermalie';
  listIndex: number;
  jwtHelper = new JwtHelperService();

  ChangeSelected(id: number): void{
    console.log('######################################################');
      for (let index = 0; index < 5; index++) {
        let selectedItem = document.getElementById(index.toString());
        selectedItem.className = "list-group-item list-group-item-action";
      }
      let selectedItem = document.getElementById(id.toString());
      selectedItem.className = "list-group-item list-group-item-action active";
  }  

  loggedIn() {
    let jwt = localStorage.getItem('id_token');
    if (jwt) {
        return !this.jwtHelper.isTokenExpired(jwt);
    } else {
        return false;
    }
   }
}


