<div class="alert alert-danger" role="alert" [hidden]="errorMessage === ''" [innerHTML]="errorMessage"></div>
<div>
    <div class="row mt-2">
        <div class="col-sm-3 col-md-3 col-lg-2">
            <h1>{{title}}</h1>
        </div>       
        <div class="col-sm-1"></div>   
        <div class="col-sm-8 col-md-8 col-lg-9 border border-dark p-3">
            <div class="row justify-content-end">
                <div class="col-sm-1 pt-1" style="font-weight:bold">Filter:</div>   
                <div class="col-sm-3 col-md-3 col-lg-3">
                    <select class="form-control" #searchEVField (change)="chooseFilterField(searchEVField.value)">
                        <option value="evActief">Actief</option>
                        <option value="evNaam">Naam</option>
                        <option value="evWachtwoord">Wachtwoord</option>
                    </select>
                </div>
                <div class="col-sm-3 col-md-3 col-lg-3">
                    <div [hidden]="searchEVField.value === 'evActief'">
                        <input class="form-control" type="text" placeholder="Zoek" [(ngModel)]="searchEVString">
                    </div>
                    <div [hidden]="searchEVField.value !== 'evActief'">
                        <select class="form-control" [(ngModel)]="searchEVString">
                            <option value="J">Actief</option>
                            <option value="N">Niet actief</option>
                        </select>
                    </div>
                </div>         
            </div>
            <div style="margin-top: 10px" class="row">
                <div class="col-sm-12">
                    <select class="form-control" #eventFilter (change)="dataOphalen(eventFilter.value)">
                        <option>---</option>
                        <option *ngFor="let event of eventArray | filter: searchEVString:searchEVField.value | orderBy: 'evNaam'" value="{{event.evEventID}}">{{ event.evNaam }}</option>
                    </select>
                </div>
            </div>
        </div>
    </div><BR>        
    <div style="margin-top: 10px" class="row justify-content-end">
        <div class="col-sm-1 pt-1" style="font-weight:bold">Filter:</div>   
        <div class="col-sm-3">
            <select class="form-control" #searchField (change)="chooseFilterField(searchField.value)">
                <option value="fullname">Deelnemer</option>      
                <option value="isBedragBetaald">Betaald</option>
            </select>
        </div>
        <div class="col-sm-3">
            <input class="form-control input-sm" type="text" placeholder="Zoek" [(ngModel)]="searchString"> 
        </div>         
    </div><BR>
    <table class="table table-condensed table-hover">
        <thead>
            <tr>
                <th [class.active]="order === 'isInschrijvingsID'" style="width:10%"
                (click)="setOrder('isInschrijvingsID')"
                class="searchColumn">
                    ID 
                    <span class="" [hidden]="order === 'isInschrijvingsID'"><i class="fas fa-sort pointy" aria-hidden="true"></i></span>
                    <span class="fontGreen" [hidden]="reverse || order !== 'isInschrijvingsID'"><i class="fas fa-sort-alpha-down pointy" aria-hidden="true"></i></span>
                    <span class="fontRed" [hidden]="!reverse || order !== 'isInschrijvingsID'"><i class="fas fa-sort-alpha-up pointy" aria-hidden="true"></i></span>
                </th>                 
                <th [class.active]="order === 'fullname'" style="width:30%"
                        (click)="setOrder('fullname')"
                        class="searchColumn">
                        Deelnemer 
                        <span class="" [hidden]="order === 'fullname'"><i class="fas fa-sort pointy" aria-hidden="true"></i></span>
                        <span class="fontGreen" [hidden]="reverse || order !== 'fullname'"><i class="fas fa-sort-alpha-down pointy" aria-hidden="true"></i></span>
                        <span class="fontRed" [hidden]="!reverse || order !== 'fullname'"><i class="fas fa-sort-alpha-up pointy-alt" aria-hidden="true"></i></span>
                </th>
                <th [class.active]="order === 'isTotaalAantal'"
                        (click)="setOrder('isTotaalAantal')"
                        class="searchColumn">
                        Aantal 
                        <span class="" [hidden]="order === 'isTotaalAantal'"><i class="fas fa-sort pointy" aria-hidden="true"></i></span>
                        <span class="fontGreen" [hidden]="reverse || order !== 'isTotaalAantal'"><i class="fas fa-sort-alpha-down pointy" aria-hidden="true"></i></span>
                        <span class="fontRed" [hidden]="!reverse || order !== 'isTotaalAantal'"><i class="fas fa-sort-alpha-up pointy-alt" aria-hidden="true"></i></span>
                </th>                 
                <th [class.active]="order === 'isTeBetalen'" style="width:15%"
                        (click)="setOrder('isTeBetalen')"
                        class="searchColumn">
                        Te Betalen 
                        <span class="" [hidden]="order === 'isTeBetalen'"><i class="fas fa-sort pointy" aria-hidden="true"></i></span>
                        <span class="fontGreen" [hidden]="reverse || order !== 'isTeBetalen'"><i class="fas fa-sort-alpha-down pointy" aria-hidden="true"></i></span>
                        <span class="fontRed" [hidden]="!reverse || order !== 'isTeBetalen'"><i class="fas fa-sort-alpha-up pointy-alt" aria-hidden="true"></i></span>
                </th> 
                <th [class.active]="order === 'isBedragBetaald'" style="width:10%"
                        (click)="setOrder('isBedragBetaald')"
                        class="searchColumn">
                        Betaald 
                        <span class="" [hidden]="order === 'isBedragBetaald'"><i class="fas fa-sort pointy" aria-hidden="true"></i></span>
                        <span class="fontGreen" [hidden]="reverse || order !== 'isBedragBetaald'"><i class="fas fa-sort-alpha-down pointy" aria-hidden="true"></i></span>
                        <span class="fontRed" [hidden]="!reverse || order !== 'isBedragBetaald'"><i class="fas fa-sort-alpha-up pointy-alt" aria-hidden="true"></i></span>
                </th>               
                <th [class.active]="order === 'isTafelNr'" style="width:10%"
                    (click)="setOrder('isTafelNr')"
                    class="searchColumn">
                    Tafel 
                    <span class="" [hidden]="order === 'isTafelNr'"><i class="fas fa-sort pointy" aria-hidden="true"></i></span>
                    <span class="fontGreen" [hidden]="reverse || order !== 'isTafelNr'"><i class="fas fa-sort-alpha-down pointy" aria-hidden="true"></i></span>
                    <span class="fontRed" [hidden]="!reverse || order !== 'isTafelNr'"><i class="fas fa-sort-alpha-up pointy-alt" aria-hidden="true"></i></span>
                </th> 

                <th style="text-align: right">Bewerk</th>
            </tr>
        </thead>

        <tbody>
            <tr class="enhanced" *ngFor="let item of primTableArray | filter: searchString:searchField.value | orderBy: order : reverse">
                <td>{{item.isInschrijvingsID}}</td>
                <td [class.fontRed]="item.isReferentieBetaling === 'Weigering'">{{item.fullname}}</td>
                <td>{{item.isTotaalAantal}}</td>
                <td>{{item.isTeBetalen}}</td>
                <td>
                    <input class="form-control"  type="number" 
                        #isBedragBetaald
                        value={{item.isBedragBetaald}} id={{item.isInschrijvingsID}}
                        (focus)="initialValue('isBedragBetaald',isBedragBetaald.value);"
                        (blur)="saveRecord('isBedragBetaald',isBedragBetaald.value, isBedragBetaald.id);"
                        >
                </td>
                <td>
                    <input class="form-control" type="text"
                        #isTafelNr
                        value={{item.isTafelNr}} id={{item.isInschrijvingsID}}
                        (focus)="initialValue('isTafelNr',isTafelNr.value);"
                        (blur)="saveRecord('isTafelNr', isTafelNr.value, isTafelNr.id);"
                        >
                </td>
                <td style="text-align: right">
                        <input class="form-control"  type="hidden" 
                        #isInschrijvingsID
                        value={{item.isInschrijvingsID}}> 
                    <a target="_blank" [href]="'https://spermalie.info/info/'+ item.evAfkortingNaam +'/' + item.isInschrijvingsID + '/' + item.isInschrijvingRef + '/' + editCheck"><span class='fas fa-pencil-alt text-success pointy'></span></a>
                    <span>&nbsp;&nbsp;</span>
                    <span (click)="showChildModal(item)" class="fas fa-times text-warning pointy" aria-hidden="true"></span>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<div bsModal #childModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Inschrijving verwijderen</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideChildModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" *ngIf="selectedItem">
                    <h2>Wil je de volgende inschrijving verwijderen?</h2>          
            <div class="row" >
                <div class="col-sm-1">
                    <label>Naam:</label>
                </div>   
                <div class="col-sm-4">
                    <span>{{selectedItem.fullname}}</span>
                </div>                   
            </div>
            <div class="row" >
                <div class="col-sm-1">
                    <label>Ref:</label>
                </div> 
                <div class="col-sm-4">
                    <span>{{selectedItem.isReferentieBetaling}}</span>
                </div>                    
            </div>            
            <div style="margin-top: 15px; margin-left: 5px;" class="row">
                <button type="button" class="btn btn-success" (click)="removeItem()">Ja</button>
                <button type="button" class="btn btn-danger" (click)="hideChildModal()">Nee</button>                
            </div>
                      
      </div>
    </div>
  </div>
</div>