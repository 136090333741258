export class Prijs {
    constructor(
        public prPrijsID:number,
        public prEventID:number,
        public prNaam: string,
        public prOmschrijving: string,
        public prPrijs:number,
        public prMaxDeelnemers:number,
        public prHeader: string,
        public prNaam_UnderScore: string,
        public prVolgorde: number
    ){}

}