import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Inschrijving } from '../entities/inschrijving.model';
import { BoundElementPropertyAst } from '@angular/compiler';

import { GeneralService } from './general.service';

const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'my-auth-token'
    })
  };

@Injectable()
export class InschrijvingService{
    apiName = "inschrijving";
   
    apiUrl = 'https://api.spermalie.info/'+this.apiName+'/read.php';
    oneRecordUrl = 'https://api.spermalie.info/'+this.apiName+'/read_one.php?ID=';
    filteredUrl = 'https://api.spermalie.info/'+this.apiName+'/read_one_event.php?eventID=';

    constructor(private http: HttpClient){}

    generalService = new GeneralService(this.http);

    getAPI_Values (): Observable<Inschrijving[]> {
        return this.http.get<Inschrijving[]>(this.apiUrl);
    }

    getOneInschrijving (ID:number): Observable<Inschrijving> {
        var url = this.oneRecordUrl + ID;
        console.log(url);
        return this.http.get<Inschrijving>(url);
    }

    getFilteredData (eventID:number): Observable<Inschrijving[]> {
        var url = this.filteredUrl + eventID;
        console.log(url);
        return this.http.get<Inschrijving[]>(url);
    }

  //////// CRUD //////////

  /** POST: add a new lijstje to the database */
  addRecord (instance: Inschrijving): Observable<Inschrijving> {
    return this.http.post<Inschrijving>('https://api.spermalie.info/'+this.apiName+'/create.php', JSON.stringify(instance))
  }

  deleteRecord (id: number): Observable<{}> {
    let body = JSON.stringify({ID: id});
    this.generalService.giveLog(body);
    return this.http.post<Inschrijving>('https://api.spermalie.info/'+this.apiName+'/delete.php', body)
  }

  updateRecord (instance: Inschrijving): Observable<Inschrijving> {
    return this.http.post<Inschrijving>('https://api.spermalie.info/'+this.apiName+'/update.php', JSON.stringify(instance))
  }
}