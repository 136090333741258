import { Component, ViewChild, OnInit, NgModule  } from '@angular/core';
import { GeneralService } from '../../services/general.service';
// import 'rxjs/add/operator/map';
// import 'rxjs/Rx';
import { BrowserModule  } from '@angular/platform-browser';

import { AppComponent } from '../../app.component';

import { OrderPipe } from 'ngx-order-pipe';
import {EvenementService} from '../../services/evenement.service';
import {Evenement} from '../../entities/evenement.model';

import {Prijs} from '../../entities/prijs.model';
import { Router } from '@angular/router';

import { TabsetComponent } from 'ngx-bootstrap/tabs';
// import { forEach } from '@angular/router/src/utils/collection';
// import { forEach } from '@angular/router/src/utils/collection';
// import { forEach } from '@angular/router/src/utils/collection';

/* @NgModule({
  imports: [BrowserModule],
  declarations: [AppComponent],
  bootstrap: [AppComponent]
}) */

@Component({
  selector: 'app-evenement',
  templateUrl: './evenement.component.html',
  providers: [ EvenementService, GeneralService ],
  styleUrls: ['./evenement.component.css']
})

export class EvenementComponent implements OnInit {
  evenement: Evenement;
  title = "Evenementen";
  //error: any;
  evenementen: Evenement[];
  errorMessage: string="";
  selectedItem: Evenement;
  selectedSomething: boolean = false;
  newItem: Evenement;

  //### specifieke variabelen: aan te passen
  tableName = "tbl_evenement";
  primKeyField = 'evEventID';
  primTableArray: Evenement[];
  primTableArraySorted: Evenement[];
  searchField:string = "evActief";
  searchString:string = "J";
  order: string = 'evNaam';
  radioDetailFix : boolean = false;
  sourceEventID:number;
  wordSalad: string;
  public radioDetail: string = 'Algemeen';
  editCheck: string;

  //### component-specifieke variabelen: niet aan te passen
  eventActief: boolean;
  eventLogin: boolean;
  arrTabs = new Array();

//### algemene variabelen: niet aan te passen
  reverse: boolean = false;
  showGeneral: boolean = false;
  submitted = false;
  newKey: number;
  updateKeyID:number;
  removeKeyID:number;
  itemAdded:boolean;
  subscription;
  textAreaClass: string [];
  initialFieldValues;
  filterAktief: any = {evAktief: 'J'};

  //### variabelen gebruikt bij datepickers

  //private selDate: Date;
  private dateSel: Date[];

  //private minDate: Date;
  private dateMin: Date[]; 
  private dateMax: Date[];  

  //private editingDate: boolean = false; 
  private dateEdited: boolean[];

  //private changedDate: boolean = false;  
  private dateChanged: boolean[];

  //private canDeleteDate: boolean = false;  
  private dateCanDelete: boolean[];

  //private dateDisabled: {date: Date, mode: string}[];  
  private dateBlocked: {date: Date, mode: string}[];  

//### variabelen gebruikt bij tijdsselectie
  private selTime: string[];
  private editingTime: boolean[];  
  private changedTime: boolean[];  
  private canDeleteTime: boolean[];
  private eventAanvangsUur: number;
  private eventAanvangsMinuut: number; 
  private eventActiefVanafUur:number;
  private eventActiefVanafMinuut:number; 

  constructor(private evenementService : EvenementService, 
              private generalService : GeneralService,
              private orderPipe: OrderPipe) {

              }

  ngOnInit() {
    //this.MaakVoorbeeldEvent();
    this.dataTonen();
    this.initialFieldValues = new Array();
    //this.selectedItem = new Evenement(0,'','','','','',0,0,'','','','','',0,0,0,'','','','','','','','',0,'','','','','','',0,'',false,0,'','',false,false,'');
  
    //Standaard de actieve events weergeven
    this.chooseFilterField("evActief");
  
    //### arrays gebruikt bij datepicker
    //### 0: datum event; 1: einddatum inschrijvingen, 2: actief vanaf
    this.dateSel = new Array(new Date(),new Date(), new Date());
    this.dateMin = new Array(new Date(),new Date(),new Date());
    this.dateMax = new Array(new Date(), undefined, undefined);
    this.dateEdited = new Array(false, false, false);
    this.dateChanged = new Array(false, false, false);
    this.dateCanDelete = new Array(false, false, false);
    this.dateBlocked = new Array(undefined, undefined, undefined);
  
    this.editingTime  = new Array(false, false, false);
    this.changedTime  = new Array(false, false, false);
    this.canDeleteTime  = new Array(false, false, false);

  }

  setOrder(value: string) {
    this.generalService.giveLog(value);
    if (this.order === value) {
      this.reverse = !this.reverse;
    }
    else {
      this.reverse = false;
    }
    this.order = value;
    this.primTableArraySorted = this.orderPipe.transform(this.primTableArray, this.order, this.reverse);
  }    

  dataTonen(): void {
    this.evenementService.getAPI_Values()
      .subscribe(
        array => this.primTableArray = array,
        err=> 
        {
          this.errorMessage = "Namen van lijsten niet opgehaald"
        },
     () => 
     {
       this.errorMessage = "";
       this.generalService.giveLog(this.primTableArray[0].evNaam);
       this.primTableArraySorted = this.orderPipe.transform(this.primTableArray, 'evNaam');
       this.generalService.giveLog(this.primTableArraySorted[0].evNaam);
     }
     );
     this.generalService.giveLog("Error message = " + this.errorMessage);
     //maak code om events aan te passen na afsluiten van inschrijving
     this.editCheck = this.generalService.createEditCheck();
 }

 onSelect(item: Evenement): void {
   this.generalService.giveLog('Geselecteerd: ' + item.evNaam);
  this.selectedItem = item;
  this.showGeneral = true;
  this.selEvDatum();
  this.selEvEindDatumInschrijving();
  this.selEvActiefVanaf();

  this.eventAanvangsUur = parseInt(this.selectedItem.evAanvangsTijd.substring(0,2));
  this.eventAanvangsMinuut = parseInt(this.selectedItem.evAanvangsTijd.substr(3,2));

  this.eventActiefVanafUur = parseInt(this.selectedItem.evActiefVanaf.substr(11,2));
  this.eventActiefVanafMinuut = parseInt(this.selectedItem.evActiefVanaf.substr(14,2));

  // this.arrTabs = this.selectedItem.evTabs.split(',');
  // this.generalService.giveLog('arrTabs = ' + this.arrTabs);


  // this.verwerkActief();
  // this.verwerkLogin();

  this.textAreaClass = new Array('', '');
  this.textAreaClass[0] = this.generalService.defineTextAreaClass(this.selectedItem.evOmschrijving);
 }

//###Functies die NIET aangepast moeten worden aan de specifieke pagina
showList() {
  // if (this.itemAdded) {
  //   this.deleteEventTBD();  
  //   this.dataTonen();
  // }
  this.showGeneral = false;
}

initialValue(fieldName:string, fieldValue: string) {
  this.initialFieldValues[fieldName]=fieldValue;
  this.generalService.giveLog('initialValues[' + fieldName + ']: ' + this.initialFieldValues[fieldName]);
}

//updateRecord
updateRecord(field?, newValue?, id?)
{
  var observable = this.evenementService.updateItem(this.selectedItem);
  observable.subscribe((response) => {
    this.generalService.giveLog(JSON.stringify(response));
    var callResponse = JSON.stringify(response);
  });
}

saveField(field:string, newValue:any, id, isNumeric:boolean = false) {
  if(this.initialFieldValues[field] != newValue) {
    var quote = String.fromCharCode(96);
    this.generalService.giveLog(newValue);
    if(!isNumeric)
    {
      var adaptedValue = newValue.replace(/["']/g,quote);      
    }

    if(newValue != adaptedValue)
    {
      switch (field) {
        case 'evNaam': 
          this.selectedItem.evNaam = adaptedValue;
          //this.generalService.giveLog("evNaam = " + adaptedValue);
          break;
        case 'evOmschrijving': 
          this.selectedItem.evOmschrijving = adaptedValue;
          //this.generalService.giveLog("evOmschrijving = " + adaptedValue);
          break;    
        case 'evMailBody': 
          this.selectedItem.evMailBody = adaptedValue;
          //this.generalService.giveLog("evMailBody = " + adaptedValue);
          break;   
        case 'evMailSubject': 
          this.selectedItem.evMailSubject = adaptedValue;
          //this.generalService.giveLog("evMailSubject = " + adaptedValue);
          break;              
        default:
          break;
      }
      newValue = adaptedValue;
    }
    this.updateRecord(field, newValue, id); 
  }
  else {
    this.generalService.giveLog("geen wijziging " + field + " - " + newValue);
  }
}

//updateRecord: time field
saveTimeField(field, hour, minute, id) {
  var newTime;
  if(hour == "") {
    newTime = "";
  }
  else {
    newTime =  this.generalService.addLeadingZero(hour, 2) + ':' + this.generalService.addLeadingZero(minute, 2);
  }
  //this.generalService.giveLog('newTime: ' + newTime);
  this.selectedItem.evAanvangsTijd = newTime;
  this.updateRecord();
  //this.generalService.update_Item(this.tableName, id, this.newKey, field, newTime); 
}

//delete record
// removeItem(tablename:string, id:number) {
//   this.generalService.removeItem(tablename, id, this.newKey, this.primTableArray, this.primKeyField);
// }

// showGeneral ==> 
toggleShowGeneral() {
  this.selectedItem
  ?	this.showGeneral = !this.showGeneral
  : this.showGeneral = this.showGeneral;
}

toggleBoolean(boolFieldName:string, boolValue: boolean) {
    this.generalService.giveLog(boolFieldName + ": "+boolValue);
    var boolNumber: number;
    boolNumber = boolValue ? 1 : 0;
    this.updateRecord();
    //this.generalService.update_Item(this.tableName, this.selectedItem.evEventID, this.newKey, boolFieldName, boolNumber);
}


//###Functies specifiek voor de datepicker: verwijderen indien geen datepicker gebruikt

  //### generieke functies: niet aanpassen
  readDate(index:number): string {
    this.dateChanged[index] = true;
    this.dateCanDelete[index] = true;
    return this.generalService.dateAmyStyle(this.dateSel[index]);    
  }

  selToday(index:number): void {
    this.dateSel[index] = new Date();
    let strDateTime = this.generalService.dateTimeAmyStyle(this.dateSel[index]);
    this.generalService.giveLog('strDateTime = ' + strDateTime);
  }

  disabled(date: Date, mode: string): boolean {
    return ( mode === 'day' && ( date.getDay() === 0 || date.getDay() === 6 ) );
  }

  dateClearAndSave(index:number) {
    this.dateClear(index);
    this.dateSave(index);
  }

  dateSave(index:number) {

    if (this.dateSel[index] == void 0) {
        this.saveDateNull(index);
    }
    else 
    {
      this.saveDateNotNull(index);
    }
    this.dateEdited[index]=false;
     if(index <2){ this.selEvActiefVanaf();} 
  }

//###Functies specifiek voor de pagina: verwijderen bij kopie naar nieuwe component
    // klikken op checkbox actief afvangen
    toggleActief() {
      this.eventActief = !this.eventActief;
      this.eventActief
        ? this.selectedItem.evActief = 'J'
        : this.selectedItem.evActief = 'N';
        this.verwerkActief();
        this.updateRecord();
        //this.generalService.update_Item(this.tableName, this.selectedItem.evEventID, this.newKey, 'evActief', this.selectedItem.evActief);
    }

    toggleSelectedActief(item: Evenement) {
      this.selectedItem = item;
      this.generalService.giveLog("start met  " + item.evEventID);
      if (item.evActief == 'J')
      {
        item.evActief = 'N'
      }
      else
      {
        item.evActief = 'J';
      }
      this.generalService.giveLog(item.evActief + " " + item.evEventID);
      this.updateRecord();

      //this.generalService.update_Item(this.tableName, item.evEventID, this.newKey, 'evActief', item.evActief);
    }  

  verwerkActief() {
    if (this.selectedItem.evActief == 'J')
    {
        //this.actiefMsg = "actief";
        this.eventActief = true;
    }
    else
    {
        //this.actiefMsg = "niet actief";
        this.eventActief = false;
    }
  }

  toggleLogin() {
    this.eventLogin = !this.eventLogin;
    this.eventLogin
      ? this.selectedItem.evLogin = 'J'
      : this.selectedItem.evLogin = 'N';
      this.verwerkLogin();
      this.updateRecord();
      //this.generalService.update_Item(this.tableName, this.selectedItem.evEventID, this.newKey, 'evLogin', this.selectedItem.evLogin);
  }

  verwerkLogin() {
    if (this.selectedItem.evLogin == 'J')
    {
        this.eventLogin = true;
        this.generalService.giveLog('Login vereist');
    }
    else
    {
        this.eventLogin = false;
        this.generalService.giveLog('Login niet vereist');
    }
  }

  toggleTab(index: number, tabName: string) {
    if(index==2) {
      this.arrTabs[2] = this.arrTabs[index] == "" ? tabName : "";
      this.arrTabs[3] = this.arrTabs[index] == "" ? tabName : "";
      this.generalService.giveLog(tabName + ': ' + this.arrTabs[index]);
      this.selectedItem.evTabs = this.arrTabs.join();
      //this.generalService.update_Item(this.tableName, this.selectedItem.evEventID, this.newKey, 'evTabs', this.selectedItem.evTabs);
    }
    else {
      this.arrTabs[index] = this.arrTabs[index] == "" ? tabName : "";
      this.generalService.giveLog(tabName + ': ' + this.arrTabs[index]);
      this.selectedItem.evTabs = this.arrTabs.join();
      //this.generalService.update_Item(this.tableName, this.selectedItem.evEventID, this.newKey, 'evTabs', this.selectedItem.evTabs);
    }
    this.updateRecord();
  }

  dateEdit(index:number) {
    this.dateEdited[index] = this.dateEdited[index] ? false : true;
        this.generalService.giveLog('dateEdited[' + index + ']= ' + this.dateEdited[index]);
      
  }

  public dateClear(index: number): void {
    this.dateSel[index] = void 0;
    this.dateCanDelete[index] = true;
    this.dateBlocked[index] = undefined;
  }   

  actiefVanafChanged(hour:string, minute:string) {
    this.eventActiefVanafUur = parseInt(hour);
    this.eventActiefVanafMinuut = parseInt(minute);
    this.generalService.giveLog('Actief vanaf: ' + this.selectedItem.evActiefVanaf + ' ' + this.eventActiefVanafUur +":"+ this.eventActiefVanafMinuut);
    this.editingTime[2] = true;
  }

//###Functies specifiek voor textarea
    //Update specifiek textarea
    saveTextArea (field, newValue, id,) {
      switch(field) {
        case 'evOmschrijving':
          this.selectedItem.evOmschrijving = newValue;
          break;
        case 'evSuccesBoodschap':
          this.selectedItem.evSuccesBoodschap = newValue;
        default:
          break;
      }
      if(this.initialFieldValues[field] != newValue) {
        var aantal: number = 0;
        var adaptedValue = this.generalService.replaceAll(newValue, "\n", "\\n");
        this.updateRecord();
        //this.generalService.update_Item(this.tableName, id, 0, field, adaptedValue);        
      }
      else {
        this.generalService.giveLog("geen wijziging " + field + " - " + newValue);
      }     
    }

    textAreaDefineClass(index:number, value:string) {
      this.textAreaClass[index] = value == '' ? 'textAreaNotValid': 'textAreaValid';
      this.generalService.giveLog("Value " + value + " ==> " + this.textAreaClass[index]);
    }

//### per datumveld: functie wat er moet gebeuren als er een record geselecteerd wordt
  private selEvDatum() {
      if (this.selectedItem.evDatum != "" && this.selectedItem.evDatum != null )
      {
        this.dateSel[0] = new Date(this.selectedItem.evDatum);
        this.dateCanDelete[0] = true;
      }
      else
      {
        this.dateSel[0] = new Date();
        this.dateCanDelete[0] = false;
      }    
  }

  private selEvEindDatumInschrijving() {
      if (this.selectedItem.evEindDatumInschrijving != "" && this.selectedItem.evEindDatumInschrijving != null )
      {
        this.dateSel[1] = new Date(this.selectedItem.evEindDatumInschrijving);
        if (this.selectedItem.evDatum != "") {
          this.dateMax[1] = new Date(this.selectedItem.evDatum);          
        }

        this.dateCanDelete[1] = true;
      }
      else
      {
        this.dateSel[1] = new Date();
        this.dateCanDelete[1] = false;
      }
  }

  private selEvActiefVanaf() {
      if (this.selectedItem.evActiefVanaf != "" && this.selectedItem.evActiefVanaf != null )
      {
        this.dateSel[2] = new Date(this.selectedItem.evActiefVanaf);
        this.generalService.giveLog(this.dateSel[2]);
        //de inschrijvingen moeten altijd actief worden voordat het event plaatsvindt

        this.dateCanDelete[2] = true;
      }
      else
      {
        this.dateSel[2] = new Date();
        this.dateCanDelete[2] = false;
      }
      if (this.selectedItem.evEindDatumInschrijving != "" && this.selectedItem.evEindDatumInschrijving != null) {
        this.dateMax[2] = new Date(this.selectedItem.evEindDatumInschrijving);
        //this.generalService.giveLog("amy1: "+this.generalService.dateAmyStyle(this.dateMax[2]));    
      }
      else if (this.selectedItem.evDatum != "" && this.selectedItem.evDatum != null) {
        this.dateMax[2] = new Date(this.selectedItem.evDatum);
        //this.generalService.giveLog("amy2: "+this.generalService.dateAmyStyle(this.dateMax[2]));             
      }
      else {
        this.dateMax[2]=undefined;     
        //this.generalService.giveLog("amy3: "+this.generalService.dateAmyStyle(this.dateMax[2]));      
      }
      this.generalService.giveLog("amy1: "+ this.dateMax[2]);  
  }    

  saveDateNull(arrayIndex:number) {
          switch(arrayIndex) {
        case 0:  //evDatum
          this.selectedItem.evDatum = null;   
          //this.generalService.update_Item(this.tableName, this.selectedItem.evEventID, this.newKey, 'evDatum', "null");  
          this.dateMax[1]=undefined;
          break;
        case 1:  // evEindDatumInschrijving
          this.selectedItem.evEindDatumInschrijving = null;   
          //this.generalService.update_Item(this.tableName, this.selectedItem.evEventID, this.newKey, 'evEindDatumInschrijving', "null");    
          break;
        case 2:  // evActiefVanaf
          this.selectedItem.evActiefVanaf = null;   
          //this.generalService.update_Item(this.tableName, this.selectedItem.evEventID, this.newKey, 'evActiefVanaf', "null");    
          break;          
        default:
          arrayIndex = 100;             
      }
      this.updateRecord();
  }

  saveDateNotNull(arrayIndex:number) {
        switch(arrayIndex) {
        case 0:   //evDatum
          this.selectedItem.evDatum = this.generalService.dateAmyStyle(this.dateSel[arrayIndex]); 
          this.generalService.giveLog("amy: "+this.generalService.dateAmyStyle(this.dateSel[arrayIndex]));
          //this.generalService.update_Item(this.tableName, this.selectedItem.evEventID, this.newKey, 'evDatum', this.selectedItem.evDatum); 
          this.dateMax[1]=new Date(this.selectedItem.evDatum);
          break;
        case 1:   // evEindDatumInschrijving
          this.selectedItem.evEindDatumInschrijving = this.generalService.dateAmyStyle(this.dateSel[arrayIndex]);   
          //this.generalService.update_Item(this.tableName, this.selectedItem.evEventID, this.newKey, 'evEindDatumInschrijving', this.selectedItem.evEindDatumInschrijving); 
          break;
        case 2:   // evActiefVanaf
          this.editingTime[2] = false;
          var actHour = this.generalService.addLeadingZero(this.eventActiefVanafUur,2);
          var actMinute = this.generalService.addLeadingZero(this.eventActiefVanafMinuut,2);
          this.selectedItem.evActiefVanaf = this.generalService.dateAmyStyle(this.dateSel[arrayIndex]) + ' ' + actHour + ':' + actMinute + ':00';   
          this.generalService.giveLog(this.selectedItem.evActiefVanaf);
          //this.generalService.update_Item(this.tableName, this.selectedItem.evEventID, this.newKey, 'evActiefVanaf', this.selectedItem.evActiefVanaf); 
          break;          
        default:
          arrayIndex = 100;             
      }     
      if (arrayIndex < 100) {
        this.updateRecord();
      }
  }

//### Specifiek voor time editor
  editTime(index:number) {
    this.editingTime[index] = this.editingTime[index] ? false : true;
  }

  clearAndSaveTime(index:number){
    this.clearTime(index);
    this.saveTime(index);
  }

  clearTime(index:number): void {
    this.selTime[index] = void 0;
    this.canDeleteTime[index] = true;
  }

  saveTime(index:number) {
    this.generalService.giveLog('new time = ' + this.selTime[index]);
    if (this.selTime[index] == void 0) {
      this.selectedItem.evAanvangsTijd = "";   
      //this.generalService.update_Item(this.tableName, this.selectedItem.evEventID, this.newKey, 'evAanvangsTijd', "");
    }
    else 
    {
      //this.generalService.update_Item(this.tableName, this.selectedItem.evEventID, this.newKey, 'evAanvangsTijd', this.selectedItem.evAanvangsTijd);
    }
    this.updateRecord();
    this.dateEdited[0] = false;
  }  

  MaakVoorbeeldEvent()
  {
    this.evenement = {
      evEventID : 0,
      evNaam: 'test',
      evDatum: '2018/05/21',
      evAanvangsTijd: '20:20',
      evEindDatumInschrijving: '1993/05/21',
      evOmschrijving: 'string',
      evMaxDeelnemers: 10,
      evMaxInschrijvingen: 2,
      evAfkortingNaam: 'string',
      evWachtwoord: 'string',
      evSlotformule: 'string',
      evActief: 'N',
      evTitelPrijzen: 'string',
      evHerinneringstermijn: null,
      evBetalingstermijn: null,
      evAnnulatieTermijn: null,
      evMailBody: 'string',
      evMailFrom: 'string',
      evMailSubject: 'string',
      evMailAttachment: 'string',
      evHerinneringBody1: 'string',
      evHerinneringSubject: 'string',
      evAnnuleringBody1: 'string',
      evAnnuleringSubject: 'string',
      evRptBevestigingID: 1,
      evWachtlijst: 'string',
      evAanhefRapport: 'string',
      evTarieven: 'string',
      evActiefVanaf: '2018/07/01',
      evRekeningNummer: 'string',
      evMailBcc: 'string',
      evLogoID: 0,
      evMailVerantwoordelijke: 'string',
      evCheckMaximum: true,
      evCheckNoMaxima: 1,
      evSuccesBoodschap: 'string',
      evLogin: 'string',
      evBetalend: false,
      evBevestiging: false,
      evTabs: 'string',
      evCreated: '2018/06/24',
      evPrijsVerplicht: false,
      evAangemaakt: '2018/06/24',
      evAangepast: '2018/06/24',
      evReadonlyDN: true
    };
  }
  chooseFilterField(value){ 
    this.searchField = value;
    this.generalService.giveLog("searchfield = " + value);
    this.searchString = ""
    if (value == "evActief"){
      this.searchString = "J";
    }
  }
  
  radioDetailFixToggle() {
    this.radioDetailFix = this.radioDetailFix ? false : true;
    this.generalService.giveLog('radioDetailFix: ' + this.radioDetailFix)
  }
}
