<div class="alert alert-danger" role="alert" [hidden]="errorMessage === ''" [innerHTML]="errorMessage">

</div>
<div class="row">
    <div class="col-sm-1 pageTitle"><span style="margin-left:15px;" (click)="createNew()" class="fas fa-plus text-success pointy" aria-hidden="true"></span></div> 
    <div style="font-size: 20px;margin-left:20px;position:absolute;right:-30px;z-index:100" class="col-sm-1 fontGreen"><span (click)="radioDetailFixToggle()">
        <i class="fas fa-thumbtack text-success pointy" [class.rotate-35]="radioDetailFix" aria-hidden="true"></i></span>
    </div> 
</div>
<div class="scroll" *ngIf="!editItem || radioDetailFix">
  <div class="row" style="border-bottom:2px solid rgb(221, 221, 221);margin-left:0px" >
          <div style="font-weight: bold" class="col-sm-1">Sort</div>    
          <div style="font-weight: bold" class="col-sm-2">Omschrijving</div> 
          <div style="font-weight: bold" class="col-sm-2">Soort</div>  
          <div style="font-weight: bold" class="col-sm-1">Verplicht</div>
          <div style="font-weight: bold" class="col-sm-1">Max. dln.</div>
          <div style="font-weight: bold" class="col-sm-3">Titel</div>            
          <div style="font-weight: bold" class="col-sm-1">Bewerk</div>
  </div>
  <div class="row" style="margin-left:0px">
    <div class="col-sm-12" (dragend)="updateSortOrder()">
        <bs-sortable
            [(ngModel)]="primTableArray"
            [itemTemplate]="itemTemplate"
            itemClass="sortable-item"
            itemActiveClass="sortable-item-active"
            placeholderItem="De gegevens worden geladen"
            placeholderClass="placeholderStyle"
            wrapperClass="sortable-wrapper"
            >
        </bs-sortable>
    </div>
  </div>
</div>
 

<div *ngIf="editItem">
    <form [formGroup]="itemForm">
        
        <div class="row">
            <div class="col-sm-12">
                <label class="klein">Omschrijving</label>
                <input class="form-control" formControlName="cOmschrijving">
            </div>
        </div>
        <div [hidden]="itemForm.get('cOmschrijving').status==='VALID' || itemForm.get('cOmschrijving').pristine===true" class="alert alert-danger">Vul een omschrijving in</div>
        <div class="row">
            <div class="col-sm-3">
                <label class="klein">Soort</label>
                <select class="form-control" formControlName="cSoort" #keuzeSoort>
                    <option *ngFor="let soort of veldTypeArray" [value]="soort.veldTypeID">{{soort.veldType}}</option>
                </select>                
            </div>
            <div [hidden]="itemForm.get('cSoort').status==='VALID' || itemForm.get('cSoort').pristine===true" class="alert alert-danger">Kies het soort veld</div>
            <div class="col-sm-2">
                <label class="klein">Verplicht</label>
                <select class="form-control" formControlName="cVerplicht">
                    <option *ngFor="let jaOfNee of jaNee" [value]="jaOfNee">{{jaOfNee}}</option>
                </select>
            </div>
            <div [hidden]="itemForm.get('cVerplicht').status==='VALID' || itemForm.get('cVerplicht').pristine===true" class="alert alert-danger">Kies of het veld al dan niet verplicht is</div>
            <div class="col-sm-3">
                <label class="klein">Lijst</label>
                <select class="form-control" formControlName="cLijst" #lijst>
                    <option *ngFor="let lijst of formValuesArray" [value]="lijst.ID">{{lijst.waardeName}}</option>
                </select>                
            </div>                
        </div>
    
        <div class="row" [hidden]="itemForm.status!=='VALID'">
            <div class="col-sm-3">
                <label class="klein">Bestemming</label>
                <select class="form-control" formControlName="cBestemming">
                    <option *ngFor="let bestemming of bestemmingArray" [value]="bestemming.bestemmingID">{{bestemming.omschrijving}}</option>
                </select> 
            </div>               
            <div class="col-sm-2"></div>           
            <div class="col-sm-3">
                <label class="klein">Max. aantal deelnemers</label>
                <input type="number" class="form-control" formControlName="cMaxDeelnemers">
            </div>
        </div>  
        <div class="row" [hidden]="itemForm.status!=='VALID'">
            <div class="col-sm-12">
                <label class="klein">Titel</label>
                <input class="form-control" formControlName="cTitel">
            </div>
        </div>  
        <div class="row">
            <div class="col-sm-12">
                <label class="klein">Mapping database</label>
                <input class="form-control" formControlName="cVergelijkMet" readonly>
            </div>
        </div>                       
        <BR> 
        <button [disabled]="itemForm.status!=='VALID'" type="button" class="btn btn-success" 
        (click)="saveRecord(itemForm.get('cOmschrijving').value, itemForm.get('cSoort').value, 
        itemForm.get('cLijst').value, itemForm.get('cVerplicht').value, 
        itemForm.get('cBestemming').value, itemForm.get('cMaxDeelnemers').value, 
        itemForm.get('cTitel').value, itemForm.get('cVergelijkMet').value)">Opslaan</button>  
        <button type="button" class="btn btn-danger" (click)="createForm()">Annuleren</button>  
        
    </form>
</div>

<BR> 

<ng-template  #itemTemplate let-item="item" let-index="index">
        <div class="row" style="border-bottom:1px solid rgb(221, 221, 221);padding:5px" >
                <div class="col-sm-1">{{item.value.volgorde}}</div>
                <div class="col-sm-2">{{item.value.omschrijving}}</div>
                <div class="col-sm-2">{{item.value.soort}}</div>                 
                <div class="col-sm-1">{{item.value.verplicht}}</div>                    
                <div class="col-sm-1">{{item.value.maxDeelnemers}}</div>
                <div class="col-sm-3">{{item.value.titel}}</div>
       
                <div class="col-sm-1 text-center">
                    <span (click)="populateForm(item.value.omschrijving,item.value.soort, item.value.waarde, 
                    item.value.verplicht, item.value.bestemming, item.value.maxDeelnemers, item.value.titel, 
                    item.value.vergelijkMet, item.value)" 
                        class="fas fa-pencil-alt text-success pointy" aria-hidden="true"></span>
                    <span>&nbsp;&nbsp;</span>                    
                    <span (click)="deleteRecord(item.value.ID)" class="fas fa-times text-warning pointy" aria-hidden="true"></span>
                </div>
        </div>
</ng-template>