import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Form_Bestemming } from '../entities/form_bestemming.model';

const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'my-auth-token'
    })
  };

@Injectable()
export class Form_BestemmingService{
    className:string = 'form_bestemming';

    mainDataUrl = 'https://api.spermalie.info/' + this.className + '/read.php';

    constructor(
        private http: HttpClient){
    }

    getmainData (): Observable<Form_Bestemming[]> {
        console.log(this.mainDataUrl);
        return this.http.get<Form_Bestemming[]>(this.mainDataUrl
        );
    }
}