import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Evenement } from '../entities/evenement.model';
import { Form } from '../entities/form.model';
import { GeneralService } from './general.service';

const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'my-auth-token'
    })
  };


@Injectable()
//##### AANPASSEN #############
export class Evenement_VragenService {

    apiName = "form";
   
    apiUrl = 'https://api.spermalie.info/'+this.apiName+'/read.php';
    filteredUrl = 'https://api.spermalie.info/'+this.apiName+'/read_one_event.php?eventID=';

    //public evenement_List: Evenement[];

    constructor(private http: HttpClient){}

    generalService = new GeneralService(this.http);

    getAPI_Values (): Observable<Form[]> {
        return this.http.get<Form[]>(this.apiUrl);
    }

    getFilteredData (eventID:number): Observable<Form[]> {
        var url = this.filteredUrl + eventID;
        console.log(url);
        return this.http.get<Form[]>(url);
    }

    //////// CRUD //////////

    /** POST: add a new lijstje to the database */
    addAPI_values (instance: Form): Observable<Form> {
      return this.http.post<Form>('https://api.spermalie.info/'+this.apiName+'/create.php', JSON.stringify(instance))
    }

    deleteAPI_values (id: number): Observable<{}> {
      let body = JSON.stringify({ID: id});
      return this.http.post<Evenement>('https://api.spermalie.info/'+this.apiName+'/delete.php', body)
    }

    updateItem (instance: Form): Observable<Form> {
      //this.generalService.giveLog(JSON.stringify(evenement));
      let observable = this.http.post<Form>('https://api.spermalie.info/'+this.apiName+'/update.php', JSON.stringify(instance));
      return observable;
    }
}