export class Form {
    constructor(
        public ID:number,
        public eventID:number,
        public volgorde:number,
        public omschrijving:string,
        public soort:string,
        public waarde:number,
        public verplicht:string,
        public bestemming:number,
        public maxDeelnemers:number,
        public titel:string,
        public omschrijvingUnderscore:string,
        public vergelijkMet:string        
    ){}
}