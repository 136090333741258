<div [class.scroll]="scroll" >
	<div class="row">  
		<div class="col-sm-3 col-md-2 col-lg-2"> <!-- Betalingstermijn -->
			<label class="klein">Betalingstermijn</label>
			<input [(ngModel)]="item.evBetalingstermijn" #evBetalingstermijn="ngModel"
			type="number" placeholder="Betalingstermijn" class="form-control" required
			(mouseover)="initialValue('evBetalingstermijn',evBetalingstermijn.value);"
			(focus)="initialValue('evBetalingstermijn',evBetalingstermijn.value);"
			(blur)="saveField('evBetalingstermijn',evBetalingstermijn.value, item.evEventID);" 
			(click)="saveField('evBetalingstermijn',evBetalingstermijn.value, item.evEventID);">
			<div [hidden]="evBetalingstermijn.value !== '' || evBetalingstermijn.pristine" class="alert alert-danger">Geef aan binnen de hoeveel dagen er betaald dient te worden.</div>
		</div>
		<div class="col-sm-6 col-md-6 col-lg-4"> <!-- Rekeningnummer -->
			<label class="klein">Rekeningnummer</label>
			<input [(ngModel)]="item.evRekeningNummer" #evRekeningNummer="ngModel"
			type="text" placeholder="Rekeningnummer" class="form-control" 
			(focus)="initialValue('evRekeningNummer',evRekeningNummer.value);"
			(blur)="saveField('evRekeningNummer',evRekeningNummer.value, item.evEventID);" >
		</div>
	</div>	
	<div class="row">
		<div class="well col-sm-11">
			<div class="row">  <!-- Herinneringstermijn -->
				<div class="col-sm-5 col-md-4 col-lg-4">
					<label class="klein">Herinneringstermijn</label>
					<input [(ngModel)]="item.evHerinneringstermijn" #evHerinneringstermijn="ngModel"
					type="number" placeholder="Herinneringstermijn" class="form-control" 
					(mouseover)="initialValue('evHerinneringstermijn',evHerinneringstermijn.value);"
					(focus)="initialValue('evHerinneringstermijn',evHerinneringstermijn.value);"
					(blur)="saveField('evHerinneringstermijn',evHerinneringstermijn.value, item.evEventID);" 
					(click)="saveField('evHerinneringstermijn',evHerinneringstermijn.value, item.evEventID);">
				</div>
			<!--</div>
			<div class="row">  Onderwerp -->
				<div class="col-sm-7 col-md-8 col-lg-8">
					<label class="klein">Onderwerp</label>
					<input [(ngModel)]="item.evHerinneringSubject" #evHerinneringSubject="ngModel"
					type="text" placeholder="Onderwerp" class="form-control" 
					(focus)="initialValue('evHerinneringSubject',evHerinneringSubject.value);"
					(blur)="saveField('evHerinneringSubject',evHerinneringSubject.value, item.evEventID);" >
				</div>
			</div>
			<div class="row">  <!-- Bericht -->
				<div class="col-sm-12 col-md-12 col-lg-12">
					<label class="klein">Bericht</label>
					<textarea #evHerinneringBody1 rows="3" placeholder="Bericht" class="form-control"
					(focus)="initialValue('evHerinneringBody1',evHerinneringBody1.value);"
					(blur)="saveTextArea('evHerinneringBody1',evHerinneringBody1.value, item.evEventID);" >{{item.evHerinneringBody1 | nl2br:2}}</textarea>
				</div>
			</div>		
		</div>
	</div>
	<div class="row">
		<div class="well col-sm-11">
			<div class="row">  <!-- Annulatietermijn -->
				<div class="col-sm-5 col-md-4 col-lg-4">
					<label class="klein">Annulatietermijn</label>
					<input [(ngModel)]="item.evAnnulatieTermijn" #evAnnulatieTermijn="ngModel"
					type="number" placeholder="Annulatietermijn" class="form-control" 
					(mouseover)="initialValue('evAnnulatieTermijn',evAnnulatieTermijn.value);"
					(focus)="initialValue('evAnnulatieTermijn',evAnnulatieTermijn.value);"
					(blur)="saveField('evAnnulatieTermijn',evAnnulatieTermijn.value, item.evEventID);" 
					(click)="saveField('evAnnulatieTermijn',evAnnulatieTermijn.value, item.evEventID);">
				</div>
				<div class="col-sm-7 col-md-8 col-lg-8"><!-- Onderwerp -->
					<label class="klein">Onderwerp</label>
					<input [(ngModel)]="item.evAnnuleringSubject" #evAnnuleringSubject="ngModel"
					type="text" placeholder="Onderwerp" class="form-control" 
					(focus)="initialValue('evAnnuleringSubject',evAnnuleringSubject.value);"
					(blur)="saveField('evAnnuleringSubject',evAnnuleringSubject.value, item.evEventID);">
				</div>
			</div>
			<div class="row">  <!-- Bericht -->
				<div class="col-sm-12 col-md-12 col-lg-12">
					<label class="klein">Bericht</label>
					<textarea #evAnnuleringBody1 rows="3" placeholder="Bericht" class="form-control"
					(focus)="initialValue('evAnnuleringBody1',evAnnuleringBody1.value);"
					(blur)="saveTextArea('evAnnuleringBody1',evAnnuleringBody1.value, item.evEventID);" >{{item.evAnnuleringBody1 | nl2br:2}}</textarea>
				</div>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-6 col-md-6 col-lg-6">  <!-- Tarieven -->
			<label class="klein">Tarieven</label>
			<input [(ngModel)]="item.evTarieven" #evTarieven="ngModel"
			type="text" placeholder="Tarieven" class="form-control" 
			(focus)="initialValue('evTarieven',evTarieven.value);"
			(blur)="saveField('evTarieven',evTarieven.value, item.evEventID);" >
		</div>
		<div class="col-sm-5 col-md-5 col-lg-5"> <!-- Titel Prijzen -->
			<label class="klein">Titel Prijzen</label>
			<input [(ngModel)]="item.evTitelPrijzen" #evTitelPrijzen="ngModel"
			type="text" placeholder="Titel Prijzen" class="form-control" 
			(focus)="initialValue('evTitelPrijzen',evTitelPrijzen.value);"
			(blur)="saveField('evTitelPrijzen',evTitelPrijzen.value, item.evEventID);" >
		</div>
	</div>
</div>