<div [class.scroll]="scroll">
  <div *ngIf="item">
      <div class="row">
          <div class="col-sm-5 col-md-5 col-lg-5"> <!--Afzender -->
              <label class="klein">Afzender</label>
              <input [(ngModel)]="item.evMailFrom"
              placeholder="Afzender" type="email" class="form-control" required
              #evMailFrom="ngModel"
              (focus)="initialValue('evMailFrom',evMailFrom.value);"
              (blur)="saveField('evMailFrom',evMailFrom.value, item.evEventID);">
              <div [hidden]="evMailFrom.valid || evMailFrom.pristine" class="alert alert-danger">Geef een geldig e-mailadres waarvan de communicaties vertrekken</div>
          </div>
          <div class="col-sm-5 col-md-5 col-lg-5"> <!--BCc -->
              <label class="klein">Bcc</label>
              <input [(ngModel)]="item.evMailBcc"
              placeholder="Bcc" type="email" class="form-control" 
              #evMailBcc="ngModel"
              (focus)="initialValue('evMailBcc',evMailBcc.value);"
              (blur)="saveField('evMailBcc',evMailBcc.value, item.evEventID);">
          </div>
      </div>
      <div class="row"> <!--Afzender -->
          <div class="col-sm-10 col-md-10 col-lg-10">
              <label class="klein">Onderwerp</label>
              <input [(ngModel)]="item.evMailSubject"
              placeholder="Onderwerp" type="text" class="form-control" required
              #evMailSubject="ngModel"
              (focus)="initialValue('evMailSubject',evMailSubject.value);"
              (blur)="saveField('evMailSubject',evMailSubject.value, item.evEventID);">
              <div [hidden]="evMailSubject.valid || evMailSubject.pristine" class="alert alert-danger">Geef het onderwerp op van de bevestigingsmail</div>
          </div>
      </div>
      <div class="row">
          <div class="col-sm-5 col-md-5 col-lg-5"> <!--Aanhef Rapport -->
              <label class="klein">Aanhef Rapport</label>
              <textarea #evAanhefRapport rows="3" placeholder="AanhefRapport" class="form-control {{textAreaClass[0]}}" 
              (focus)="initialValue('evAanhefRapport',evAanhefRapport.value);"
              (keyup)="textAreaDefineClass(0, evAanhefRapport.value)"
              (blur)="saveTextArea('evAanhefRapport',evAanhefRapport.value, item.evEventID);">{{item.evAanhefRapport | nl2br:2}}</textarea>
              <div [hidden]="evAanhefRapport.value !== '' || evAanhefRapport.pristine" class="alert alert-danger">Geef de tekst die boven de eventuele details komt</div>
          </div>

          <div class="col-sm-5 col-md-5 col-lg-5"> <!--Slot Rapport -->
              <label class="klein ">Slot Rapport</label>
              <textarea #evSlotformule rows="3" placeholder="Slot Rapport" class="form-control {{textAreaClass[1]}}"
              (focus)="initialValue('evSlotformule',evSlotformule.value);" 
              (keyup)="textAreaDefineClass(1, evSlotformule.value)"
              (blur)="saveTextArea('evSlotformule',evSlotformule.value, item.evEventID);">{{item.evSlotformule | nl2br:2}}</textarea>
              <div [hidden]="evSlotformule.value !== '' || evSlotformule.pristine" class="alert alert-danger">Geef de tekst die onder de eventuele details komt</div>
          </div>
      </div>

      <div class="row">
          <div class="col-sm-5 col-md-5 col-lg-5"> <!--RapportID -->
              <label class="klein">Bevestigingsrapport</label>
              <select class="form-control textAreaValid" [(ngModel)]="item.evRptBevestigingID"
                   #evRptBevestigingID="ngModel"
                  (focus)="initialValue('evRptBevestigingID',evRptBevestigingID.value);"
                  (blur)="saveField('evRptBevestigingID',evRptBevestigingID.value, item.evEventID);">
                  <option *ngFor="let rapport of rapportArray | orderBy: 'RptBevestiging'" [ngValue]="rapport.RptBevestigingID">{{ rapport.RptBevestiging }}</option>
              </select>
          <div [hidden]="evRptBevestigingID.valid || evRptBevestigingID.pristine" class="alert alert-danger">Kies een rapport</div>
          </div>

          <div class="col-sm-5 col-md-5 col-lg-5"> 
              <label class="klein">Logo op rapport</label>
              <select class="form-control textAreaValid" [(ngModel)]="item.evLogoID" (ngModelChange)="change($event)"
                   #evLogoID="ngModel"
                  (focus)="initialValue('evLogoID',evLogoID.value);"
                  (blur)="saveField('evLogoID',evLogoID.value, item.evEventID);">
                  <option *ngFor="let logo of logoArray | orderBy: 'LogoNaam'" [ngValue]="logo.LogoID">{{ logo.LogoNaam }}</option>
              </select>
          </div>
          <div [hidden]="evLogoID.valid || evLogoID.pristine" class="alert alert-danger">Kies een logo</div>

      </div>     
      <!--    
      <div class="row"> 
          <div class="col-sm-10 col-md-10 col-lg-10">
              <label class="klein">Bijlage</label>
              <input [(ngModel)]="item.evMailAttachment"
              placeholder="Bijlage" type="text" class="form-control" 
              #evMailAttachment="ngModel"
              (focus)="initialValue('evMailAttachment',evMailAttachment.value);"
              (blur)="saveField('evMailAttachment',evMailAttachment.value, item.evEventID);">
          </div>
      </div>
      -->
  </div>
</div>
