<div>
  <div class="row mt-2">
      <div class="col">
        <h1>{{title}} </h1>  
      </div>
  </div>
  <div style="position: fixed; top:5px; right:5px">
    <p *ngFor="let alert of alerts">
      <ngb-alert [type]="alert.type" (close)="closeAlert(alert)"><strong>{{ alert.subject }}</strong> {{ alert.message }}</ngb-alert>
    </p>
  </div>
    <div class="row">      
      <div class="col-sm-12">
        <form>
          <div class="form-group">
            <label for="evNaam">Naam</label>
            <textarea 
            rows="2" class="form-control" id="evNaam" name="evNaam" required
            #evNaam
            (blur)="checkValidity(evNaam.value, evWachtwoord.value);"
            (input)="checkValidity(evNaam.value, evWachtwoord.value);"
            ></textarea>
          </div>
          <div class="form-group">
            <label for="evWachtwoord">Wachtwoord</label>
            <textarea  
             rows="2" class="form-control" id="evWachtwoord" name="evWachtwoord" required
             #evWachtwoord
             (blur)="checkValidity(evNaam.value, evWachtwoord.value);"
             (input)="checkValidity(evNaam.value, evWachtwoord.value);"
             ></textarea>
          </div>
          <div class="form-group">
            <label for="evOmschrijving">Omschrijving</label>
            <textarea #evOmschrijving rows="2" class="form-control" id="evOmschrijving" name="evOmschrijving" required></textarea>
          </div>
        </form>
      </div>
    </div>
    <div class="row">
      <div *ngIf="magOpslaan" id="save" class="col">
          <span (click)="saveItem(evNaam.value, evWachtwoord.value, evOmschrijving.value);" class="fas fa-check fa-3x iconGreen pointy"></span>            
      </div>   
    </div>
</div>