import { Component, OnInit, Input, ViewChild} from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { GeneralService } from '../../services/general.service';

import { AlertService} from '../../services/alerts.service';
import { finalize, debounceTime } from 'rxjs/operators';

import { IAlert } from '../../entities/alert.model';
import {Evenement} from '../../entities/evenement.model';
import { Form } from '../../entities/form.model';
import { VeldType } from '../../entities/veldtype.model';
import { Keuzes } from '../../entities/keuzes.model';
import { Afh_Vraag } from '../../entities/afh_vraag.model';
import { Afh_Vraag_Show } from '../../entities/afh_vraag.model';
import { Afh_Vragen_View } from '../../entities/afh_vraag.model';
import { Form_Bestemming } from '../../entities/form_bestemming.model';
import { Form_values } from '../../entities/form_values.model';

//import { Evenement_AfhankelijkService } from '../_services/evenement-afhankelijk.service';
import { AfhVragenService} from '../../services/afh-vragen.service';
import { Evenement_VragenService } from '../../services/evenement-vragen.service';

//import {  Bestemming,  Afhankelijk } from '../model/form.model';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-afh-vragen',
  templateUrl: './afh-vragen.component.html',
  styleUrls: ['./afh-vragen.component.css']
  ,  providers: [GeneralService, AfhVragenService, Evenement_VragenService, AlertService] 
})
export class AfhVragenComponent implements OnInit {
  @Input() item: Evenement;
  @Input() scroll: boolean;
  itemForm = new FormGroup ({
    cParentVraagID: new FormControl()
  });  

  //### specifieke variabelen: aan te passen    
  tableName = "vwAfhankelijkeVragen";
  tabelUpdateName = "tbl_item_dependency";
  primKeyField = 'itemDepID';
  primTableArray: Afh_Vragen_View[];
  vragenArray: Form[];
  keuzesArray: Keuzes[];
  selectedItem: Afh_Vragen_View;
  
  initialPrimTableArray: Afh_Vragen_View[];
  selRecord: Afh_Vraag_Show;
  alerts: Array<IAlert> = [];

  //searchField: string = "omschrijving";
  order: string = 'AntwoordOpVraag';
  editItem: boolean = false;
  createItem: boolean = false;
  tempArray: Afh_Vraag;
  //tempVeldTypeArr: VeldType;

  jaNee = ['J', 'N'];
  controlTypes = [];
  veldTypeArray: VeldType[];
  bestemmingArray: Form_Bestemming[];

//### algemene variabelen: niet aan te passen
  title = "Afhankelijk werkt"
  counter:number; 
  sortAction:boolean;
  reverse: boolean = false;
  showGeneral: boolean = false;  
  newKey: number;
  newItem: Afh_Vraag;
  updateKeyID:number;
  removeKeyID:number;
  itemAdded:boolean;
  initialFieldValues; 
  initialFieldValue: string = "";
  initialFieldName : string = "";  
  errorMessage: string = "";
  subscription;
  private tick: number;
  constructor(private generalService: GeneralService, 
              private fb: FormBuilder, 
              private afhVragenService: AfhVragenService,
              private evenement_VragenService: Evenement_VragenService,
              private alertService:  AlertService) {

    this.createForm()
  }

  ngOnInit() {
    this.dataOphalen();
  }

  dataOphalen() {
    this.afhVragenOphalen();
    this.comboLijstenOphalen()
  }

  afhVragenOphalen() {

    //var primTableArrayUnOrdered: Afh_Vragen_View[];
    let observable = this.afhVragenService.getFilteredData(this.item.evEventID);
    observable
    .subscribe(
      array => this.primTableArray = array,
      err=> this.generalService.giveLog(err),
      () => 
      {
      //this.generalService.giveLog('Tabel opgehaald uit database; ' + primTableArrayUnOrdered.length + ' records');
       this.errorMessage = "";
       //this.generalService.giveLog(primTableArrayUnOrdered[0].omschrijving);
       //this.primTableArray = this.sortArray(primTableArrayUnOrdered);
       this.initialPrimTableArray = this.primTableArray;
      //  this.initialPrimTableArray.forEach(element => {
      //this.generalService.giveLog(this.primTableArray[0].AntwoordOpVraag);
      //  });
       
      }      
    )
    this.newKey = 0;
  }

  comboLijstenOphalen() {
    this.vragenOphalen();    
    if (this.selRecord != undefined) {
        this.keuzesOphalen(this.selRecord.parentID);      
    } 
  }

  keuzesOphalen(parent_id: number)
  {
    this.generalService.giveLog('Keuzes ophalen voor parentID ' + parent_id);
    let observable = this.afhVragenService.getAfhKeuzes(parent_id);
    observable
    .subscribe(
      array => this.keuzesArray = array,
      err=> this.generalService.giveLog(err),
      () => 
      {
      this.generalService.giveLog('Keuzes opgehaald uit database; ' + this.keuzesArray.length + ' records');
       this.errorMessage = "";       
      }      
    );  
  }

  vragenOphalen()
  {
    var primTableArrayUnOrdered: Form[];
    let observableVragen = this.evenement_VragenService.getFilteredData(this.item.evEventID);
    observableVragen
    .subscribe(
      array => this.vragenArray = array,
      err=> this.generalService.giveLog(err),
      () => 
      {
       this.generalService.giveLog(this.vragenArray[0].omschrijving);
      
      }      
    )
  }

//#### Functies voor reactive forms
createForm() {
  this.generalService.giveLog('createForm');
  this.editItem = false;
  this.createItem = false;
  this.itemForm = this.fb.group({
    cParentVraagID: null,
    cChildID: null,  
    cAntwID: null,       
    cResponse: null
  });
} 

populateForm(itemSelected) {

  this.generalService.giveLog('populateForm');
  this.editItem = true;
  this.selRecord = itemSelected;
  this.comboLijstenOphalen();
  if(itemSelected == undefined){
    this.itemForm.setValue ({
      cParentVraagID:null,   
      cChildID: null,
      cAntwID:null,
      cResponse: ''
    });
  }
  else {

    this.itemForm.setValue ({
      cParentVraagID:itemSelected.parentID,   
      cChildID: itemSelected.childID,
      cAntwID:itemSelected.valueItemID,
      cResponse: itemSelected.response
    });      
  }

} 

//saveRecord(itemForm.get('cParentVraagID').value, itemForm.get('cChildID').value, itemForm.get('cAntwID').value, itemForm.get('cResponse').value, item)"

saveRecord(parentID, childID, valueItemID, response, itemSelected) {
  this.generalService.giveLog('Save Item');

  this.newItem = new Afh_Vraag (null, parentID, childID, valueItemID, response, this.item.evEventID );
  if(this.createItem) {
    var response;
    this.createItem = false;
    this.generalService.giveLog('Create Item:' + JSON.stringify(this.newItem));

    this.afhVragenService.addRecord(this.newItem)
    .pipe( finalize( () => this.alertService.newAlert('success','is opgeslagen',3000,this.alerts, this.newItem.parentID.toString()) ) )
    .subscribe(
      gelukt => this.newItem,
      error =>  
      {
        this.generalService.giveLog('error bij wegschrijven');
        this.alertService.newAlert('Fout','niet opgeslagen',3000,this.alerts, this.newItem.parentID.toString());
        this.generalService.giveLog(this.newItem);
      },
      () => 
      {
        this.generalService.giveLog(this.newItem);
        //this.primTableArray.push(this.newItem);
        this.dataOphalen();
      }
    );
  }
  else {
    this.newItem.itemDepID = this.selRecord.itemDepID;
    this.updateRecord(this.newItem);
    this.generalService.giveLog('Create Item / update');
  }
    this.editItem = false;   
}

createNew() {
  this.generalService.giveLog("#### Create"); 
  this.createItem = true;
  this.editItem = true;
  this.populateForm(undefined);  
  //this.comboLijstenOphalen();

}

updateRecord(afhVraag: Afh_Vraag) {
  this.generalService.giveLog("update afh vraag");
  var observable = this.afhVragenService.updateRecord(afhVraag);
  observable.subscribe(
    (response) => {
    this.generalService.giveLog(JSON.stringify(response));
    var callResponse = JSON.stringify(response);
    if (callResponse == '{"update":"OK"}') {
      this.generalService.giveLog("OK");
      //this.selRecord = afhVraag;
      this.dataOphalen();
    } else {
      this.generalService.giveLog("not OK");
    }
  });
}

removeItem(ID:number) {
  this.generalService.giveLog("delete: " + ID);
  var observable = this.afhVragenService.deleteRecord(ID);
  observable.subscribe(
    (response) => {
    this.generalService.giveLog(JSON.stringify(response));
    },
    error =>
    {
      this.generalService.giveLog('Fout');
    },
    () => this.dataOphalen()
  );
}
}
