import { Component, Input, OnInit } from '@angular/core';

import { GeneralService } from '../../services/general.service';
import { LogoService } from '../../services/logo.service';
import { BevestigingsRapportService } from '../../services/bevestigingsRapport.service';
import { EvenementService } from '../../services/evenement.service';

import { Evenement } from '../../entities/evenement.model';
import { Logo } from '../../entities/logo.model';
import { BevestigingsRapport } from '../../entities/bevestigingsRapport.model';

@Component({
  selector: 'app-communicatie',
  templateUrl: './communicatie.component.html',
  styleUrls: ['./communicatie.component.css']
  ,  providers: [GeneralService, LogoService, BevestigingsRapportService, EvenementService] 
})
export class CommunicatieComponent implements OnInit {
  @Input() item: Evenement;
  @Input() scroll: boolean;
//### specifieke variabelen: aan te passen
  tableName = "tbl_evenement";
  primKeyField = 'evEventID';
  primTableArray: Evenement[];
  logoArray: Logo[];
  rapportArray: BevestigingsRapport[];

//### component-specifieke variabelen: niet aan te passen


//### algemene variabelen + constructor: niet aan te passen
  //showGeneral: boolean = false;
  //submitted = false;
  updateKeyID:number;
  removeKeyID:number;
  itemAdded:boolean;
  errorMessage: string = "";
  subscription;
  newKey: number;
  textAreaClass: string [];  
  initialFieldValues;  

  constructor(private generalService: GeneralService,
              private bevestigingsRapportService: BevestigingsRapportService,
              private logoService: LogoService,
              private evenementService:EvenementService
            ) { }

  ngOnInit() {
    this.generalService.giveLog("Pagina geladen");
    this.dataOphalen();
    this.initialFieldValues = new Array();  
    //### voor textAreas
    this.textAreaClass = new Array('', '');
    this.textAreaClass[0] = this.generalService.defineTextAreaClass(this.item.evAanhefRapport);
    this.textAreaClass[1] = this.generalService.defineTextAreaClass(this.item.evSlotformule);
  }

  dataOphalen():void {
    this.generalService.giveLog("bevestigingsRapportOphalen")
    this.bevestigingsRapportOphalen();
    this.logoOphalen();
  }

  bevestigingsRapportOphalen(): void {

    let observable = this.bevestigingsRapportService.getAPI_Values();
    this.generalService.giveLog("---> bevestigingsRapport opgehaald");
    observable
    .subscribe(
      array => this.rapportArray = array,
      err=> this.generalService.giveLog(err),
      () => 
      {
       this.errorMessage = "";
       this.rapportArray.forEach(element => {
       //this.generalService.giveLog(element.RptBevestiging);
       });
       
      }      
    )
  }

  logoOphalen(): void {

    let observable = this.logoService.getAPI_Values();
    this.generalService.giveLog("---> logo opgehaald");
    observable
    .subscribe(
      array => this.logoArray = array,
      err=> this.generalService.giveLog(err),
      () => 
      {
       this.errorMessage = "";
       this.logoArray.forEach(element => {
       //this.generalService.giveLog(element.LogoNaam);
       });
       
      }      
    )
  }

  //###Functies die niet aangepast moeten worden
  initialValue(fieldName:string, fieldValue: string) {
    this.initialFieldValues[fieldName]=fieldValue;
    this.generalService.giveLog('initialValues[' + fieldName + ']: ' + this.initialFieldValues[fieldName]);
  }

  //updateRecord
  /*
  saveField(field, newValue, id) {
    this.generalService.giveLog("field " + field + " - newValue" + newValue + " - id" + id);
    this.generalService.giveLog('saveField: initialValue van ' + field + ": " + this.initialFieldValues[field]);
    if(this.initialFieldValues[field] != newValue) {
      let aantal: number = 0;
      this.generalService.updateItem(this.tableName, id, 0, field, newValue);        
    }
    else {
      this.generalService.giveLog("geen wijziging " + field + " - " + newValue);
    }
  }
  */

  //updateRecord
updateRecord(field?, newValue?, id?)
{
  var observable = this.evenementService.updateItem(this.item);
  observable.subscribe((response) => {
    this.generalService.giveLog(JSON.stringify(response));
    var callResponse = JSON.stringify(response);
    if (callResponse == '{"update":"OK"}') {
      this.generalService.giveLog("OK");
    } else {
      this.generalService.giveLog("not OK");
    }
  });
}

saveField(field, newValue, id) {
  //this.generalService.giveLog("field " + field + " - newValue" + newValue + " - id" + id);
  //this.generalService.giveLog('saveField: initialValue van ' + field + ": " + this.initialFieldValues[field]);
  if(this.initialFieldValues[field] != newValue) {
    //let aantal: number = 0;
    //this.generalService.update_Item(this.tableName, id, 0, field, newValue);       
    this.updateRecord(field, newValue, id); 
  }
  else {
    this.generalService.giveLog("geen wijziging " + field + " - " + newValue);
  }
}

//###Functies specifiek voor textarea
  //Update specifiek textarea
  saveTextArea (field, newValue, id) {
    switch(field) {
      case 'evAanhefRapport':
        this.item.evAanhefRapport = newValue;
        this.generalService.giveLog("newValue = "+newValue);
        break;
      case 'evSlotformule':
        this.item.evSlotformule = newValue;
      default:
        break;
    }
    if(this.initialFieldValues[field] != newValue) {
      var aantal: number = 0;
      var adaptedValue = this.generalService.replaceAll(newValue, "\n", "\\n");
      this.saveField(field, adaptedValue, id);
      //this.generalService.updateItem(this.tableName, id, 0, field, adaptedValue);        
    }
    else {
      this.generalService.giveLog("geen wijziging " + field + " - " + newValue);
    }     
  }

  textAreaDefineClass(index:number, value:string) {
    this.textAreaClass[index] = value == '' ? 'textAreaNotValid': 'textAreaValid';
    //this.generalService.giveLog("Value " + value + " ==> " + this.textAreaClass[index]);
  }

//Change dropdown
      change($event){
        if(this.item.evLogoID == null){
          this.generalService.giveLog("geen logoID");
        }
        else {
          this.generalService.giveLog("logoID = " + this.item.evLogoID);
        }
      }

}
