import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

//import { tokenNotExpired, JwtHelper} from 'angular2-jwt';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class AuthGuard implements CanActivate {
 
    constructor(private router: Router) { }
    jwtHelper = new JwtHelperService();

    canActivate() {
        let token = localStorage.getItem('id_token');
               
        if (token && !this.jwtHelper.isTokenExpired(token)) {
            // logged in so return true
            return true;            
        }
 
        // not logged in so redirect to login page
        this.router.navigate(['/login']);
        return false;
    }
}