import { Component, Input, OnInit } from '@angular/core';

import { GeneralService } from '../../services/general.service';
import { EvenementService } from '../../services/evenement.service';

import { Evenement } from '../../entities/evenement.model';

@Component({
  selector: 'app-betaal-info',
  templateUrl: './betaal-info.component.html',
  styleUrls: ['./betaal-info.component.css']
  ,  providers: [GeneralService, EvenementService] 
})
export class BetaalInfoComponent implements OnInit {
  @Input() item: Evenement;
  @Input() scroll: boolean;
//### specifieke variabelen: aan te passen
  tableName = "tbl_evenement";
  primKeyField = 'evEventID';
  primTableArray: Evenement[];

  //### algemene variabelen + constructor: niet aan te passen
  updateKeyID:number;
  removeKeyID:number;
  itemAdded:boolean;
  errorMessage: string = "";
  subscription;
  newKey: number;
  textAreaClass: string [];  
  initialFieldValues;  

  constructor(private generalService: GeneralService,
              private evenementService:EvenementService
            ) { }

  //constructor() { }

  ngOnInit() {
    this.generalService.giveLog("Pagina geladen");
    this.initialFieldValues = new Array();  
    //### voor textAreas
    this.textAreaClass = new Array('', '');
    this.textAreaClass[0] = this.generalService.defineTextAreaClass(this.item.evAanhefRapport);
    this.textAreaClass[1] = this.generalService.defineTextAreaClass(this.item.evSlotformule);
  }

    //###Functies die niet aangepast moeten worden
    initialValue(fieldName:string, fieldValue: string) {
      this.initialFieldValues[fieldName]=fieldValue;
      this.generalService.giveLog('initialValues[' + fieldName + ']: ' + this.initialFieldValues[fieldName]);
    }
  
    //updateRecord
  updateRecord(field?, newValue?, id?)
  {
    var observable = this.evenementService.updateItem(this.item);
    observable.subscribe((response) => {
      this.generalService.giveLog(JSON.stringify(response));
      var callResponse = JSON.stringify(response);
      if (callResponse == '{"update":"OK"}') {
        this.generalService.giveLog("OK");
      } else {
        this.generalService.giveLog("not OK");
      }
    });
  }
  
  saveField(field, newValue, id) {
    //this.generalService.giveLog("field " + field + " - newValue" + newValue + " - id" + id);
    //this.generalService.giveLog('saveField: initialValue van ' + field + ": " + this.initialFieldValues[field]);
    if(this.initialFieldValues[field] != newValue) {
      //let aantal: number = 0;
      //this.generalService.update_Item(this.tableName, id, 0, field, newValue);       
      this.updateRecord(field, newValue, id); 
    }
    else {
      this.generalService.giveLog("geen wijziging " + field + " - " + newValue);
    }
  }
  
  //###Functies specifiek voor textarea
    //Update specifiek textarea
    saveTextArea (field, newValue, id) {
      switch(field) {
        case 'evAanhefRapport':
          this.item.evAanhefRapport = newValue;
          this.generalService.giveLog("newValue = "+newValue);
          break;
        case 'evSlotformule':
          this.item.evSlotformule = newValue;
        default:
          break;
      }
      if(this.initialFieldValues[field] != newValue) {
        var aantal: number = 0;
        var adaptedValue = this.generalService.replaceAll(newValue, "\n", "\\n");
        this.saveField(field, adaptedValue, id);
        //this.generalService.updateItem(this.tableName, id, 0, field, adaptedValue);        
      }
      else {
        this.generalService.giveLog("geen wijziging " + field + " - " + newValue);
      }     
    }
  
    textAreaDefineClass(index:number, value:string) {
      this.textAreaClass[index] = value == '' ? 'textAreaNotValid': 'textAreaValid';
      //this.generalService.giveLog("Value " + value + " ==> " + this.textAreaClass[index]);
    }

}
