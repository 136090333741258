import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Betaling } from '../entities/betaling.model';
import { BoundElementPropertyAst } from '@angular/compiler';

import { GeneralService } from './general.service';

const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'my-auth-token'
    })
  };

@Injectable()
export class BetalingService{
    apiName = "betaling_view";
   
    apiUrl = 'https://api.spermalie.info/'+this.apiName+'/read.php';
    filteredUrl = 'https://api.spermalie.info/'+this.apiName+'/read_one_event.php?eventID=';

    constructor(private http: HttpClient){}

    generalService = new GeneralService(this.http);

    getAPI_Values (): Observable<Betaling[]> {
        return this.http.get<Betaling[]>(this.apiUrl);
    }

    getFilteredData (eventID:number): Observable<Betaling[]> {
        var url = this.filteredUrl + eventID;
        //console.log(url);
        return this.http.get<Betaling[]>(url);
    }

  //////// CRUD //////////

  /** POST: add a new lijstje to the database */
  addRecord (instance: Betaling): Observable<Betaling> {
    return this.http.post<Betaling>('https://api.spermalie.info/'+this.apiName+'/create.php', JSON.stringify(instance))
  }

  deleteRecord (id: number): Observable<{}> {
    let body = JSON.stringify({ID: id});
    this.generalService.giveLog(body);
    return this.http.post<Betaling>('https://api.spermalie.info/'+this.apiName+'/delete.php', body)
  }

  updateRecord (instance: Betaling): Observable<Betaling> {
    return this.http.post<Betaling>('https://api.spermalie.info/'+this.apiName+'/update.php', JSON.stringify(instance))
  }

    // checkUniekeNaam(naam: string, lijst: Form_values[]): boolean 
    // {
    //   var uniek = true;
    //   if (naam == '') {
    //       uniek = false;
    //   } else {
    //     var BreakException = {};
    //     try {
    //       lijst.forEach(element => {
    //         //console.log('Idem: ' + element.waardeName);
    //         if (element.waardeName == naam) {
    //           uniek = false;
    //           throw BreakException;
    //         }
    //       });        
    //     } catch (error) {
    //       if (error !== BreakException) throw error;
    //     }          
    //   }
    //   return uniek;
    // }
}



