import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Evenement } from '../entities/evenement.model';
import { GeneralService } from './general.service';

const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'my-auth-token'
    })
  };


@Injectable()
export class EvenementService{

    apiName = "evenement";
   
    apiUrl = 'https://api.spermalie.info/'+this.apiName+'/read.php';

    //public evenement_List: Evenement[];

    constructor(private http: HttpClient){}

    generalService = new GeneralService(this.http);

    getAPI_Values (): Observable<Evenement[]> {
        return this.http.get<Evenement[]>(this.apiUrl);
    }

    //////// CRUD //////////

    /** POST: add a new lijstje to the database */
    addAPI_values (instance: Evenement): Observable<Evenement> {
      return this.http.post<Evenement>('https://api.spermalie.info/'+this.apiName+'/create.php', JSON.stringify(instance))
    }

    deleteAPI_values (id: number): Observable<{}> {
      let body = JSON.stringify({evEventID: id});
      return this.http.post<Evenement>('https://api.spermalie.info/'+this.apiName+'/delete.php', body)
    }

    updateItem (evenement: Evenement): Observable<Evenement> {
      //this.generalService.giveLog(JSON.stringify(evenement));
      let observable = this.http.post<Evenement>('https://api.spermalie.info/evenement/update.php', JSON.stringify(evenement));
      return observable;
    }

    checkUniekeNaam(evNaam: string, evenement_List: Evenement[]): boolean 
    {
      var uniek = true;
      var BreakException = {};

      try {
        if (evNaam == "") {
          uniek = false;
        } else {
          evenement_List.forEach(element => {
            //this.generalService.giveLog('Idem: ' + element.evNaam);
            if (element.evNaam == evNaam) {
              uniek = false;
              throw BreakException;
            }
          });            
        }        
      } catch (error) {
        if (error !== BreakException) throw error;
      }
      return uniek;
    }

    checkUniekWachtwoord(evWachtwoord: string, evenement_List: Evenement[]): boolean 
    {
      var uniek = true;
      this.generalService.giveLog('Te checken: ' + evWachtwoord);
      var BreakException = {};
      try {
        if (evWachtwoord == "") {
          uniek = false;
        } else {
          evenement_List.forEach(element => {
            //this.generalService.giveLog('Idem: ' + element.evWachtwoord);
            if (element.evWachtwoord == evWachtwoord) {
              uniek = false;
              throw BreakException;
            }
          });            
        }
      } catch (error) {
        if (error !== BreakException) throw error;
      }
      return uniek;
    }


}