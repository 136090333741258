<div>
  <div class="row mt-2">
      <div class="col-sm-4" id="HoofdItems">
        <h1>{{title}} <span (click)="addItem();" class="fas fa-plus iconGreen pointy float-right"></span></h1>  
      </div>
      <div *ngIf="addNewItem || selectedSomething" class="col-sm-4">
      </div>
      <div  *ngIf="selectedSomething || selectedFormValueItem" class="col-sm-4">
        <h2>Items <span (click)="addSubItem();" class="fas fa-plus iconGreen pointy float-right"></span></h2>  
      </div>
  </div>
  <div style="position: fixed; top:5px; right:5px">
    <p *ngFor="let alert of alerts">
      <ngb-alert [type]="alert.type" (close)="closeAlert(alert)"><strong>{{ alert.subject }}</strong> {{ alert.message }}</ngb-alert>
    </p>
  </div>
  <div class="row">
    <div class="col-sm-4">
      <ul class="list-group" id="lstLijsten">
        <li class="list-group-item list-group-item-action"
          *ngFor="let form_value of mainData; index as i"
          (click)="onSelect(form_value)">
          {{form_value.waardeName}}
          <span (click)="modalDelete(content,i)" class="fas fa-trash-alt iconRed float-right pointy"></span>
        </li>
      </ul>
    </div>
    <div *ngIf="selectedSomething" class="col-sm-4">
      <div class="row">
        <div class="col">
          <form>
            <div class="alineaWit6"><span>Id: </span>{{selectedItem.ID}}</div>
            <div class="form-group">
              <label for="fvNaam">Naam</label>
              <textarea 
              [(ngModel)]="selectedItem.waardeName"
              rows="2" class="form-control" 
              id="fvNaam" 
              #fvNaam = "ngModel"
              name="fvNaam" 
              required
              ></textarea>
            </div>
          </form>
        </div>
      </div>
      <div class="row">
        <div id="saveSelected" class="col">
            <span (click)="saveItem();" class="fas fa-check fa-3x iconGreen pointy"></span>            
        </div>   
      </div>
    </div>
    
    <div *ngIf="addNewItem" class="col-sm-4">
      <div class="row">
        <div class="col">
          <form>
            <div class="alineaWit6"><span>Id: <i>Nieuw item</i></span></div>
            <div class="form-group">
              <label for="fvNewNaam">Naam</label>
              <textarea id="fvNewNaam" 
                rows="2" 
                class="form-control"                 
                #fvNewNaam required
                (blur)="checkNaam(fvNewNaam.value);"
                (input)="checkNaam(fvNewNaam.value);"
                ></textarea>
            </div>
          </form>
        </div>
      </div>
      <div class="row">
        <div *ngIf="magOpslaan" id="saveNew" class="col">
            <span (click)="addItemSave(fvNewNaam.value);" class="fas fa-check fa-3x iconGreen pointy"></span>            
        </div>   
        <div *ngIf="!magOpslaan" id="saveNewBlocked" class="col">
          <span class="fas fa-check fa-3x iconGray"></span>            
      </div>  
      </div> 
    </div>

    <div *ngIf="showItemDetails" class="col-sm-4">
      <ul class="list-group" id="lstLijstItems">
        <li class="list-group-item list-group-item-action"
          *ngFor="let form_value_item of subData; index as i"
          (click)="onSelectFormValueItem(form_value_item)">
          {{form_value_item.itemNaam}} (index {{form_value_item.itemSorteer}})
          <span (click)="modalDelete(contentDetail,i)" class="fas fa-trash-alt iconRed float-right pointy"></span>
        </li>
      </ul>
    </div>
    <div *ngIf="selectedFormValueItem" class="col-sm-4" Id="SubItemInfo">
      <div class="row">
        <div class="col">
          <form>
            <div class="alineaWit6"><span>Id: </span>{{selectedSubItem.itemID}}</div>
            <div class="form-group">
              <label for="itemNaam">Naam</label>
              <textarea 
              [(ngModel)]="selectedSubItem.itemNaam"
              rows="2" class="form-control" 
              id="itemNaam" 
              #itemNaam = "ngModel"
              name="itemNaam"
              required
              (blur)="giveDefaultSortNr();" 
              ></textarea>
            </div>
            <div class="form-group">
              <label for="itemSorteer">Sorteerindex</label>
              <input 
              type="number"
              [(ngModel)]="selectedSubItem.itemSorteer"
              class="form-control" 
              id="itemSorteer" 
              #itemSorteer = "ngModel"
              name="itemSorteer" 
              required
              >
            </div>             
            <div class="form-group">
              <label for="itemNum">Numerieke waarde</label>
              <input 
              type="number"
              [(ngModel)]="selectedSubItem.itemNum"
              class="form-control" 
              id="itemNum" 
              #itemNum = "ngModel"
              name="itemNum"
              >
            </div>
            <div class="form-group">
              <label for="itemEenheid">Eenheid</label>
              <input 
              [(ngModel)]="selectedSubItem.itemEenheid"
              class="form-control" 
              id="itemEenheid" 
              #itemNum = "ngModel"
              name="itemEenheid" 
              >
            </div>   

            <div class="form-group">
              <label for="itemMax">Max. aantal</label>
              <input 
              type="number"
              [(ngModel)]="selectedSubItem.itemMax"
              class="form-control" 
              id="itemMax" 
              #itemMax = "ngModel"
              name="itemMax"
              >
            </div>                     
          </form>
        </div>
      </div>
      <div class="row">
        <div id="saveFvItem" class="col">
            <span (click)="saveSubItem();" class="fas fa-check fa-3x iconGreen pointy"></span>            
        </div>   
      </div>
    </div>
    <div *ngIf="addNewSubItem" class="col-sm-4" Id="SubItemNew">
      <div class="row">
        <div class="col">
          <form>
            <div class="form-group">
              <label for="itemNaam">Naam</label>
              <textarea 
              rows="2" class="form-control" 
              #itemNaam
              id="newItemNaam" 
              name="itemNaam" 
              (blur)="checkNaamSubItem(itemNaam.value);"
              (input)="checkNaamSubItem(itemNaam.value);"
              required
              ></textarea>
            </div>
            <div class="form-group">
              <label for="itemSorteer">Sorteerindex</label>
              <input 
              type="number"
              class="form-control" 
              #itemSorteer
              id="itemSorteer" 
              name="itemSorteer" 
              required
              >
            </div>             
            <div class="form-group">
              <label for="itemNum">Numerieke waarde</label>
              <input 
              type="number"
              class="form-control" 
              #itemNum
              id="itemNum" 
              name="itemNum"
              >
            </div>
            <div class="form-group">
              <label for="itemEenheid">Eenheid</label>
              <input 
              class="form-control" 
              #itemEenheid
              id="itemEenheid" 
              name="itemEenheid" 
              >
            </div>   
            <div class="form-group">
              <label for="itemMax">Max. aantal deelnemers</label>
              <input 
              type="number"
              class="form-control" 
              #itemMax
              id="itemMax" 
              name="itemMax"
              >
            </div>                     
          </form>
        </div>
      </div>
      <div class="row">
        <div *ngIf="magOpslaanSub" id="saveNewFvItem" class="col">
            <span (click)="addSubItemSave(itemNaam.value, itemSorteer.value, itemNum.value, itemEenheid.value, itemMax.value);" class="fas fa-check fa-3x iconGreen pointy"></span>            
        </div>   
        <div *ngIf="!magOpslaanSub" id="saveNewFvItemBlocked" class="col">
          <span class="fas fa-check fa-3x iconGray"></span>            
      </div>           
      </div>
    </div>    
  </div>
</div>

<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Verwijderen</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>Weet u zeker dat u lijst </p> 
    <p><strong>{{selectedItem.waardeName}}</strong> met id: {{selectedItem.ID}}</p>
    <p>wil verwijderen?</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="c('Verwijder')">Ja, verwijder</button>
    <button type="button" class="btn btn-outline-dark" (click)="c('Annuleer')">Neen, behouden</button>
  </div>
</ng-template>
<ng-template #contentDetail let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Verwijderen</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>Weet u zeker dat u het item </p> 
    <p><strong>{{selectedSubItem.itemNaam}}</strong> met id: {{selectedSubItem.itemID}}</p>
    <p>wil verwijderen?</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="c('VerwijderDetail')">Ja, verwijder</button> 
    <button type="button" class="btn btn-outline-dark" (click)="c('AnnuleerDetail')">Neen, behouden</button>
  </div>
</ng-template>