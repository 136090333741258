import { Pipe, PipeTransform } from '@angular/core';
//
@Pipe({	name: 'filter'})
export class FilterPipe implements PipeTransform {
	transform(value:any[], searchString: any, searchField: any):any[]{
		if(value){
			// hoofdletterONafhankelijk maken
			if (searchString){	
				return value.filter((item) => item[searchField].toLowerCase().indexOf(searchString.toLowerCase())>=0);
			}				
		}
		return value;
	}
	fieldIndex(json, fieldName:string){
		let counter:number = 0;
		for (let label in json) {
			if (label == fieldName){
				return counter;
			}
			counter ++;
		}
		return;
	}
}