import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';

import {Logo} from '../entities/logo.model';

import { GeneralService } from './general.service';

const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'my-auth-token'
      })
    };
  
  @Injectable({ providedIn: 'root' })
  export class LogoService {
        private table: string = "tbl_logo";
        apiName = "logo";
        
        //##### Default values create
        public defPath: string = 'https://www.spermalie.info/info/img/';
        public defImage: string ='afbeelding_naam.png';

        apiUrl = 'https://api.spermalie.info/'+this.apiName+'/read.php';
        //filteredUrl = 'https://api.spermalie.info/'+this.apiName+'/read_one_event.php?eventID=';
    
        constructor(private http: HttpClient){}
    
        generalService = new GeneralService(this.http);
    
        getAPI_Values (): Observable<Logo[]> {
            return this.http.get<Logo[]>(this.apiUrl);
        }
  }