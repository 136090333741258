import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';

import { GeneralService } from '../../services/general.service';
import { AlertService} from '../../services/alerts.service';
import { finalize, debounceTime } from 'rxjs/operators';

import { PrijsService } from '../../services/prijs.service';

import { IAlert } from '../../entities/alert.model';
import { Evenement } from '../../entities/evenement.model';
import { Prijs } from '../../entities/prijs.model';

@Component({
  selector: 'app-prijs',
  templateUrl: './prijs.component.html',
  styleUrls: ['./prijs.component.css']
  ,  providers: [PrijsService, GeneralService, AlertService] 
})
export class PrijsComponent implements OnInit {
  @Input() item: Evenement;
  @Input() scroll: boolean;
  itemForm = new FormGroup ({
    cOmschrijving: new FormControl()
  });   
//### specifieke variabelen: aan te passen
tableName = "tbl_prijzen";
primKeyField = 'prPrijsID';
primTableArray: Prijs[];
initialPrimTableArray: Prijs[];
selRecord: Prijs;
searchField: string = "prNaam";
order: string = 'prNaam';
editItem: boolean = false;
createItem: boolean = false;
newItem: Prijs;
tempArray: Prijs;
alerts: Array<IAlert> = [];
//### component-specifieke variabelen: niet aan te passen


//### algemene variabelen: niet aan te passen
reverse: boolean = false;
showGeneral: boolean = false;
updateKeyID: number;
removeKeyID: number;
itemAdded: boolean;
errorMessage: string = "";
newKey: number;
textAreaClass: string [];  
initialFieldValues;
private tick: number;
counter:number; 
sortAction:boolean;
radioDetailFix: boolean = false;
  constructor(private generalService: GeneralService, 
              private prijsService:PrijsService,               
              private alertService: AlertService,
              private fb: FormBuilder) {
    this.createForm();
   }

  ngOnInit() {
    this.dataOphalen();
    this.textAreaClass = new Array('', '');
    this.initialFieldValues = new Array();
    this.selRecord = new Prijs(0,0,"","",0,0,"","",0);
    this.generalService.giveLog('ID van event' + this.item.evEventID);
  }

  dataOphalen():void {

    var observable = this.prijsService.getFilteredData(this.item.evEventID);
    observable
    .subscribe(
      array => this.primTableArray = array,
      err=> this.generalService.giveLog(err),
      () => 
      {
       this.generalService.giveLog('Tabel prijzen opgehaald uit database; ' + this.primTableArray.length + ' records');
       this.errorMessage = "";
       this.initialPrimTableArray = this.primTableArray;
      this.initialPrimTableArray.forEach(element => {
       this.generalService.giveLog(element.prNaam);
      });
       
      }      
    )
    this.newKey = 0;
  }

  radioDetailFixToggle() {
    this.radioDetailFix = this.radioDetailFix ? false : true;
    this.generalService.giveLog('radioDetailFix: ' + this.radioDetailFix);
  }    

  bepaalVolgordeMax() {
    var volgorde:number = 1;
    this.primTableArray.forEach(element => {
      if (element.prVolgorde > volgorde) {
        volgorde = element.prVolgorde;
      }
    });
    this.generalService.giveLog("max van volgorde:" + volgorde);
    return volgorde;
  }

    //### Functies voor sortable
    /*
    updateSortOrder() {
      this.generalService.giveLog("drag ended");
      this.counter = 0;
      this.primTableArray.forEach(element => {
          this.generalService.giveLog(element.prPrijsID + ' = ' + this.counter + ' - ' + element.prNaam + " (" + element.prVolgorde + ") *** " +  
          this.initialPrimTableArray[this.counter].prNaam + " (" + this.initialPrimTableArray[this.counter].prVolgorde + ")");  
          if(element.prVolgorde != this.counter) {
            this.generalService.updateItem(this.tableName, element.prPrijsID,0,'prVolgorde',this.counter);
             this.generalService.giveLog('update');
          }            
          this.counter++;  
      })
    }
    */

    updateSortOrder() {
      this.generalService.giveLog("drag ended");
      this.counter = 0;
      this.primTableArray.forEach(element => {
          /*this.generalService.giveLog(element.ID + ' = ' + this.counter + ' - ' + element.omschrijving + " (" + element.volgorde + ") *** " +  
          this.initialPrimTableArray[this.counter].omschrijving + " (" + this.initialPrimTableArray[this.counter].volgorde + ")");  */
          
          if(element.prVolgorde != this.counter) {
            element.prVolgorde = this.counter;
            this.updateRecord(element);
          }            
          this.counter++;  
      })
    }

  sortArray(tableArray:Prijs[]) {
      this.sortAction = true;
      while(this.sortAction){
        this.sortAction = false;
        this.counter = 0;
        tableArray.forEach(element => {
          if(this.counter > 0) {
            if(Number(tableArray[this.counter].prVolgorde) < Number(tableArray[this.counter -1].prVolgorde)) {
              this.tempArray = tableArray[this.counter];
              tableArray[this.counter] = tableArray[this.counter -1];
              tableArray[this.counter -1] = this.tempArray;
              this.sortAction = true;
            }
          }
          this.counter++;
        })            
      }
      return tableArray;
  }

//###Functies die niet aangepast moeten worden	
initialValue(fieldName:string, fieldValue: string) {
  this.initialFieldValues[fieldName]=fieldValue;
  this.generalService.giveLog('initialValues[' + fieldName + ']: ' + this.initialFieldValues[fieldName]);
}

setOrder(value: string) {
  console.log(value);
  if (this.order === value) {
    this.reverse = !this.reverse;
  }
  else {
    this.reverse = false;
  }
  this.order = value;
}

chooseFilterField(value){ 
    this.searchField = value;
    console.log("searchfield = " + value);
}    

//delete record
deleteRecord(ID: number) {
  this.generalService.giveLog("delete: " + ID);
  var observable = this.prijsService.deleteRecord(ID);
  observable.subscribe(
    (response) => {
    this.generalService.giveLog(JSON.stringify(response));
    },
    error =>
    {
      this.generalService.giveLog('Fout');
    },
    () => this.dataOphalen()
  );
}

  onSelect(selItem: Prijs): void {
        this.generalService.giveLog('selItem.prNaam: ' + selItem.prNaam);
        this.selRecord = selItem;
        this.generalService.giveLog('this.selRecord.prNaam: ' + this.selRecord.prNaam);
  }

//#### Functies voor reactive forms
  createForm() {
    this.editItem = false;
    this.itemForm = this.fb.group({
      cNaam: ['', Validators.required ],
      cOmschrijving: ['', Validators.required ],
      cPrijs: [null, Validators.required ],    
      cMaxDeelnemers: null,
      cHeader: ''
    });
  } 

  populateForm(naam, omschrijving, prijs, maxDeelnemers, header, itemSelected) {
    this.generalService.giveLog('populateForm');
    this.editItem = true;
    this.selRecord = itemSelected;
    this.generalService.giveLog('omschrijving = ' + omschrijving);
    this.itemForm.setValue ({
      cNaam:naam,      
      cOmschrijving: omschrijving,
      cPrijs: prijs,    
      cMaxDeelnemers: maxDeelnemers,
      cHeader: header
    });
  } 

  createNew() {
    this.generalService.giveLog("#### Create"); 
    this.createItem = true;
    this.editItem = true;
    this.populateForm('','',null,null,'',undefined);
  }

  saveRecord(naam, omschrijving, prijs, maxDeelnemers, header) {
    var volgorde: number;
    //this.generalService.giveLog("De volgorde moet 1 zijn:" + (volgorde + 1));
    if (this.selRecord != null) {
      this.generalService.giveLog("selRecord.prPrijsID" + this.selRecord.prPrijsID)
      volgorde = this.selRecord.prVolgorde;
    }
    else {      
      volgorde = this.bepaalVolgordeMax() + 1;
    }
    this.generalService.giveLog("volgorde nieuwe prijs: " + volgorde); 
    var omschrijving_underscore = omschrijving.replace(" ", "_");
    this.generalService.giveLog(omschrijving_underscore)
    this.newItem = new Prijs (null,this.item.evEventID, naam, omschrijving, prijs, maxDeelnemers, header, omschrijving_underscore, volgorde);
    if(this.createItem) {
      var response;
      this.createItem = false;
      this.generalService.giveLog('Create Item:' + JSON.stringify(this.newItem));

      this.prijsService.addRecord(this.newItem)
      .pipe( finalize( () => this.alertService.newAlert('success','is opgeslagen',3000,this.alerts, this.newItem.prOmschrijving) ) )
      .subscribe(
        gelukt => this.newItem,
        error =>  
        {
          this.generalService.giveLog('error bij wegschrijven');
          this.alertService.newAlert('Fout','niet opgeslagen',3000,this.alerts, this.newItem.prOmschrijving);
          //this.generalService.giveLog(this.newItem);
        },
        () => 
        {
          this.generalService.giveLog(this.newItem);
          this.primTableArray.push(this.newItem);
          this.dataOphalen();
        }
      );
    }
    else {
      this.newItem.prPrijsID = this.selRecord.prPrijsID;
      this.updateRecord(this.newItem);
      this.generalService.giveLog('Create Item / update');
    }
      this.editItem = false;    

  }

  //CRUD
  updateRecord(prijs: Prijs) {
    this.generalService.giveLog("update: " + prijs.prNaam + " --> " + prijs.prVolgorde);
    var observable = this.prijsService.updateRecord(prijs);
    observable.subscribe(
      (response) => {
      this.generalService.giveLog(JSON.stringify(response));
      var callResponse = JSON.stringify(response);
      if (callResponse == '{"update":"OK"}') {
        this.generalService.giveLog("OK");
        this.selRecord = prijs;
        this.dataOphalen();
      } else {
        this.generalService.giveLog("not OK");
      }
    });
  }

  

  /*
  createRecord(prNaam, prOmschrijving, prPrijs, prMaxDeelnemers, prHeader) {
    this.editItem = false;
    this.generalService.giveLog("#### Create");        
    this.generalService.giveLog("prNaam " + prNaam);
    this.addItem(prNaam, prOmschrijving, prPrijs, prMaxDeelnemers, prHeader);
  }  
*/
}
