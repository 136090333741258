export class Afh_Vraag
{
        constructor(
                public itemDepID:number,
                public parentID:number,
                public childID:number,
                public valueItemID:number,
                public response:string,
                public itemDepEventID:number
        ){}
}

export class Afh_Vraag_Show
{
    constructor(
            public itemDepID:number,
            public itemDepEventID:number,
            public AntwoordOpVraag:string,
            public Antwoord:string,
            public ToonVraag:string,
            public childID:number,
            public parentID:number
    ){}
}

export class Afh_Vragen_View
{
        constructor(
                public itemDepID:number,
                public itemDepEventID:number,
                public AntwoordOpVraag:string,
                public Antwoord:string,
                public ToonVraag:string,
                public childID:number,
                public parentID:number,
                public valueItemID:number,
                public response:string                
        ){}
}

export class Afh_Keuzes_View
{
        constructor(
                public vraagID:number,
                public eventID:number,
                public itemID:number,
                public itemNaam:string
        ){}
}