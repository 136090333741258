import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Form_values } from '../entities/form_values.model';
import { BoundElementPropertyAst } from '@angular/compiler';

const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'my-auth-token'
    })
  };

@Injectable()
export class Form_ValuesService{
    formValuesUrl = 'https://api.spermalie.info/formvalues/read.php';
    public Form_values_List: Form_values[];

    constructor(
        private http: HttpClient){
    }

    getmainData (): Observable<Form_values[]> {
        return this.http.get<Form_values[]>(this.formValuesUrl
        );
    }

  //////// CRUD //////////

  /** POST: add a new lijstje to the database */
  addForm_values (form_values: Form_values): Observable<Form_values> {
    return this.http.post<Form_values>('https://api.spermalie.info/formvalues/create.php', JSON.stringify(form_values))
  }

  deleteForm_values (id: number): Observable<{}> {
    let body = JSON.stringify({ID: id});
    return this.http.post<Form_values>('https://api.spermalie.info/formvalues/delete.php', body)
  }

  updateForm_values (form_values: Form_values): Observable<Form_values> {
    return this.http.post<Form_values>('https://api.spermalie.info/formvalues/update.php', JSON.stringify(form_values))
  }

//   /** PUT: update the hero on the server. Returns the updated hero upon success. */
//   updateHero (hero: Hero): Observable<Hero> {
//     httpOptions.headers =
//       httpOptions.headers.set('Authorization', 'my-new-auth-token');

//     return this.http.put<Hero>(this.heroesUrl, hero, httpOptions)
//       .pipe(
//         catchError(this.handleError('updateHero', hero))
//       );
//   }

    MaakLijsten()
    {
        //this.Form_values_List = new Form_values[3];
        /* this.lijst1 = new Form_values(1, 'Roomtype');
        this.lijst2 = new Form_values(2, 'Akkoord');
        this.lijst3 = new Form_values(3, 'Klassen'); */
    }    

    checkUniekeNaam(naam: string, lijst: Form_values[]): boolean 
    {
      var uniek = true;
      if (naam == '') {
          uniek = false;
      } else {
        var BreakException = {};
        try {
          lijst.forEach(element => {
            //console.log('Idem: ' + element.waardeName);
            if (element.waardeName == naam) {
              uniek = false;
              throw BreakException;
            }
          });        
        } catch (error) {
          if (error !== BreakException) throw error;
        }          
      }
      return uniek;
    }
}



