<div class="alert alert-danger" role="alert" [hidden]="errorMessage === ''" [innerHTML]="errorMessage"></div>
<div [hidden]="showGeneral && !radioDetailFix"> <!-- Lijst met events -->
        <div class="row mt-2 justify-content-end">
            <div class="col-sm-5" id="HoofdItems">
                <h1>{{title}}</h1>  
            </div>
            <div class="col-sm-1 pt-1" style="font-weight:bold"><span class="align-text-bottom">Filter:</span></div>   
            <div class="col-sm-3">
                <select class="form-control" #searchField (change)="chooseFilterField(searchField.value)">
                    <option value="evActief">Actief</option>
                    <option value="evNaam">Naam</option>
                    <option value="evWachtwoord">Wachtwoord</option>
                </select>
            </div>
            <div class="col-sm-3">
                <div [hidden]="searchField.value === 'evActief'">
                    <input class="form-control" type="text" placeholder="Zoek" [(ngModel)]="searchString">
                </div>
                <div [hidden]="searchField.value !== 'evActief'">
                    <select class="form-control" [(ngModel)]="searchString">
                        <option value="J">Actief</option>
                        <option value="N">Niet actief</option>
                    </select>
                </div>
                
            </div>         
        </div><BR>
        <div [class.scroll]="radioDetailFix" [class.scrollFull]="!radioDetailFix">
            <table class="table table-condensed table-hover">
                <thead>
                    <tr>
                        <th data-field="evEventID">ID</th>
                        <th [class.active]="order === 'evNaam'"
                                (click)="setOrder('evNaam')" 
                                class="searchColumn">
                                Naam 
                                <span class="" [hidden]="order === 'evNaam'"><i class="fas fa-sort pointy" aria-hidden="true"></i></span>
                                <span class="fontGreen" [hidden]="reverse || order !== 'evNaam'"><i class="fas fa-sort-alpha-down pointy" aria-hidden="true"></i></span>
                                <span class="fontRed" [hidden]="!reverse || order !== 'evNaam'"><i class="fas fa-sort-alpha-up pointy" aria-hidden="true"></i></span>
                        </th>
                        <th [class.active]="order === 'evDatum'"
                                (click)="setOrder('evDatum')"
                                class="searchColumn">
                                Datum
                                <span class="" [hidden]="order === 'evDatum'"><i class="fas fa-sort pointy" aria-hidden="true"></i></span>
                                <span class="fontGreen" [hidden]="reverse || order !== 'evDatum'"><i class="fas fa-sort-alpha-down pointy" aria-hidden="true"></i></span>
                                <span class="fontRed" [hidden]="!reverse || order !== 'evDatum'"><i class="fas fa-sort-alpha-up pointy" aria-hidden="true"></i></span>                        
                        </th>
                        <th [class.active]="order === 'evWachtwoord'"
                                (click)="setOrder('evWachtwoord')"
                                class="searchColumn">
                                Wachtwoord
                                <span class="" [hidden]="order === 'evWachtwoord'"><i class="fas fa-sort pointy" aria-hidden="true"></i></span>
                                <span class="fontGreen" [hidden]="reverse || order !== 'evWachtwoord'"><i class="fas fa-sort-alpha-down pointy" aria-hidden="true"></i></span>
                                <span class="fontRed" [hidden]="!reverse || order !== 'evWachtwoord'"><i class="fas fa-sort-alpha-up pointy" aria-hidden="true"></i></span>
                        </th>
                        <th>Bewerk</th>
                        <th>Rapportering</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of primTableArraySorted | filter: searchString:searchField.value">
                        <td>{{ item.evEventID }}</td>
                        <td [class.fontRed]="item.evActief === 'N'">{{item.evNaam}}</td>
                        <td *ngIf="item.evDatum != '0000-00-00 00:00:00'">{{item.evDatum | date:"yyyy / MM / dd"}}</td>
                        <td *ngIf="item.evDatum == '0000-00-00 00:00:00'"></td>
                        <td>{{item.evWachtwoord}}</td>
                        <td>
                            <span (click)="onSelect(item)" class="fas fa-pencil-alt text-success pointy" aria-hidden="true" title="Bewerken"></span>
                            <span>&nbsp;&nbsp;</span>
                            <a target="_blank" [href]="'https://spermalie.info/info/'+item.evWachtwoord+'/new/sub/'+editCheck">
                                <span class="fas fa-plus text-success" aria-hidden="true" title="Nieuwe inschrijving"></span>
                            </a>
                            <span>&nbsp;&nbsp;</span>
                            <span (click)="toggleSelectedActief(item)" [class.fontRed]="item.evActief === 'N'" [class.fontGreen]="item.evActief === 'J'" title="Actief?"><i class="far fa-eye pointy" aria-hidden="true"></i></span>
                        </td>
                        <td>
                            <a target="_blank" title="Overzicht evenement" [href]="'https://spermalie.info/info/goedkeuring.php?eventID='+item.evEventID"><span class='fas fa-print text-success pointy'></span></a>
                            <span>&nbsp;&nbsp;</span>
                            <a target="_blank" title="Download Excel" [href]="'https://www.spermalie.info/info/rapport.php?token=1&eventID='+item.evEventID+'&wachtlijst=0'"><span class='fas fa-file-excel text-success pointy'></span></a>
                            <span>&nbsp;&nbsp;</span>
                            <a target="_blank" title="Download Wachtlijst" [href]="'https://www.spermalie.info/info/rapport.php?token=1&eventID='+item.evEventID+'&wachtlijst=1'"><span class='fas fa-stopwatch text-success pointy'></span></a>
                            <span>&nbsp;&nbsp;</span>
                            <a target="_blank" title="Maak mySQL-tabel" [href]="'https://www.spermalie.info/info/utilities/stagedataToTable.php?id='+item.evEventID"><span class='fas fa-hdd text-success pointy'></span></a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
</div>

<div *ngIf="showGeneral && !radioDetailFix" class="pageTitle" style="margin-left: 15px">{{selectedItem.evNaam}} ({{selectedItem.evEventID}})</div> 

<div *ngIf="showGeneral || radioDetailFix">
    <div style="font-size: 20px;margin-left:20px;position:absolute;margin-top:10px;right:20px;z-index:100" class="fontGreen" title="Evenementen lijst vastpinnen">
        <span (click)="radioDetailFixToggle()">
            <i class="fas fa-thumbtack text-success pointy" [class.rotate-35]="radioDetailFix" aria-hidden="true"></i>
        </span>
    </div>    
    <div *ngIf="!radioDetailFix" style="font-size: 20px;position:absolute;margin-top:10px;right:50px;z-index:100" class="fontGreen" title="Toon evenementen lijst">
        <span (click)="showList()">
            <i class="fas fa-list-ul text-success pointy" aria-hidden="true"></i>
        </span>
    </div>
    <div style="font-size: 20px;position:absolute;margin-top:10px;right:80px;z-index:100" class="fontGreen">
        <a target="_blank" title="Voorbeeld formulier" [href]="'https://spermalie.info/info/'+selectedItem.evAfkortingNaam">
            <i class="fas fa-external-link-alt text-success pointy" aria-hidden="true"></i>
        </a>
    </div>
<div class="col-sm-12 col-md-12 col-lg-12" style="margin-top:5px">
    <tabset #staticTabs>
        <tab heading="Algemeen">
            <div [class.scroll]="radioDetailFix" 
                *ngIf="(showGeneral && radioDetail==='Algemeen') || (radioDetailFix  && radioDetail==='Algemeen')" style="margin-top:15px" > <!-- Algemene opties evenement -->

                    <div class="row"> <!--Eventnaam -->
                            <div class="col-sm-6 col-md-6 col-lg-6">
                                <label class="klein">Eventnaam </label>
                                <input [(ngModel)]="selectedItem.evNaam"
                                placeholder="Naam" type="text" class="form-control" required
                                #evNaam="ngModel"
                                (focus)="initialValue('evNaam',evNaam.value);"
                                (blur)="saveField('evNaam', evNaam.value, selectedItem.evEventID);">
                                <div [hidden]="evNaam.valid || evNaam.pristine" class="alert alert-danger">Vul een naam in</div>
                            </div>

                            <div class="col-sm-6 col-md-6 col-lg-6"><!--Mail verantwoordelijke -->		
                                <label class="klein">Mail verantwoordelijke</label>
                                <input [(ngModel)]="selectedItem.evMailVerantwoordelijke"
                                placeholder="Mail verantwoordelijke" type="email" class="form-control" required
                                #evMailVerantwoordelijke="ngModel"
                                (focus)="initialValue('evMailVerantwoordelijke',evMailVerantwoordelijke.value);"
                                (blur)="saveField('evMailVerantwoordelijke',evMailVerantwoordelijke.value, selectedItem.evEventID);">
                                <div [hidden]="evMailVerantwoordelijke.valid || evMailVerantwoordelijke.pristine" class="alert alert-danger">Vul het mailadres van de verantwoordelijke in </div>
                            </div>	
                    </div>

                    <div class="row"> <!--Afkorting, Wachtwoord, Max. aantal deelnemers, Inschrijvingen / persoon -->			
                        <div class="col-sm-2 col-md-2 col-lg-2">	
                            <label class="klein">Afkorting</label> <!--Afkorting -->
                            <input [(ngModel)]="selectedItem.evAfkortingNaam"
                            placeholder="Afkorting" type="text" class="form-control" required
                            #evAfkortingNaam="ngModel"
                            (focus)="initialValue('evAfkortingNaam',evAfkortingNaam.value);"
                            (blur)="saveField('evAfkortingNaam',evAfkortingNaam.value, selectedItem.evEventID);">
                            <div [hidden]="evAfkortingNaam.valid || evAfkortingNaam.pristine" class="alert alert-danger">Vul een afkorting in</div>
                        </div>

                        <div class="col-sm-2 col-md-2 col-lg-2"> <!--Wachtwoord -->	
                            <label class="klein">Wachtwoord</label>
                            <input [(ngModel)]="selectedItem.evWachtwoord"
                            placeholder="Wachtwoord" type="text" class="form-control" required
                            #evWachtwoord="ngModel"
                            (focus)="initialValue('evWachtwoord',evWachtwoord.value);"
                            (blur)="saveField('evWachtwoord',evWachtwoord.value, selectedItem.evEventID);">
                            <div [hidden]="evWachtwoord.valid || evWachtwoord.pristine" class="alert alert-danger">Vul een uniek wachtwoord in</div>
                        </div>

                        <div class="col-sm-3 col-md-2 col-lg-2"> <!--Max. aantal deelnemers -->
                            <label class="klein">Max. aantal deelnemers</label>
                            <input [(ngModel)]="selectedItem.evMaxDeelnemers"
                            placeholder="Max. aantal" type="number" class="form-control" 
                            #evMaxDeelnemers="ngModel"
                            (mouseover)="initialValue('evMaxDeelnemers',evMaxDeelnemers.value);"
                            (blur)="saveField('evMaxDeelnemers',evMaxDeelnemers.value, selectedItem.evEventID, true);"
                            (click)="saveField('evMaxDeelnemers',evMaxDeelnemers.value, selectedItem.evEventID, true);">
                        </div>

                        <div class="col-sm-3 col-md-2 col-lg-2"> <!--Inschrijvingen / persoon -->
                            <label class="klein">Inschrijvingen / persoon</label>
                            <input [(ngModel)]="selectedItem.evMaxInschrijvingen"
                            placeholder="Inschr./pers." type="number" class="form-control" 
                            #evMaxInschrijvingen="ngModel"
                            (mouseover)="initialValue('evMaxInschrijvingen',evMaxInschrijvingen.value);"
                            (blur)="saveField('evMaxInschrijvingen',evMaxInschrijvingen.value, selectedItem.evEventID, true);"
                            (click)="saveField('evMaxInschrijvingen',evMaxInschrijvingen.value, selectedItem.evEventID, true);">
                        </div>

                        <div class="col-sm-2 col-md-3 col-lg-3 col-md-offset-1">	
                            <label class="klein">Login</label>
                            <div style="margin-top: 5px">
                                <label class="checkbox-inline">
                                    <input type="checkbox" class="" [checked]="eventLogin" (change)="toggleLogin()"/> Aanmelden verplicht
                                </label>
                                <input [(ngModel)]="selectedItem.evLogin" type="hidden" class="form-control"/>
                            </div>
                        </div>            
                    </div>	

                <!--### Datepicker datum en tijd event-->
                    <div class="row">			
                        <div class="col-sm-4 col-md-4 col-lg-3">
                            <div class="form-group">
                                <label class="klein">Datum</label>
                                <div class="input-group">
                                <div class="input-group-addon"><span (click)="dateEdit(0)" class="fas fa-pencil-alt text-success" aria-hidden="true"></span></div>
                                <input type="text" class="form-control" id="exampleInputAmount" placeholder="Amount" value='{{selectedItem.evDatum | date:"d / M / yyyy" }}'>
                                <div class="input-group-addon"><span (click)="dateClearAndSave(0)" class="fontRed" [hidden]="!dateCanDelete[0]"><i class="fas fa-trash-alt" aria-hidden="true"></i></span></div>
                                </div>
                            </div>
                        </div>
                                
                        <div class="col-sm-3 col-md-2 col-lg-2 col-lg-offset-1">
                            <label class="klein">Aanvangstijd</label>
                            <div class="input-group">
                                <input placeholder="uur" type="number" min="0" max="23" class="form-control" 
                                #evAanvangsuur
                                value="{{eventAanvangsUur}}"
                                (mouseover)="initialValue('evAanvangsTijd', evAanvangsuur.value + ':' + evAanvangsminuut.value);"
                                (change)="saveTimeField('evAanvangsTijd', evAanvangsuur.value, evAanvangsminuut.value, selectedItem.evEventID);"
                                > 
                                <div class="input-group-addon">uur</div>
                            </div>
                        </div>

                        <div class="col-sm-3 col-md-2 col-lg-2" >
                            <div class="input-group" style="margin-top:24px">
                                <input placeholder="min." type="number" min="0" max="59" class="form-control" 
                                #evAanvangsminuut
                                value="{{eventAanvangsMinuut}}"
                                (mouseover)="initialValue('evAanvangsTijd', evAanvangsuur.value + ':' + evAanvangsminuut.value);"
                                (change)="saveTimeField('evAanvangsTijd', evAanvangsuur.value, evAanvangsminuut.value, selectedItem.evEventID);"
                                >
                                <div class="input-group-addon">min.</div>
                            </div>
                        </div>

                        <div class="col-sm-2 col-md-2 col-lg-3 col-md-offset-1">
                            <label class="klein">Actief</label>
                            <div style="margin-top:5px">
                                <label class="checkbox-inline">
                                    <input type="checkbox" class="" [checked]="eventActief" (change)="toggleActief()"/> Actief
                                </label>
                                <input [(ngModel)]="selectedItem.evActief" type="hidden" class="form-control"/>
                            </div>
                        </div>
                    </div>

                    <div class="row" [hidden]="!dateEdited[0]">
                        <div class="col-sm-2 col-md-2 col-lg-2">
                            <button type="button" class="btn btn-sm btn-info btn-block" (click)="selToday(0)">Vandaag</button><BR>
                            <button type="button" class="btn btn-sm btn-danger btn-block" (click)="dateClear(0)">Wissen</button><BR>
                            <button type="button" class="btn btn-sm btn-success btn-block" (click)="dateSave(0)">Opslaan</button><BR>
                        </div>            
                        <div class="col-sm-4 col-md-4 col-lg-4">
                            <div style="display:inline-block; min-height:290px;">
                                <datepicker 
                                    [(ngModel)]="dateSel[0]" [minDate]="dateMin[0]" [showWeeks]="false" [dateDisabled]="dateBlocked[0]"
                                    >
                                </datepicker>
                            </div>
                        </div>
                    </div>     

                    <div class="row"><div class="col-sm-12 horizontalBar"></div></div>		  

                <!--### Datepicker einddatum inschrijvingen-->
                    <div class="row">			
                        <div class="col-sm-4 col-md-4 col-lg-3">
                            <div class="form-group">
                                <label class="klein">Einddatum inschrijvingen</label>
                                <div class="input-group">
                                    <div class="input-group-addon"><span (click)="dateEdit(1)" class="fas fa-pencil-alt text-success" aria-hidden="true"></span></div>
                                    <input type="text" class="form-control" id="exampleInputAmount" placeholder="Amount" value='{{selectedItem.evEindDatumInschrijving | date:"d / M / yyyy" }}'>
                                    <div class="input-group-addon"><span (click)="dateClearAndSave(1)" class="fontRed" [hidden]="!dateCanDelete[1]"><i class="fas fa-trash-alt" aria-hidden="true"></i></span></div>
                                </div>
                            </div>
                        </div>
                    </div>		        
                    <div class="row" [hidden]="!dateEdited[1]">
                        <div class="col-sm-2 col-md-2 col-lg-2">
                            <button type="button" class="btn btn-sm btn-info btn-block" (click)="selToday(1)">Vandaag</button><BR>
                            <button type="button" class="btn btn-sm btn-danger btn-block" (click)="dateClear(1)">Wissen</button><BR>
                            <button type="button" class="btn btn-sm btn-success btn-block" (click)="dateSave(1)">Opslaan</button><BR>
                        </div>            
                        <div class="col-sm-4 col-md-4 col-lg-4">
                            <div style="display:inline-block; min-height:290px;">
                                <datepicker 
                                    [(ngModel)]="dateSel[1]" [maxDate]="dateMax[1]" [showWeeks]="false" [dateDisabled]="dateBlocked[1]"
                                    >
                                </datepicker>
                            </div>
                        </div>
                    </div>   

                    <div class="row"><div class="col-sm-12 horizontalBar"></div></div>	

                <!--### Datepicker datum en tijd evActiefVanaf		        -->
                    <div class="row">
                        <div class="col-sm-4 col-md-4 col-lg-3">
                            <div class="form-group">
                                <label class="klein">Actief vanaf</label>
                                <div class="input-group">
                                    <div class="input-group-addon"><span (click)="dateEdit(2)" class="fas fa-pencil-alt text-success" aria-hidden="true"></span></div>
                                    <input type="text" class="form-control" id="exampleInputAmount" placeholder="Amount" value='{{selectedItem.evActiefVanaf | date:"d / M / yyyy" }}'>
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-sm-3 col-md-2 col-lg-2 col-lg-offset-1">	
                            <label class="klein">Tijdstip actief</label>
                            <div class="input-group">
                                <input placeholder="uur" type="number" min="0" max="23" class="form-control" 
                                #evActiefVanafUur
                                value="{{eventActiefVanafUur}}"
                                (change)="actiefVanafChanged(evActiefVanafUur.value, evActiefVanafMinuut.value);"
                                (click)="actiefVanafChanged(evActiefVanafUur.value, evActiefVanafMinuut.value);"
                                > 
                                <div class="input-group-addon">uur</div>
                            </div>
                        </div>

                        <div class="col-sm-3 col-md-2 col-lg-2" >
                            <div class="input-group" style="margin-top:24px">
                                <input placeholder="min." type="number" min="0" max="59" class="form-control" 
                                #evActiefVanafMinuut
                                value="{{eventActiefVanafMinuut}}"
                                (change)="actiefVanafChanged(evActiefVanafUur.value, evActiefVanafMinuut.value);"
                                (click)="actiefVanafChanged(evActiefVanafUur.value, evActiefVanafMinuut.value);"
                                >
                                <div class="input-group-addon">min.</div>
                            </div>
                        </div>

                        <div class="col-sm-1 col-md-1 col-lg-1" style="padding-top:30px" >
                            <span class="fontRedBig" [hidden]="editingTime[2] === false" (click)="dateSave(2)"><i class="far fa-save" aria-hidden="true"></i></span>
                            <span class="fontGreen" [hidden]="editingTime[2]"> <i class="fas fa-save text-success" aria-hidden="true"></i></span>                                         
                        </div>
                    </div>
                        
                    <div class="row" [hidden]="!dateEdited[2]">
                        <div class="col-sm-2 col-md-2 col-lg-2">
                            <button type="button" class="btn btn-sm btn-info btn-block" (click)="selToday(2)">Vandaag</button><BR>
                            <button type="button" class="btn btn-sm btn-success btn-block" (click)="dateSave(2)">Opslaan</button><BR>
                        </div>            
                        <div class="col-sm-4 col-md-4 col-lg-4">
                            <div style="display:inline-block; min-height:290px;">
                                <datepicker 
                                    [(ngModel)]="dateSel[2]" [minDate]="dateMin[2]" [maxDate]="dateMax[2]" [showWeeks]="false" [dateDisabled]="dateBlocked[2]"
                                    >
                                </datepicker>
                            </div>
                        </div>
                    </div>

                <!-- textareas voor omschrijving en succesboodschap -->          
                
                    <div class="row">  <!-- Omschrijving -->
                        <div class="col-sm-6 col-md-6 col-lg-6">
                            <label class="klein">Omschrijving</label>
                            <textarea #evOmschrijving rows="3" placeholder="Omschrijving" class="form-control {{textAreaClass[0]}}"
                            (focus)="initialValue('evOmschrijving',evOmschrijving.value);"
                            (keyup)="textAreaDefineClass(0, evOmschrijving.value)"
                            (blur)="saveTextArea('evOmschrijving',evOmschrijving.value, selectedItem.evEventID);">{{selectedItem.evOmschrijving | nl2br:2}}</textarea>
                            <div [hidden]="evOmschrijving.value !== '' || evOmschrijving.pristine" class="alert alert-danger">Geef een omschrijving voor het evenement</div>
                        </div>
                        <!-- SlotFormule rapport: zie communicatie -->
                        <!-- <div class="col-sm-6 col-md-6 col-lg-6"> 
                            <label class="klein">SlotFormule rapport</label>
                            <textarea #evSlotformule rows="3" placeholder="SlotFormule rapport" class="form-control"
                            (focus)="initialValue('evSlotformule',evSlotformule.value);"
                            (blur)="saveTextArea('evSlotformule',evSlotformule.value, selectedItem.evEventID);">{{selectedItem.evSlotformule | nl2br:2}}</textarea>
                        </div> -->
                    </div>

                <!-- checkboxes for tabs -->          
                    <div class="row" style="padding-top:10px"> 
                        <div class="col-sm-3 col-md-2 col-lg-2">
                            <label class="checkbox-inline">
                                <input type="checkbox" class="" [checked]="arrTabs[0] !== ''" (change)="toggleTab(0,'Afhankelijk')"/> Afhankelijk
                            </label>
                        </div>
                        <div class="col-sm-3 col-md-2 col-lg-2">
                            <label class="checkbox-inline">
                                <input type="checkbox" class="" [checked]="arrTabs[1] !== ''" (change)="toggleTab(1,'Communicatie')"/> Communicatie
                            </label>
                        </div>
                        <div class="col-sm-3 col-md-2 col-lg-2">
                            <label class="checkbox-inline">
                                <input type="checkbox" class="" [checked]="arrTabs[2] !== ''" (change)="toggleTab(2,'Betaling')"/> Betaling
                            </label>
                        </div> 
                        <div class="col-sm-3 col-md-2 col-lg-3">
                            <label class="checkbox-inline">
                                <input type="checkbox" class="" [checked]="arrTabs[4] !== ''" (change)="toggleTab(4,'Wachtlijst')"/> Wachtlijst
                            </label>
                        </div>                         
                    </div>   
            </div>

        </tab>             
        <tab heading="Vragen">
            <app-vragen [item]="selectedItem" [class.scroll]="radioDetailFix" class="container" ></app-vragen>
        </tab> 
        <tab heading="Communicatie">
            <app-communicatie [item]="selectedItem" [scroll]="radioDetailFix" *ngIf="(showGeneral && arrTabs[1] !== '') || (radioDetailFix  && arrTabs[1] !== '')" class="container"></app-communicatie> 
        </tab>     
        <tab heading="Betaling">
            <app-betaal-info [item]="selectedItem" [scroll]="radioDetailFix" *ngIf="(showGeneral && arrTabs[2] !== '') || (radioDetailFix  && arrTabs[2] !== '')" class="container"></app-betaal-info> 
        </tab>   
        
        <tab heading="Prijzen">
            <app-prijs [item]="selectedItem" [scroll]="radioDetailFix" *ngIf="(showGeneral && arrTabs[2] !== '') || (radioDetailFix  && arrTabs[2] !== '')" class="container"></app-prijs> 
        </tab>           


        <tab heading="Afh. vragen">
            <app-afh-vragen [item]="selectedItem" [class.scroll]="radioDetailFix" *ngIf="(showGeneral && arrTabs[0] !== '') || (radioDetailFix && arrTabs[0] !== '')" class="container" ></app-afh-vragen>
        </tab>                 
         <!--  
        <tab heading="Wachtlijst" >

            <evenement-wachtlijst [item]="selectedItem" [scroll]="radioDetailFix" *ngIf="(showGeneral && arrTabs[4] !== '') || (radioDetailFix  && arrTabs[4] !== '')" class="container"></evenement-wachtlijst> 
        </tab>     -->
    </tabset>         
</div>
</div>