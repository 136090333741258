<div>
    <div class="row mt-2">
        <div class="col">
          <h1>{{title}} </h1>  
        </div>
    </div>
    <div style="position: fixed; top:5px; right:5px">
      <p *ngFor="let alert of alerts">
        <ngb-alert [type]="alert.type" (close)="closeAlert(alert)"><strong>{{ alert.subject }}</strong> {{ alert.message }}</ngb-alert>
      </p>
    </div>
    <div class="row alineaWit12">
      <div class="col-sm-12">
          <select (change)="onSelect($event.target.value)" class="form-control form-control-lg">
              <option *ngFor="let element of mainData; index as i" value={{i}}>{{element.evNaam}}</option>
          </select>
      </div>
    </div>
    <div *ngIf="selectedSomething" class="col">
      <div class="row">      
        <div class="col">
          <form>
            <div class="form-group">
              <label for="evNaam">Naam</label>
              <textarea [(ngModel)]="newItem.evNaam" 
              rows="2" class="form-control" id="evNaam" name="evNaam" required
              #evNaam="ngModel"
              (blur)="checkValidity(evNaam.value, evWachtwoord.value);"
              (ngModelChange)="checkValidity(evNaam.value, evWachtwoord.value);"
              ></textarea>
            </div>
            <div class="form-group">
              <label for="evWachtwoord">Wachtwoord</label>
              <textarea [(ngModel)]="newItem.evWachtwoord" 
               rows="2" class="form-control" id="evWachtwoord" name="evWachtwoord" required
               #evWachtwoord="ngModel"
               (blur)="checkValidity(evNaam.value, evWachtwoord.value);"
               (ngModelChange)="checkValidity(evNaam.value, evWachtwoord.value);"
               ></textarea>
            </div>
            <div class="form-group">
              <label for="evOmschrijving">Omschrijving</label>
              <textarea [(ngModel)]="newItem.evOmschrijving" rows="2" class="form-control" id="evOmschrijving" name="evOmschrijving" required></textarea>
            </div>
          </form>
        </div>
      </div>
      <div class="row">
        <div *ngIf="magOpslaan" id="save" class="col">
            <span (click)="saveItem();" class="fas fa-check fa-3x iconGreen pointy"></span>            
        </div>   
      </div>
    </div>  
    <div class="row">
      <div *ngIf="kopieOK" id="copyQenP" class="col">
          <button type="button" class="btn btn-success" (click)="kopieerVragenEnPrijzen()">Kopieer vragen en prijzen</button>
          <!-- <span (click)="kopieerVragenEnPrijzen();" class="fas fa-check fa-3x iconGreen pointy"></span>             -->
      </div>   
    </div>
</div>

  
  <!-- <ng-template #content let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Verwijderen</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>Weet u zeker dat u lijst </p> 
      <p><strong>{{selectedItem.waardeName}}</strong> met id: {{selectedItem.ID}}</p>
      <p>wil verwijderen?</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="c('Verwijder')">Ja, verwijder</button> <!-- deleteItem(content,selectedItem.ID) -->
  <!--    
      <button type="button" class="btn btn-outline-dark" (click)="c('Annuleer')">Neen, behouden</button>
    </div>
  </ng-template> -->