<div class="alert alert-danger" role="alert" [hidden]="errorMessage === ''" [innerHTML]="errorMessage"></div>

<div class="row">
    <div class="col-sm-1 pageTitle"><span style="margin-left:15px;" (click)="createNew()" class="fas fa-plus text-success pointy" aria-hidden="true"></span></div> 
    <div style="font-size: 20px;margin-left:20px;position:absolute;right:-30px;z-index:100" class="col-sm-1 fontGreen"><span (click)="radioDetailFixToggle()">
        <i class="fas fa-thumbtack text-success pointy" [class.rotate-35]="radioDetailFix" aria-hidden="true"></i></span>
    </div> 
</div>

<div class="scroll" *ngIf="!editItem  || radioDetailFix">
    <div class="row" style="border-bottom:2px solid rgb(221, 221, 221);margin-left:0px" >
            <!--<div style="font-weight: bold" class="col-sm-1">Plaats</div>-->
            <div style="font-weight: bold" class="col-sm-1 col-md-1 col-lg-1">Sort</div>    
            <div style="font-weight: bold" class="col-sm-3 col-md-3 col-lg-2">Naam</div> 
            <div style="font-weight: bold" class="col-sm-2 col-md-2 col-lg-1">Prijs</div>  
            <div style="font-weight: bold" class="col-sm-2 col-md-2 col-lg-1">Max. dln.</div>
            <div style="font-weight: bold" class="col-sm-2 col-md-2 col-lg-1">Afk.</div>            
            <div style="font-weight: bold" class="col-sm-2 col-md-2 col-lg-1">Bewerk</div>
    </div>
    <div class="row" style="margin-left:0px">
        <div class="col-sm-12" (dragend)="updateSortOrder()">
            <bs-sortable
                [(ngModel)]="primTableArray"
                [itemTemplate]="itemTemplate"
                itemClass="sortable-item"
                itemActiveClass="sortable-item-active"
                placeholderItem="De gegevens worden geladen"
                placeholderClass="placeholderStyle"
                wrapperClass="sortable-wrapper"
                >
            </bs-sortable>
        </div>
    </div>
</div>
   

<div *ngIf="editItem">
    <form [formGroup]="itemForm">
        <div class="row">
            <div class="col-sm-12">
                <label class="klein">Naam</label>
                <input class="form-control" formControlName="cNaam">
            </div>
        </div>
        <div [hidden]="itemForm.get('cNaam').status==='VALID' || itemForm.get('cNaam').pristine===true" class="alert alert-danger">Vul een naam in</div>

        <div class="row">
            <div class="col-sm-12">
                <label class="klein">Omschrijving</label>
                <input class="form-control" formControlName="cOmschrijving">
            </div>
        </div>
        <div [hidden]="itemForm.get('cOmschrijving').status==='VALID' || itemForm.get('cOmschrijving').pristine===true" class="alert alert-danger">Vul een omschrijving in</div>

        <div class="row">
            <div class="col-sm-3">
                <label class="klein">Prijs</label>
                <input type="number" class="form-control" formControlName="cPrijs">               
            </div>
        </div>
        <div [hidden]="itemForm.get('cPrijs').status==='VALID' || itemForm.get('cPrijs').pristine===true" class="alert alert-danger">Vul de prijs in</div>

        <div class="row" [hidden]="itemForm.status!=='VALID'">
            <div class="col-sm-3">
                <label class="klein">Max. aantal deelnemers</label>
                <input type="number" class="form-control" formControlName="cMaxDeelnemers">
            </div>
            <div class="col-sm-9">
                <label class="klein">Titel</label>
                <input class="form-control" formControlName="cHeader">
            </div>
        </div>                 
        <BR> 
        <button [disabled]="itemForm.status!=='VALID'" type="button" class="btn btn-success" 
        (click)="saveRecord(itemForm.get('cNaam').value, itemForm.get('cOmschrijving').value, itemForm.get('cPrijs').value, itemForm.get('cMaxDeelnemers').value, itemForm.get('cHeader').value)">Opslaan</button>  
        <button type="button" class="btn btn-danger" (click)="createForm()">Annuleren</button>  
    </form>
</div>
<BR> 

<ng-template  #itemTemplate let-item="item" let-index="index">
        <div class="row" style="border-bottom:1px solid rgb(221, 221, 221);padding:5px" >
                <div class="col-sm-2 col-md-2 col-lg-1">{{item.value.prVolgorde}}</div>
                <div class="col-sm-4 col-md-3 col-lg-2">{{item.value.prNaam}}</div>
                <div class="col-sm-2 col-md-2 col-lg-1">{{item.value.prPrijs}}</div>                     
                <div class="col-sm-2 col-md-2 col-lg-1">{{item.value.prMaxDeelnemers}}</div>
                <div class="col-sm-2 col-md-2 col-lg-1">{{item.value.prHeader}}</div>
       
                <div class="col-sm-1 text-center">
                    <span (click)="populateForm(item.value.prNaam,item.value.prOmschrijving, item.value.prPrijs, item.value.prMaxDeelnemers, item.value.prHeader, item.value)" 
                        class="fas fa-pencil-alt text-success pointy" aria-hidden="true"></span>
                    <span>&nbsp;&nbsp;</span>                    
                    <span (click)="deleteRecord(item.value.prPrijsID)" class="fas fa-times text-warning pointy" aria-hidden="true"></span>
                </div>
        </div>
</ng-template>
           
