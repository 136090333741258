<div class="alert alert-danger" role="alert" [hidden]="errorMessage === ''" [innerHTML]="errorMessage"></div>
<div class="row">
    <div class="col-s-12">
        <h3>Login</h3>
        <div *ngIf="!loggedIn()">
            <div class="alert alert-danger"><strong>Opgepast!</strong> Je moet aangemeld zijn om dit onderdeel te bekijken. Geef uw logingegevens hieronder op.</div>
            <!--<button class="btn btn-large" (click)="popup()" style="width:165px;height:40px;background: url(../assets/btn_aanmelden_165x40.png) no-repeat;"></button>-->
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                <label for="naam">Naam: </label>
                <input id="naam" type="text" formControlName="naam" />
              
                <br />
              
                <label for="wachtwoord">Wachtwoord: </label>
                <input id="wachtwoord" type="password" formControlName="wachtwoord" />
              
                <br />
              
                <button class="btn btn-large btn-primary" type="submit">Inloggen</button>
            </form>
        </div>
        <div *ngIf="loggedIn()">
            <div class="alert alert-success"><strong>Je bent aangemeld!</strong> Kies een item in het menu om verder te gaan.</div>
            <p>Klik op onderstaande knop om af te melden.</p>
            <button class="btn btn-primary btn-large waves-effect right" (click)="logout()">Afmelden</button>
        </div>
    </div>
</div>