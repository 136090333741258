import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { VeldType } from '../entities/veldtype.model';
//import { BoundElementPropertyAst } from '@angular/compiler';

const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'my-auth-token'
    })
  };

@Injectable()
export class VeldTypeService{
    className:string = 'veldType';

    mainDataUrl = 'https://api.spermalie.info/' + this.className + '/read.php';

    constructor(
        private http: HttpClient){
    }

    getmainData (): Observable<VeldType[]> {
        console.log(this.mainDataUrl);
        return this.http.get<VeldType[]>(this.mainDataUrl
        );
    }


  //////// CRUD //////////

  /** POST: add a new lijstje to the database */
  /*
  addItem (newItem: Form_value_item): Observable<Form_value_item> {
    return this.http.post<Form_value_item>('https://api.spermalie.info/form_value_items/create.php', JSON.stringify(newItem))
  }

  deleteItem (id: number): Observable<{}> {
    let body = JSON.stringify({itemID: id});
    return this.http.post<Form_value_item>('https://api.spermalie.info/form_value_items/delete.php', body)
  }

  updateItem (form_value_item: Form_value_item): Observable<Form_value_item> {
    return this.http.post<Form_value_item>('https://api.spermalie.info/form_value_items/update.php', JSON.stringify(form_value_item))
  }
  */
}



