import { Injectable } from '@angular/core';
//import { RequestOptions } from '@angular/http';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
//import 'rxjs/add/operator/map';

@Injectable()
//##### AANPASSEN #############
export class GeneralService {

    private token: string = "1";
    //private table: string = "tbl_logo";
    private serveraddress = 'https://www.spermalie.info/Test/API/CRUD/';

//##################

    private parameters; 
    private headers = new Headers({'Content-Type': 'application/json'});
    private apiUrl; //= this.serveraddress + this.parameters; 
    private page;
    private table_Name:string;
    private tableData;
    errorMessage: string = "";

    constructor(private http: HttpClient){

    }
//### CRUD functions
    readEntireTable(table_Name){
        
        this.page = 'read.php';
        this.parameters = '?token='+this.token +'&table=' + table_Name;
        this.apiUrl = this.serveraddress + this.page + this.parameters; 
        this.giveLog(this.apiUrl);
        //this.tableData = this.http.get(this.apiUrl,this.jwt());
        this.tableData = this.http.get(this.apiUrl);
        this.giveLog(this.tableData);
        return this.tableData;
    }

    readAllRecords(table_Name, randomChars){
        this.page = 'readSafe.php';
        this.token = this.maakToken(randomChars);
        this.parameters = '?token='+this.token +'&table=' + table_Name;
        this.apiUrl = this.serveraddress + this.page + this.parameters; 
        this.giveLog(this.apiUrl);
        //this.tableData = this.http.get(this.apiUrl,this.jwt());
        this.tableData = this.http.get(this.apiUrl);
        console.log(this.tableData);
        return this.tableData;
    }    

    // private jwt() {
    //     // create authorization header with jwt token
    //     let id_token = JSON.parse(localStorage.getItem('id_token'));
    //     let token = JSON.parse(id_token.token);
    //     if (token) {
    //         let headers = new Headers({ 'Authorization': 'Bearer ' + token.jwt });
    //         return new RequestOptions({ headers: headers });
    //     }
    // }

    readView(view_Name) {
        this.page = 'readViewIndex.php';
        this.parameters = '?token='+this.token +'&view=' + view_Name;
        this.apiUrl = this.serveraddress + this.page + this.parameters; 
        //console.log(this.apiUrl);
        this.tableData = this.http.get(this.apiUrl);
        this.giveLog('lengte tableData: ' + this.tableData.length);
        return this.tableData;        
    }

    readViewWhere(view_Name, whereField, whereValue, type) {
        //type: indien number ==> number, anders beschouwd als string
        this.page = 'readView.php';
        this.parameters = '?token='+this.token +'&view=' + view_Name + '&whereField=' + whereField + '&whereValue=' + whereValue
        + '&type=' + type ;
        this.apiUrl = this.serveraddress + this.page + this.parameters; 
        this.tableData = this.http.get(this.apiUrl);
        this.giveLog(this.apiUrl);
        this.giveLog(this.tableData);
        return this.tableData;        
    }

//https://www.spermalie.info/Test/API/CRUD/readView.php?token=1&view=vwBetaling

        
    readFilteredTable(table_Name, filterField, filterAction, filterValue){
        //https://github.com/mevdschee/php-crud-api List + Filter
        /*
        cs: contain string (string contains value)
        sw: start with (string starts with value)
        ew: end with (string end with value)
        eq: equal (string or number matches exactly)
        lt: lower than (number is lower than value)
        le: lower or equal (number is lower than or equal to value)
        ge: greater or equal (number is higher than or equal to value)
        gt: greater than (number is higher than value)
        bt: between (number is between two comma separated values)
        in: in (number is in comma separated list of values)
        is: is null (field contains "NULL" value)
        You can negate all filters by prepending a 'n' character, so that 'eq' becomes 'neq'.
        */
        this.page = 'read.php';
        this.parameters = '?token='+this.token +'&table=' + table_Name  +'&filter=' + filterField + ',' + filterAction + ',' + filterValue;
        this.apiUrl = this.serveraddress + this.page + this.parameters; 
        console.log(this.apiUrl);
        this.tableData = this.http.get(this.apiUrl);
        return this.tableData;
    }

    readLimitedTable(table_Name, filterField, filterAction, filterValue, orderField, orderSense, noRecords){
        //https://github.com/mevdschee/php-crud-api List + Filter
        /*
        cs: contain string (string contains value)
        sw: start with (string starts with value)
        ew: end with (string end with value)
        eq: equal (string or number matches exactly)
        lt: lower than (number is lower than value)
        le: lower or equal (number is lower than or equal to value)
        ge: greater or equal (number is higher than or equal to value)
        gt: greater than (number is higher than value)
        bt: between (number is between two comma separated values)
        in: in (number is in comma separated list of values)
        is: is null (field contains "NULL" value)
        You can negate all filters by prepending a 'n' character, so that 'eq' becomes 'neq'.
        */
        this.page = 'read.php';
        this.parameters = '?token='+this.token +'&table=' + table_Name  +'&filter=' + filterField + ',' 
        + filterAction + ',' + filterValue +'&order=' + orderField + ',' + orderSense + '&page=1,' + noRecords;
        this.apiUrl = this.serveraddress + this.page + this.parameters; 
        console.log(this.apiUrl);
        this.tableData = this.http.get(this.apiUrl);
        return this.tableData;
    }

    // deleteRecord(primKey:number, tableName: string){
    //     //console.log("record verwijderd: id = " + primKey + 'tabel = ' + tableName);
    //     this.page = 'delete.php';
    //     this.parameters = '?token='+this.token +'&table=' + tableName +'&id=' + primKey;
        
    //     this.apiUrl = this.serveraddress + this.page + this.parameters; 
    //     console.log('apiUrl= ' + this.apiUrl);
    //     return this.http.get(this.apiUrl)
    //         .map(res => res.json());
    // }

    // removeRecord(table, primKey){
    //     //console.log(table + " delete " + primKey + " from " + table);
    //     let body = JSON.stringify({Tabel: table, PrimKey:primKey, token:1 });

    //     //console.log(body);
    //     return this.http.post('https://www.spermalie.info/Test/API/CRUD/remove.php', body)
    //         .map(res => res.json());
    // }


    // updateRecord(table, primKey, field, value){
    //     //console.log(table + " update");
    //     let body = JSON.stringify({Tabel: table, PrimKey:primKey, Fieldname: field, NewValue: value, token:1 });

    //     //console.log(body);
    //     return this.http.post('https://www.spermalie.info/Test/API/CRUD/update.php', body)
    //         .map(res => res.json());
    // }

    updateItem(tablename:string, id:number, newKey:number, fieldName:string, newValue) {
        if(newValue == null) {
            newValue = "null";
            this.giveLog("value = null");
        }
        let aantalLength:number = 0;
        let updateKeyID:number= 0;
        let aantal = "0";
        if (newKey == 0) {
            updateKeyID = id;
        }
        else {
            updateKeyID = newKey;
        }
    }
    // this.updateRecord(tablename, updateKeyID, fieldName, newValue).subscribe(
    //      updatedItem => {
    //          aantal = updatedItem.aantal;
    //          aantalLength = aantal.length;
    //          //console.log('stringlengte = ' + aantalLength);
    //      },
    //       err =>  {
    //         this.errorMessage = "<strong>Foutmelding:</strong> het record is niet gewijzigd. Probeer het opnieuw.";
    //         this.errorMessageChange.next(this.errorMessage);
    //       },
    //       () => {
    //             if(aantalLength == 1) {
    //                 this.errorMessage = "";
    //             }
    //             else {
    //                 this.errorMessage = "<strong>Foutmelding:</strong> de waarde van dit veld is niet uniek. Probeer het opnieuw.";                   
    //             }
    //             //console.log(aantal + " records upgedate");                
    //             this.errorMessageChange.next(this.errorMessage);
    //       })
    // }

    // removeItem(tablename:string, id:number, newKey:number, tableArr:any[], fieldName) {
    //     let removeKeyID:number= 0;
    //     var dbActionCompleted:boolean = false;
    //     this.giveLog('Te verwijderen:' + tablename + ' - ' + id + ' - ' + newKey + ' - ' + fieldName );
    //     if (newKey == 0) {
    //         removeKeyID = id;
    //     }
    //     else {
    //         removeKeyID = newKey;
    //     }
    //     this.removeRecord(tablename, removeKeyID).subscribe(
    //         deletedItem => {},
    //         err =>  {
    //             this.errorMessage = "<strong>Foutmelding:</strong> het record is niet verwijderd. Probeer het opnieuw.";
    //             this.errorMessageChange.next(this.errorMessage);
    //             return dbActionCompleted;
    //         },
    //         () => {
    //             let removeIndex = this.searchIndex(removeKeyID, tableArr, fieldName);
    //             tableArr.splice(removeIndex,1);
    //             this.errorMessage = "";
    //             this.errorMessageChange.next(this.errorMessage);
    //             dbActionCompleted = true;
    //             this.giveLog('dbActionCompleted: ' + dbActionCompleted);
    //             return dbActionCompleted;
    //         })
    // }

//### Stringfunctions
        //functie om code naar formulier te sturen waarmee inschrijving aangepast kan worden na afsluiten event
        createEditCheck() {
            var d = new Date();
            var stringEditCheck = d.getDay()+d.getHours()+"spermalie" // bv. 13spermalie
            return btoa(stringEditCheck);
        }

      searchIndex(keyID:number, selArray:any[], fieldName:string) {
      let counter:number = 0;
      let index:number = -1;
        selArray.forEach(element => {
            if(element[fieldName] == keyID){
            index = counter;
          } 
          else {
            counter++;
            //console.log(counter + ' - ' + element[fieldName]);
          }
      });
      return index;
    }

    replaceAll (source, search, replacement) {
    return source.replace(new RegExp(search, 'g'), replacement);
    };

    addLeadingZero(numberValue: number, nrLength) {
        var strLength = numberValue.toString().length;
        var leadingZeros = "";
        for(var i = 0; i < nrLength - strLength ; i++) {
            leadingZeros = leadingZeros + "0";
        }
        return leadingZeros + numberValue;
    }

//### DateTime functions
    dateAmyStyle(inputDate:Date) {
    var mm = inputDate.getMonth() + 1; // getMonth() is zero-based
    var dd = inputDate.getDate();

    return [inputDate.getFullYear(),
            '-',
            (mm>9 ? '' : '0') + mm,
            '-',
            (dd>9 ? '' : '0') + dd
            ].join('');
    };

    dateTimeAmyStyle(inputDate:Date) {
    var mm = inputDate.getMonth() + 1; // getMonth() is zero-based
    var dd = inputDate.getDate();
    var hh = inputDate.getHours();
    var nn = inputDate.getMinutes();
    var ss = inputDate.getSeconds();

    return [inputDate.getFullYear(),
            '-',
            (mm>9 ? '' : '0') + mm,
            '-',
            (dd>9 ? '' : '0') + dd,
            ' ',
            (hh>9 ? '' : '0') + hh,
            ':',
            (nn>9 ? '' : '0') + nn,
            ':',
            (ss>9 ? '' : '0') + ss
            ].join('');
    }

    dateTimeString(inputDate:Date) {
    var mm = inputDate.getMonth() + 1; // getMonth() is zero-based
    var dd = inputDate.getDate();
    var hh = inputDate.getHours();
    var nn = inputDate.getMinutes();
    var ss = inputDate.getSeconds();

    return [inputDate.getFullYear(),
            (mm>9 ? '' : '0') + mm,
            (dd>9 ? '' : '0') + dd,
            (hh>9 ? '' : '0') + hh,
            (nn>9 ? '' : '0') + nn,
            (ss>9 ? '' : '0') + ss
            ].join('');
      
    }


//### Functies voor textArea
  defineTextAreaClass(value:string) {
    var className = value == '' ? 'textAreaNotValid': 'textAreaValid';
    console.log("class = " + className);
    return className;
  }

//token
    goKelleyann () {
        var randomGetal;
        var randomString = "";
        randomGetal = this.randomIntFromInterval(45,59);
        randomString += String.fromCharCode(randomGetal);
        var aantalTekens = this.randomIntFromInterval(180,220);
        for(var i = 0; i <= aantalTekens; i++) {
            var zone = this.randomIntFromInterval(1,3);
            switch(zone) {
                case 1:
                    randomGetal = this.randomIntFromInterval(97,126);
                    break;
                case 2:
                    randomGetal = this.randomIntFromInterval(45,59);
                    break;
                default:
                    randomGetal = this.randomIntFromInterval(63,91);
            }
            randomString += "," + String.fromCharCode(randomGetal);
            
        }
        //this.giveLog('randomString ='+ randomString);
        //this.maakToken(randomString);
        return randomString;
    }

    maakToken(basicString:string) {
        var randomArray = basicString.split(",");
        var datumString = this.dateTimeString(new Date);
        //this.giveLog(datumString);
        //var numDate = parseInt(this.dateTimeString(new Date));
        //this.giveLog('randomString ='+ (numDate + 20000000000000));
        //this.giveLog('zesde teken:' + randomArray[5]);
        var interval = (randomArray[5].charCodeAt(0) % 6) + 7;
        //this.giveLog('ASCII zesde teken:' + randomArray[5].charCodeAt(0));
        this.giveLog('interval = ' + interval) ;
        var counter = 1;
        for(var i = 0; i <14; i++) {
            //this.giveLog(datumString.substr(i,1));
            randomArray[counter * interval] = datumString.substr(i,1);
            //this.giveLog(counter * interval);
            counter++;
        }

        this.giveLog('joined array= ' +  randomArray.join(''));
        return(randomArray.join(''));
    }

    randomIntFromInterval(min,max)
    {
        return Math.floor(Math.random()*(max-min+1)+min);
    }

//### Varia
    giveLog(message){
        if (/localhost/.test(document.location.host)) {
            console.log(message);
        }
    }    
}