import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Form_value_item } from '../entities/form_value_item.model';
//import { BoundElementPropertyAst } from '@angular/compiler';

const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'my-auth-token'
    })
  };

@Injectable()
export class Form_Value_ItemsService{
    formValueItemsUrl = 'https://api.spermalie.info/form_value_items/read.php';
    formValueIdUrl = 'https://api.spermalie.info/form_value_items/readOneFormValue.php?id=';

    constructor(
        private http: HttpClient){
    }

    getmainData (): Observable<Form_value_item[]> {
        return this.http.get<Form_value_item[]>(this.formValueItemsUrl
        );
    }

    getFilteredData (valuesID:number): Observable<Form_value_item[]> {
        var url = this.formValueIdUrl + valuesID;
        console.log(url);
        return this.http.get<Form_value_item[]>(url
        );
    }

  //////// CRUD //////////

  /** POST: add a new lijstje to the database */
  addItem (newItem: Form_value_item): Observable<Form_value_item> {
    return this.http.post<Form_value_item>('https://api.spermalie.info/form_value_items/create.php', JSON.stringify(newItem))
  }

  deleteItem (id: number): Observable<{}> {
    let body = JSON.stringify({itemID: id});
    return this.http.post<Form_value_item>('https://api.spermalie.info/form_value_items/delete.php', body)
  }

  updateItem (form_value_item: Form_value_item): Observable<Form_value_item> {
    return this.http.post<Form_value_item>('https://api.spermalie.info/form_value_items/update.php', JSON.stringify(form_value_item))
  }

  checkUniekeNaam(naam: string, lijst: Form_value_item[]): boolean 
  {
    var uniek = true;
    if (naam == '') {
        uniek = false;
    } else {
      var BreakException = {};
      try {
        lijst.forEach(element => {
          console.log(naam + ' idem: ' + element.itemNaam + '?');
          if (element.itemNaam == naam) {
            uniek = false;
            throw BreakException;
          }
        });        
      } catch (error) {
        if (error !== BreakException) throw error;
      }          
    }
    return uniek;
  }

  nextSortID(lijst: Form_value_item[]): number
  {
    var sortId = 0;
    sortId = Number(sortId);
    lijst.forEach(element => {
      if (Number(element.itemSorteer) > sortId) {
        sortId = Number(element.itemSorteer);
      }
    });

    sortId += 100;
    return sortId;
  }
}



