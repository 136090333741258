<div class="alert alert-danger" role="alert" [hidden]="errorMessage === ''" [innerHTML]="errorMessage"></div>
<div>
    <div style="margin-top: 10px" class="row">
        <div class="col-sm-12">
            <div class="col-sm-3 pageTitle"><span style="margin-left:15px;" (click)="createNew()" class="fas fa-plus text-success pointy" aria-hidden="true"></span>
            <!-- </div> 
            <div class="col-sm-1 pageTitle">
            -->
            <span style="margin-left:15px;" (click)="dataOphalen()" class="fas fa-sync-alt text-success pointy" aria-hidden="true"></span></div> 
        </div>       
        <div class="col-sm-1 col-md-1 col-lg-1" style="padding-top: 7px; text-align:right; font-weight:bold "></div>  
    </div><BR> 

    <table class="table table-condensed table-hover">
        <thead>
            <tr>
                <th valign="top">ID</th>
                <th [class.active]="order === 'AntwoordOpVraag'"
                        
                        class="searchColumn">
                        Indien antwoord op de vraag 
                        <span class="" [hidden]="order === 'AntwoordOpVraag'"><i class="fas fa-sort pointy" aria-hidden="true"></i></span>
                        <span class="fontGreen" [hidden]="reverse || order !== 'AntwoordOpVraag'"><i class="fas fa-sort-alpha-down pointy" aria-hidden="true"></i></span>
                        <span class="fontRed" [hidden]="!reverse || order !== 'AntwoordOpVraag'"><i class="fas fa-sort-alpha-up pointy" aria-hidden="true"></i></span>
                </th>
                <th [class.active]="order === 'Antwoord'"
                        
                        class="searchColumn">
                        = 
                        <span class="" [hidden]="order === 'Antwoord'"><i class="fas fa-sort pointy" aria-hidden="true"></i></span>
                        <span class="fontGreen" [hidden]="reverse || order !== 'Antwoord'"><i class="fas fa-sort-alpha-down pointy" aria-hidden="true"></i></span>
                        <span class="fontRed" [hidden]="!reverse || order !== 'Antwoord'"><i class="fas fa-sort-alpha-up pointy" aria-hidden="true"></i></span>
                </th>                
                <th [class.active]="order === 'ToonVraag'"
                        
                        class="searchColumn">
                        Toon dan vraag 
                        <span class="" [hidden]="order === 'ToonVraag'"><i class="fas fa-sort pointy" aria-hidden="true"></i></span>
                        <span class="fontGreen" [hidden]="reverse || order !== 'ToonVraag'"><i class="fas fa-sort-alpha-down pointy" aria-hidden="true"></i></span>
                        <span class="fontRed" [hidden]="!reverse || order !== 'ToonVraag'"><i class="fas fa-sort-alpha-up pointy" aria-hidden="true"></i></span>
                </th>   

                <th>Bewerk</th>
            </tr>
        </thead>

        <tbody>

            <tr class="enhanced" *ngFor="let item of primTableArray | orderBy: order : reverse">
                <td>{{item.itemDepID}}</td>
                <td>{{item.AntwoordOpVraag}}</td>
                <td>{{item.Antwoord}}</td>
                <td>{{item.ToonVraag}}</td>

                <td>
                        <input class="form-control"  type="hidden" 
                        #itemDepID
                        value={{item.itemDepID}}> 
                    <span (click)="populateForm(item)" class='fas fa-pencil-alt text-success pointy'></span>   
                    <span>&nbsp;&nbsp;</span>
                    <span (click)="removeItem(item.itemDepID)" class="fas fa-times text-warning pointy" aria-hidden="true"></span>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<div *ngIf="editItem">
    <form [formGroup]="itemForm">
        <div class="row">
            <div class="col-sm-12">
                <label class="klein">Indien antwoord op de vraag</label>
                <select class="form-control" 
                formControlName="cParentVraagID"
                #parentID
                (change)="keuzesOphalen(parentID.value)">
                    <option *ngFor="let vraag of vragenArray | orderBy: 'omschrijving'" [value]="vraag.ID">{{ vraag.omschrijving }}</option>
                </select>                 
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <label class="klein">het volgende is</label>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-3">
                <input class="form-control" formControlName="cResponse"> 
            </div>
            <div class="col-sm-1"> of </div>       
            <div class="col-sm-8">
                <select class="form-control" formControlName="cAntwID">
                    <option *ngFor="let keuze of keuzesArray | orderBy: 'itemNaam'" [value]="keuze.itemID">{{ keuze.itemNaam }}</option>
                </select>                      
            </div>                 
        </div>

        <div class="row">
            <div class="col-sm-12">
                <label class="klein">Toon dan vraag</label>
                <select class="form-control" formControlName="cChildID">
                    <option *ngFor="let vraag of vragenArray | orderBy: 'omschrijving'" [value]="vraag.ID">{{ vraag.omschrijving }}</option>
                </select>          
            </div>
        </div>

        <BR> 
        <button type="button" class="btn btn-success" 
        (click)="saveRecord(itemForm.get('cParentVraagID').value, itemForm.get('cChildID').value, itemForm.get('cAntwID').value, itemForm.get('cResponse').value, item)"
        >
        Opslaan
        </button>  
        <button type="button" class="btn btn-danger" (click)="createForm()">Annuleren</button>  
    </form>
</div>
<BR> 
